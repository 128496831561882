import {Link, NavLink} from "react-router-dom";
import Logo from './../images/logo.png';
import Watsapp from '../images/watsapp.svg';
import Instagram from '../images/instagram.svg';
import Phone from '../images/phone.svg';
import Email from '../images/email.svg';

const data = [
  // {title: 'Правила и условия', href: 'terms_and_conditions'},
  {title: 'Политика конфиденциальности', href: 'privacy_policy'},
  // {title: 'Условия обслуживания', href: 'terms_of_service'},
  {title: 'Политика возврата', href: 'return_policy'},
  {title: 'Пользовательское соглашение', href: 'user_agreement'},
  {title: 'Правила оказания услуг пользователем (курьером)', href: 'rules_courier'},
  {title: 'Правила выбора и предоставления услуг пользователем (ПОВАРОМ)', href: 'rules_chef'},
  {title: 'Публичная оферта на оказание услуг курьерской службой ТОО "DOM FOOD" (ДОМ ФУД)', href: 'public_offer_courier'},
  {title: 'Публичная оферта на оказание услуг в электронном виде компанией «ДОМ ФУД»', href: 'public_offer_company'},
  {title: 'Соглашение о защите персональных данных пользователей', href: 'agreement_users'},
  {title: 'Check Account', href: 'deleted_account'},
]

const iconsBlock = [
  { title: 'WhatsApp', icon: Watsapp ,  url: '+77779001033'},
  { title: 'Instagram', icon: Instagram , url: 'https://www.instagram.com/domfood.kaz/profilecard/?igsh=a3c5azJmYm9hdjd2'},
  { title: 'Phone', icon: Phone,  url: '+77779001033' },
  { title: 'Email', icon: Email,  url: 'support@dom-food.com' }
];
const Footer = () => {
  return (
    <div className="bg-[#f7f8f9] px-4 md:px-[30px] lg:px-[20px] xl:px-[100px] py-[20px] lg:py-[80px] flex-col lg:flex-row flex items-center gap-x-[40px]">
      <div className="w-full md:w-auto flex flex-col items-start md:items-center">
        <Link to="/">
          <img alt="svg" src={Logo} />
        </Link>
        <div className="md:flex gap-x-4 mt-4 hidden">
          {iconsBlock.map((item, index) => {
            if(item.title === 'Email') {
              return (
                <Link key={index} target="_blank" to={`mailto:${item.url}`}>
                  <img src={item.icon} alt="svg" />
                </Link>
              )
            }
            if(item.title === 'WhatsApp') {
              return (
                <Link key={index} target="_blank" to={`https://api.whatsapp.com/send?phone=${item.url}`}>
                  <img src={item.icon} alt="svg" />
                </Link>
              )
            }
            if(item.title === 'Phone') {
              return (
                <Link key={index} target="_blank" to={`tel:${item.url}`}>
                  <img src={item.icon} alt="svg" />
                </Link>
              )
            }
            return (
              <Link key={index} target="_blank" to={item.url}>
                <img src={item.icon} alt="svg" />
              </Link>
            );
          })}
        </div>
      </div>
      <nav className="mt-10 lg:mt-0">
        <ul className="grid grid-cols-1 md:grid-cols-2 gap-x-4">
          {data.map(({ href, title }) => (
            <li className='m-2' key={title}>
              <NavLink to={href} >
                <p className={'text-black text-sm text-start md:text-center'}>{title}</p>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className="md:hidden gap-x-4 mt-4 flex">
        {iconsBlock.map((item, index) => {
          if(item.title === 'Email') {
            return (
              <Link key={index} target="_blank" to={`mailto:${item.url}`}>
                <img src={item.icon} alt="svg" />
              </Link>
            )
          }
          if(item.title === 'WhatsApp') {
            return (
              <Link key={index} target="_blank" to={`https://api.whatsapp.com/send?phone=${item.url}`}>
                <img src={item.icon} alt="svg" />
              </Link>
            )
          }
          if(item.title === 'Phone') {
            return (
              <Link key={index} target="_blank" to={`tel:${item.url}`}>
                <img src={item.icon} alt="svg" />
              </Link>
            )
          }
          return (
            <Link key={index} target="_blank" to={item.url}>
              <img src={item.icon} alt="svg" />
            </Link>
          );
        })}
      </div>

    </div>
  )
}

export default Footer
