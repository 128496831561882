import {useEffect} from "react";

const PublicOfferCompany = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-xl md:text-3xl">ПУБЛИЧНАЯ ОФЕРТА ОКАЗАНИЯ ЭЛЕКТРОННЫХ УСЛУГ КОМПАНИЕЙ
        ТОО "DOM FOOD (ДОМ ФУД)"</h1>
      <p className="mt-4">
        Настоящее предложение, Товарищество с ограниченной ответственностью «DOM FOOD (ДОМ
        ФУД)» (далее — Исполнитель), в лице Директора Евлоева Абдуллы Аслановича, действующего
        на основании Устава, БИН 240940031813, адрес места нахождения: Казахстан, город Алматы,
        Алмалинский район, улица Карасай Батыра, дом 193Б, почтовый индекс 050008, электронный
        адрес: https://dom-food.com/ и https://domfood.kz/, электронная почта support@dom-food.com, номер
        телефона: +7 777 9001033, ИИК KZ46722S000040853571, БИК CASPKZKA в АО «Kaspi Bank», КБе
        17, является публичной офертой, в соответствии с которой Исполнитель предлагает заключить
        Договор оказания электронных услуг с использованием информационных технологий на условиях,
        изложенных в настоящем предложении, с любым лицом, принявшим оферту.
        Данная публичная оферта содержит все существенные условия, признанные таковыми
        законодательством, а также условия, необходимые для оказания услуг по размещению готовой
        продукции на платформе DOM FOOD (ДОМ ФУД), её реализации и продвижению. Настоящее
        предложение также включает условия, относительно которых по заявлению Исполнителя должно
        быть достигнуто соглашение с Пользователем.
      </p>
      <h3 className="font-bold text-xl md:text-2xl mt-4 text-center">1. ПОНЯТИЯ И ТЕРМИНЫ, ИСПОЛЬЗУЕМЫЕ В НАСТОЯЩЕЙ ПУБЛИЧНОЙ
        ОФЕРТЕ</h3>
    <p>
      <p><br/></p> 1.1. Услуги — размещение на платформе DOM FOOD (ДОМ ФУД) готовой продукции, её
      реализация и продвижение, то есть электронные услуги (включая информационные,
      интерактивные, транзакционные), предоставляемые на территории Республики Казахстан
      совершеннолетним дееспособным физическим и юридическим лицам с применением
      информационных технологий.
      <p><br/></p>1.2. Договор - Оферта — настоящее предложение, которое Исполнитель делает любому лицу,
      согласному на заключение договора, и которое содержит все существенные условия. Данное
      предложение включает все условия, признанные существенными законодательством или
      необходимыми для оказания Услуг, а также те условия, относительно которых по заявлению
      Исполнителя должно быть достигнуто соглашение.
      <p><br/></p> 1.3. Акцепт — ответ Пользователя или совершение Пользователем действий, определенных
      Договором-Офертой, свидетельствующих о полном и безоговорочном принятии условий
      Договора-Оферты.
      <p><br/></p>  1.4. Информационная технология — совокупность методов, производственных процессов и
      программно-технических средств, объединенных в технологический комплекс, обеспечивающий
      сбор, создание, хранение, накопление, обработку, поиск, вывод, копирование, передачу и
      распространение информации.
      <p><br/></p> 1.5. Сайт — интернет-ресурс https://dom-food.com/ и https://domfood.kz/ Исполнителя,
      представляющий собой информацию (в текстовом, графическом, аудиовизуальном или ином
      виде), размещенная на аппаратно-программном комплексе, имеющем уникальный сетевой адрес
      и (или) доменное имя и функционирующем в Интернете.
      <p><br/></p>  1.6. Посетитель/ покупатель — лицо, посетившее Сайт для ознакомления и/или покупки
      готовой продукции Пользователя без цели регистрации или размещения Заказа.
      <p><br/></p> 1.7. Пользователь — лицо, принявшее условия Договора-Оферты Исполнителя посредством
      Акцепта.
      <p><br/></p>  1.8. Заказ — заявка Пользователя на оказание Услуг, оформленная в соответствии с условиями
      Договора-Оферты и размещённая на Платформе.
      <p><br/></p> 1.9. Готовая продукция — продукция, изготовленная Пользователем собственными силами, и
      прошедшая предварительное согласование с Исполнителем на соответствие стандартам качества.
      <p><br/></p> 1.10. Стороны — Исполнитель и Пользователь, совместно являющиеся сторонами ДоговораОферты.
      <p><br/></p> 1.11. Персональные данные — сведения, регламентированные Договором-Офертой,
      относящиеся к определенному физическому лицу и зафиксированные на электронном, бумажном
      или ином материальном носителе.
      <p><br/></p> 1.12. Сбор Персональных данных — действия, направленные на получение Персональных
      данных для выполнения обязательств по Договору-Оферте.
      <p><br/></p>  1.13. Обработка персональных данных — действия, направленные на накопление, хранение,
      изменение, дополнение, использование, распространение, обезличивание, блокирование и
      уничтожение персональных данных.
      <p><br/></p> 1.14. Третьи лица — лица, связанные с Исполнителем в настоящем или будущем по
      обстоятельствам или правоотношениям, касающимся Сбора, Обработки и защиты Персональных
      данных Пользователя.
      <p><br/></p>1.15. Правила отбора и оказания услуг пользователем — приложение к настоящему договору
      публичной оферты, где изложены все правила оказания услуг, Требования, Права и обязанности
      Пользователя, данные правила являются неотьемлемой частью договора (далее по тексту
      Правила).
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. ПРЕДМЕТ ДОГОВОРА - ОФЕРТЫ</h3>
      <p>
        <p><br/></p> 2.1. Обязательства Сторон: Исполнитель обязуется оказать Услуги по размещению,
        реализации и продвижению готовой продукции на платформе DOM FOOD (ДОМ ФУД), а
        Пользователь обязуется оплатить данные Услуги. Условия оказания Услуг определены в
        настоящем Договоре-Оферте и могут быть приняты Пользователем исключительно путем
        присоединения к предложенному Договору-Оферте в целом через принятие (Акцепт)
        предложения Исполнителя.
        <p><br/></p> 2.2. Порядок совершения Акцепта:
        Предложение Исполнителя считается принятым Пользователем с момента совершения им любого
        из следующих действий:
        <p><br/></p> 2.2.1. Оплата Услуг Пользователем в порядке и на условиях, указанных в Договоре-Оферте.
        <p><br/></p> 2.2.2. Совершение Пользователем действий, указывающих на принятие Договора-Оферты, в
        частности, проставление отметки (галочки) в соответствующем поле на странице Сайта,
        свидетельствующей о принятии Договора-Оферты.
        <p><br/></p> 2.2.3. Регистрация на Сайте.
        <p><br/></p>   2.2.4. Оформление Заказа готовой продукции.
        <p><br/></p>  2.3. Правовые последствия Акцепта:
        С момента совершения Пользователем Акцепта вышеуказанным образом, наступают следующие
        правовые последствия:
        <p><br/></p>   2.3.1. Пользователь ознакомлен, согласен и принял предложение Исполнителя с условиями
        Договора-Оферты и Правил.
        <p><br/></p>  2.3.2. Акцепт Исполнителем получен.
        <p><br/></p> 2.3.3. Договор-Оферта считается заключенным и имеет юридическую силу в электронном виде
        без необходимости двустороннего подписания.
        <p><br/></p> 2.3.4. Письменная форма сделки считается соблюденной.
        <p><br/></p> 2.4. Право Исполнителя на отзыв Договора-Оферты: Исполнитель имеет право отозвать свое
        предложение о заключении Договора-Оферты в любое время, что не является основанием для
        отказа от выполнения обязательств по уже принятым Заказам. Исполнитель обязуется разместить
        уведомление об отзыве Договора-Оферты на своем Сайте с указанием точного времени отзыва не
        менее чем за 12 часов до фактического отзыва (приостановления) действия Договора-Оферты.
        <p><br/></p>  2.5. Периодическая недоступность Сайта: Сайт может быть временно недоступен, частично
        или полностью, и соответственно, оказание Услуг может быть временно прекращено, частично
        или полностью, в связи с проведением профилактических или иных работ, а также по любым
        другим причинам технического характера. Исполнитель имеет право периодически проводить
        необходимые профилактические или иные работы, с предварительным уведомлением
        Пользователей или без такового, в зависимости от обстоятельств.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. УСЛОВИЯ ОКАЗАНИЯ УСЛУГ</h3>
      <p>
        3.1. Правила пользования Сайтом: Правила пользования Сайтом отражены в
        Пользовательском соглашении. Данные правила обязательны для исполнения Пользователем, и
        несоблюдение их может повлечь временное ограничение доступа к Услугам Платформы.
      </p>
      <p>3.2. Виды Услуг и их особенности:</p>
      <div id="table-scroll">

      <table className="service-table mt-4 overflow-y-auto">
        <thead>
        <tr>
          <th>№</th>
          <th>Наименование Услуги</th>
          <th>Особенности оказываемой Услуги, сведения об основных свойствах Услуги</th>
          <th>Срок оказания Услуги и порядок его исчисления</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>Регистрация на Сайте</td>
          <td>Регистрация личного кабинета, создание и настройка профиля</td>
          <td>Мгновенно при принятии условий Договора-Оферты</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Размещение готовой продукции</td>
          <td>Размещение фотографий, описания, состава, количества и сроков готовности продукции</td>
          <td>Продолжительность зависит от активности Пользователя</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Продвижение готовой продукции</td>
          <td>Маркетинговое продвижение продукции, включая участие в акциях и скидках</td>
          <td>Продолжительность — до расторжения Договора</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Оформление заказа продукции (упаковка, оплата, доставка)</td>
          <td>Оформление заказа на готовую продукцию, упаковка и подготовка к доставке</td>
          <td>Осуществляется в сроки, указанные в карточке заказа</td>
        </tr>
        <tr>
          <td>5</td>
          <td>Доставка готовой продукции Покупателю</td>
          <td>Доставка осуществляется курьерами или Поваром (при необходимости)</td>
          <td>Срок зависит от выбранного способа доставки и места назначения</td>
        </tr>
        <tr>
          <td>6</td>
          <td>Закрытие заказа</td>
          <td>Завершение заказа, подведение итогов и подтверждение выполнения</td>
          <td>Осуществляется после успешной доставки и оплаты</td>
        </tr>
        </tbody>
      </table>
      </div>
<p>
  <p><br/></p>  3.3. Оказание Услуг на постоянной основе: По Заказу Пользователя Исполнитель оказывает
  Услуги на регулярной основе до момента прекращения договорных отношений между сторонами.
  <p><br/></p>  3.4. Неограниченное количество Заказов: В рамках исполнения Договора-Оферты
  Пользователем может быть оформлено неограниченное количество Заказов для Покупателей. При
  этом исполнение определенного Заказа (оказание Услуги) не прекращает действие ДоговораОферты.
  <p><br/></p>   3.5. Порядок оформления Заказа: Пользователь оформляет Заказ путем выполнения действий,
  описанных в Правилах отбора и оказания услуг Пользователем, которые являются
  неотъемлемой частью настоящего Договора-Оферты.
  <p><br/></p>  3.6. Привлечение соисполнителей:
  <p><br/></p>  3.6.1. Совершая Акцепт Договора-Оферты, Пользователь выражает согласие на привлечение
  Исполнителем третьих лиц (соисполнителей) для исполнения обязательств по Договору-Оферте.
  В частности, услуги по доставке готовой продукции Покупателю могут осуществляться
  курьерами, привлекаемыми Исполнителем.
  <p><br/></p>   3.6.2. В случае, если Исполнитель по какой-либо причине не сможет предоставить услугу
  доставки курьерами, Пользователь (Повар) обязуется самостоятельно организовать доставку
  готовой продукции Покупателю в согласованные сроки, обеспечивая её качество и
  своевременность в соответствии с требованиями Платформы.
  <p><br/></p>  3.7. Технические сбои: Исполнитель не несёт ответственности за задержки или невозможность
  оказания Услуг, вызванные техническими сбоями на Платформе, перебоями в работе интернета
  или другими форс-мажорными обстоятельствами. При этом Пользователь обязуется
  самостоятельно следить за обновлениями Платформы и программного обеспечения,
  необходимого для работы.
  <p><br/></p>   3.8. Контроль достоверности информации: Пользователь несёт ответственность за
  достоверность информации, размещённой на Платформе, включая описание готовой продукции,
  её состав, сроки годности и другие характеристики. В случае недостоверной информации
  Исполнитель вправе приостановить доступ к Услугам до устранения нарушений.
  <p><br/></p>   3.9. Гибкость комиссии: Пользователь имеет право обратиться в службу поддержки
  Исполнителя по вопросам, связанным с размещением продукции, обработкой Заказов и
  доставкой. Исполнитель обязуется обеспечить своевременное реагирование на запросы
  Пользователя.
  <p><br/></p>  3.10. Приоритетное продвижение: В случае самостоятельной доставки Пользователем
  (Поваром), общая комиссия за размещение и реализацию продукции на Платформе может быть
  пересмотрена Исполнителем. О таких изменениях Пользователь будет уведомлен заранее.
  <p><br/></p> 3.11. Поддержка и коммуникация: Исполнитель может предлагать Пользователю
  дополнительные платные услуги, включая приоритетное продвижение продукции или участие в
  специальных акциях. Пользователь вправе согласиться на такие условия через личный кабинет на
  Платформе.
</p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. ЦЕНА, СРОК И ПОРЯДОК ОПЛАТЫ УСЛУГ</h3>

      <div id="table-scroll">

      <table className="service-table mt-4 overflow-y-auto">
        <thead>
        <tr>
          <th>№</th>
          <th>Наименование Услуги</th>
          <th>Единица измерения</th>
          <th>Стоимость Услуги</th>
          <th>Комментарии по стоимости и особенностям</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>Размещение  готовой  продукции на Платформе DOM FOOD</td>
          <td>количество размещённых позиций (блюд или товаров)</td>
          <td>Определяется  Исполнителем</td>
          <th>Цена на продукцию отображается для Покупателя как конечная.</th>
        </tr>
        <tr>
          <td>2</td>
          <td>Продвижение и маркетинговая поддержка</td>
          <td>рекламная  кампания / акция</td>
          <td>Включено в стоимость</td>
          <th>Включает участие в акциях,маркетинговую поддержку и рекламное продвижение на Платформе.</th>
        </tr>
        <tr>
          <td>3</td>
          <td>Доставка готовой продукции</td>
          <td>заказ (единица доставки)</td>
          <td>Оплачивается отдельно</td>
          <th>Стоимость доставки варьируется в зависимости от расстояния и типа продукции. Если курьеры недоступны, доставка осуществляется Пользователемсамостоятельно.</th>
        </tr>
        <tr>
          <td>4</td>
          <td>Регистрация на Платформе</td>
          <td>действие (создание  личного кабинета) </td>
          <td>Бесплатно</td>
          <th>Регистрация личного кабинета и создание профиля на Платформе.</th>
        </tr>
        <tr>
          <td>5</td>
          <td>Оформление заказа</td>
          <td>заказ</td>
          <td>Входит в общую стоимость продукции</td>
          <th>Включает упаковку, прием оплаты и координацию доставки.</th>
        </tr>
        <tr>
          <td>6</td>
          <td>Закрытие заказа</td>
          <td>действие (подтверждение завершения заказа)</td>
          <td>Бесплатно</td>
          <th>Оформление завершения заказа через систему Платформы.</th>
        </tr>
        </tbody>
      </table>
      </div>
      <p>
        <p><br/></p>  4.1. Описание оказываемых Услуг:
        <p><br/></p>  4.1.1. Исполнитель оказывает Пользователю комплексные Услуги, включающие:
        • Регистрация на Платформе: Регистрация личного кабинета, настройка профиля,
        публикация информации о продукции.
        • Размещение готовой продукции: Публикация фотографий продукции, описание, состав,
        характеристики и сроки готовности.
        • Продвижение продукции: Маркетинговая поддержка, участие в акциях и скидках,
        рекламные кампании для увеличения продаж.
        • Контроль качества: Контроль качества размещённой продукции, упаковки, соответствия
        заявленным стандартам.
        • Оформление и закрытие заказов: Координация заказов, включая упаковку, прием оплаты
        и доставку готовой продукции.
        <p><br/></p>  4.2. Формирование цены и комиссия Исполнителя:
        <p><br/></p>  4.2.1. Пользователь указывает цену продукции в карточке товара, видимой для Покупателя.
        Конечная стоимость, которую видит Покупатель, включает все дополнительные расходы и
        отображается как единая цена.
        <p><br/></p>  4.2.2. Исполнитель регулирует цену, добавляя свои расходы, связанные с продвижением,
        поддержанием Платформы и другими услугами. Эта корректировка не раскрывается
        Пользователю.
        <p><br/></p>  4.2.3. Пользователь получает выплату согласно указанной им цене товара, независимо от
        размера комиссии Исполнителя. Это гарантирует прозрачность для Пользователя без раскрытия
        внутренних расчётов Платформы. Пользователь соглашается с возможными изменениями,
        принимая настоящую Оферту.
        <p><br/></p>   4.2.4. Комиссия, взимаемая Исполнителем, покрывает широкий спектр услуг и расходов,
        связанных с предоставлением комплексной поддержки Пользователю, включая, но не
        ограничиваясь, следующими аспектами:
        1. Маркетинговые операции:
        • Организация и проведение рекламных кампаний для повышения видимости продукции
        Пользователя на Платформе.
        • Продвижение готовой продукции через участие в акциях и скидках, которые привлекают
        больше покупателей.
        • Разработка и реализация стратегий увеличения продаж, включая таргетированную рекламу,
        сотрудничество с партнёрами и привлечение новых пользователей на Платформу.
        2. Техническое обеспечение и поддержание Платформы:
        • Обслуживание и поддержка работы Платформы DOM FOOD (ДОМ ФУД), включая
        разработку, обновление и улучшение функционала для удобства и эффективности работы
        Пользователей.
        • Обеспечение бесперебойной работы Платформы, защита данных и поддержка безопасности
        на всех этапах взаимодействия.
        • Внедрение новых технологий и инструментов, улучшающих пользовательский опыт, таких
        как улучшенная система уведомлений, функции для анализа продаж и обратной связи от
        Покупателей
        3. Поддержка Пользователей:
        • Предоставление Пользователям доступа к службе поддержки, которая помогает решать
        вопросы, связанные с размещением продукции, продвижением, оформлением заказов и
        другими аспектами работы на Платформе.
        • Юридическая, бухгалтерская и организационная поддержка в течение первого месяца
        сотрудничества, а также предоставление консультаций по использованию Платформы.
        4. Организация и управление процессами продаж:
        • Координация заказов, включая помощь в оформлении и обработке Заказов, проверку
        соответствия размещенной продукции стандартам качества, а также координацию процессов
        упаковки и доставки.
        • Автоматизация процессов оформления и закрытия заказов, что позволяет пользователям
        сосредоточиться на приготовлении и качестве продукции, оставляя административные задачи
        Платформе.
        5. Контроль качества и безопасность:
        • Контроль качества готовой продукции, обеспечивающий соответствие заявленным
        стандартам и требованиям Платформы.
        • Поддержание санитарных стандартов и обеспечение безопасности пищевых продуктов,
        включая аудит продукции и упаковки.
        6. Маркетинговый анализ и рекомендации:
        • Проведение маркетингового анализа и предоставление Пользователям рекомендаций по
        улучшению ассортимента продукции, а также по повышению привлекательности и
        конкурентоспособности их предложений на Платформе.
        • Сбор и анализ обратной связи от покупателей для улучшения позиций продукции
        Пользователя на рынке.
        Комиссия удерживается Исполнителем автоматически при каждой транзакции, и
        соответствующая сумма переводится на счет Пользователя после вычета комиссии.
        <p><br/></p>  4.3. Условия и порядок оплаты:
        <p><br/></p> 4.3.1. Оплата Заказа: Пользователь обязуется обеспечить оплату Услуг по соответствующему
        Заказу на условиях 100% предоплаты со стороны Покупателя. Сумма предоплаты поступает на
        счет Исполнителя.
        <p><br/></p>  4.3.2. Срок оплаты: Исполнитель обязуется перечислить средства Пользователю в течение 1
        (одного) календарного дня с момента поступления оплаты от Покупателя. Пользователь
        соглашается с тем, что в случае форс-мажорных обстоятельств или технических неполадок
        возможны задержки в сроках перечисления средств. Исполнитель обязуется уведомлять
        Пользователя о возможных задержках и прилагать усилия для минимизации их длительности.
        <p><br/></p>   4.3.3. Выплата Пользователю: Пользователь получает выплату согласно заявленной им цене за
        вычетом внутренних расходов Платформы.
        <p><br/></p>  4.3.4. Ответственность за неисполнение обязательств: Неисполнение или ненадлежащее
        исполнение Пользователем своих обязательств по оплате приводит к тому, что Исполнитель
        освобождается от выполнения данного Заказа. В таком случае Заказ считается не оформленным,
        не полученным и не принятым к исполнению.
        <p><br/></p>  4.4. Способы оплаты:
        <p><br/></p> 4.4.1. Оплата за Услуги осуществляется в безналичном порядке следующими способами:
        • Платежной картой (VISA, MasterCard).
        • Банковский перевод.
        • Электронные кошельки (если доступны на Платформе).
        • Другие способы оплаты, доступные на Платформе и указанные при оформлении Заказа.
        <p><br/></p>  4.5. Дополнительные расходы и комиссии:
        • Дополнительные расходы на оплату: Некоторые способы оплаты могут предполагать
        дополнительные комиссии или платежи, связанные с использованием банковских услуг или
        платежных систем. Все данные расходы несёт Пользователь. Пользователь обязан учитывать эти
        затраты при выборе способа оплаты.
        <p><br/></p>  4.6. Адаптация условий и изменение цен:
        <p><br/></p>  4.6.1. Исполнитель оставляет за собой право корректировать условия сотрудничества и цену в
        зависимости от объёма продаж, участия в акциях и других факторов. Эти изменения не требуют
        отдельного уведомления Пользователя и автоматически отражаются в ценах на Платформе.
        <p><br/></p>  4.6.2. Продолжая пользоваться Платформой, Пользователь соглашается с такими изменениями.
        <p><br/></p>  4.7. Прозрачность и контроль расчётов:
        <p><br/></p>  4.7.1. Пользователь имеет доступ к отчётам в личном кабинете, где отображаются все операции,
        связанные с его продукцией, включая суммы выплат за заказы.
        <p><br/></p>  4.7.2. Расчёты обновляются автоматически при изменении условий или запуске маркетинговых
        акций, что гарантирует своевременную выплату Пользователю.
        <p><br/></p>  4.8. Индивидуальные условия для отдельных категорий Пользователей:
        <p><br/></p>  4.8.1. Индивидуальные условия: Исполнитель может предлагать специальные условия и ставки
        для отдельных категорий Пользователей (например, партнёров с большим оборотом или
        участников специальных программ).
        <p><br/></p>   4.8.2. Дополнительные соглашения: При необходимости заключаются индивидуальные
        соглашения с Пользователями, которые оформляются в письменном или электронном виде и
        являются неотъемлемой частью настоящего Договора-Оферты.
        <p><br/></p>  4.9. Логистика и управление доставкой:
        <p><br/></p>  4.9.1. Гибкие условия доставки: Если доставка курьерами Платформы временно недоступна,
        Пользователь обязан самостоятельно организовать доставку готовой продукции до Покупателя.
        <p><br/></p>  4.9.2. Координация логистики: Исполнитель может предложить помощь в организации
        логистики при необходимости, включая подключение к внешним курьерским службам.
        <p><br/></p>  4.9.3. Расходы на доставку: Стоимость доставки указывается отдельно и видна Покупателю при
        оформлении заказа. Эти расходы не входят в цену товара и регулируются отдельно.
        <p><br/></p>  4.10. Политика возвратов и претензий:
        <p><br/></p>  4.10.1. Возврат средств Покупателю: В случае отмены заказа или претензий от Покупателя,
        Исполнитель может удержать часть суммы на покрытие расходов на маркетинг и операционные
        издержки.
        <p><br/></p>  4.10.2. Рассмотрение претензий: Претензии по заказам обрабатываются через систему
        Платформы. Исполнитель выступает посредником между Покупателем и Пользователем для
        разрешения споров.
        <p><br/></p>  4.11. Изменение и приостановка предоставления Услуг:
        <p><br/></p>   4.11.1. Изменение структуры стоимости: Исполнитель оставляет за собой право изменять
        стоимость и структуру Услуг в зависимости от текущих условий рынка.
        <p><br/></p>  4.11.2. Временная приостановка Услуг: Исполнитель может временно приостановить
        предоставление Услуг на Платформе в случае технических проблем или форс-мажорных
        обстоятельств. Пользователи будут уведомлены о таких ситуациях через личный кабинет или
        электронную почту.
        <p><br/></p>  4.12. Политика налоговых обязательств:
        <p><br/></p>  4.12.1. Налоговые обязательства: Пользователи несут ответственность за уплату всех налогов и
        сборов, связанных с их деятельностью на Платформе.
        <p><br/></p>  4.12.2. Возможность администрирования налогов Исполнителем: При необходимости
        Исполнитель может предложить услугу администрирования налогов за Пользователя на
        основании отдельного соглашения. Размер комиссии в таких случаях устанавливается
        индивидуально.
        <p><br/></p>  4.13. Поддержка Пользователей и улучшение условий сотрудничества:
        <p><br/></p>   4.13.1. Консультационная поддержка: Пользователи имеют доступ к службе поддержки, которая
        помогает решать вопросы, связанные с размещением продукции, обработкой заказов и другими
        аспектами работы на Платформе.
        <p><br/></p>  4.13.2. Обратная связь и развитие: Исполнитель регулярно собирает обратную связь от
        Пользователей для улучшения функционала Платформы и условий сотрудничества.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">5. ПРИЕМКА-ПЕРЕДАЧА ОКАЗАННЫХ УСЛУГ</h3>
      <p>
        <p><br/></p> 5.1. Общий порядок подтверждения услуг: Поскольку основная деятельность Платформы
        DOM FOOD (ДОМ ФУД) связана с продажей домашней продукции через Пользователей,
        традиционная практика оформления актов выполненных работ заменяется электронными
        подтверждениями и транзакционными отчетами в системе Платформы. Закрытие заказа в
        электронном виде является достаточным подтверждением факта оказания услуг и выполнения
        обязательств.
        <p><br/></p> 5.2. Электронные подтверждения и отчёты:
        <p><br/></p>  5.2.1. Оформление заказов, их выполнение и передача фиксируются через программные средства
        Платформы и подтверждаются закрытием заказа в системе.
        <p><br/></p>  5.2.2. Каждая операция будет отражена в виде транзакционного отчета в личном кабинете
        Пользователя, который может использоваться для внутренней отчетности и налогообложения.
        <p><br/></p> 5.3. Акт выполненных работ (по требованию):
        <p><br/></p>  5.3.1. Если Пользователь (например, юридическое лицо) или контрагенты требуют
        дополнительные документы для бухгалтерской или налоговой отчётности, Исполнитель
        предоставляет Акт выполненных работ на основании запроса.
        <p><br/></p>  5.3.2. Акт оформляется в двух экземплярах, подписывается Исполнителем и передаётся
        Пользователю для подписания в течение 10 (десяти) рабочих дней после закрытия заказа.
        <p><br/></p>  5.3.3. В случае отсутствия претензий или подписанного Акта в указанный срок, услуги будут
        считаться оказанными надлежащим образом и принятыми Пользователем без возражений.
        <p><br/></p> 5.4. Обоснованный отказ и устранение нарушений:
        <p><br/></p>  5.4.1. В случае расхождений или выявления несоответствий между заказом и фактически
        оказанными услугами, Пользователь должен уведомить Исполнителя и предоставить обоснование
        в течение 5 (пяти) календарных дней.
        <p><br/></p> 5.4.2. Исполнитель обязуется устранить установленные несоответствия в согласованный срок, но
        не позднее 10 (десяти) рабочих дней с момента получения уведомления. После этого процедура
        передачи услуг повторяется.
        <p><br/></p> 5.5. Отчётность для юридических лиц и поставщиков:
        <p><br/></p>  5.5.1. Для юридических лиц (поставщиков) также могут быть предусмотрены акты выполненных
        работ. Эти документы будут предоставлены на основании их запроса и станут неотъемлемой
        частью договорных обязательств.
        <p><br/></p> 5.5.2. Поставщики обязаны предоставлять акты выполненных работ Исполнителю в целях
        налоговой отчётности и прозрачности расчётов.
        <p><br/></p>5.6. Налоговая ответственность и документы:
        <p><br/></p> 5.6.1. Пользователи несут ответственность за уплату налогов и оформление собственной
        отчетности в соответствии с законодательством Республики Казахстан.
        <p><br/></p> 5.6.2. Если Пользователь или контрагент желает, чтобы Исполнитель предоставлял документы для
        налоговой отчётности, стороны заключают дополнительное соглашение для урегулирования
        данных вопросов.
        <p><br/></p> 5.7. Прозрачность и удобство:
        <p><br/></p>  5.7.1. Все отчеты и транзакционные данные будут доступны в личном кабинете Пользователя.
        <p><br/></p>  5.7.2. Платформа обеспечивает автоматизацию процессов подтверждения и закрытия заказов, что
        снижает необходимость использования бумажного документооборота и ускоряет обработку
        заявок.
        <p><br/></p>   5.7.3. В случае вопросов или сложностей Пользователь может обратиться в службу поддержки
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ (ФОРС-МАЖОР)</h3>
      <p>
        <p><br/></p> 6.1. Освобождение от ответственности: Сторона освобождается от ответственности за
        частичное или полное неисполнение, или ненадлежащее исполнение обязательств, если это явилось
        следствием обстоятельств непреодолимой силы, возникших после заключения Договора - Оферты
        в результате обстоятельств чрезвычайного характера, которые Сторона не могла предвидеть или
        предотвратить.
        <p><br/></p> 6.2. Перечень обстоятельств непреодолимой силы: Под обстоятельствами непреодолимой
        силы понимаются следующие события, не зависящие от воли Сторон:
        <p><br/></p> 6.2.1. Природные катастрофы: наводнение, пожар, землетрясение, ураган, стихийные явления и
        иные события природного характера.
        <p><br/></p>  6.2.2. Социальные и политические события: эпидемии, пандемии, война, военные действия,
        массовые беспорядки, протесты.
        <p><br/></p>  6.2.3. Государственные акты и распоряжения: Введение карантина, локдаунов, изменение
        законодательства, запреты или ограничения, введённые государственными органами, делающие
        выполнение обязательств невозможным.
        <p><br/></p> 6.3. Обязанность уведомления другой Стороны: Сторона, пострадавшая от обстоятельств
        непреодолимой силы, обязана уведомить другую Сторону в течение пяти (5) рабочих дней с
        момента наступления таких обстоятельств. Уведомление должно содержать:
        <p><br/></p>  6.3.1. Подробное описание обстоятельств.
        <p><br/></p>  6.3.2. Оценку влияния на исполнение обязательств.
        <p><br/></p>  6.4. Подтверждение обстоятельств непреодолимой силы: Сторона, ссылающаяся на
        обстоятельства непреодолимой силы, должна представить другой Стороне официальные
        документы, удостоверяющие наличие этих обстоятельств, а также, по возможности, оценку их
        влияния на возможность исполнения Стороной своих обязательств по Договору - Оферте.
        Обстоятельства непреодолимой силы, имеющие общеизвестный характер, не требуют
        документального подтверждения. Тем не менее, Сторона обязана уведомить другую Сторону о
        наступлении таких обстоятельств в течение 24 часов.
        <p><br/></p> 6.5. Лишение права ссылаться на форс-мажор: Если Сторона, подвергшаяся воздействию
        обстоятельств непреодолимой силы, не направит в срок, предусмотренный Договором - Офертой,
        документы, удостоверяющие наличие этих обстоятельств, то такая Сторона лишается права
        ссылаться на такие обстоятельства как на основание для освобождения от ответственности за
        неисполнение или ненадлежащее исполнение обязательств по Договору - Оферте.
        <p><br/></p> 6.6. Изменение сроков выполнения обязательств: При наступлении обстоятельств
        непреодолимой силы срок выполнения обязательств по Договору - Оферте отодвигается на время,
        в течение которого действуют эти обстоятельства и их последствия. Если обстоятельства
        непреодолимой силы продолжают действовать более тридцати (30) календарных дней, Стороны
        обязаны провести дополнительные переговоры для определения приемлемых альтернативных
        способов исполнения Договора - Оферты. Если соглашение не будет достигнуто, обязательства
        Сторон прекращаются ввиду невозможности исполнения, за исключением денежных обязательств,
        которые остаются в силе.
        <p><br/></p>  6.7. Продолжение работы при частичном форс-мажоре: Если обстоятельства непреодолимой
        силы влияют только на часть обязательств, остальные обязательства остаются в силе. Например,
        если доставка невозможна, но продукция готова, Пользователь обязуется организовать
        самостоятельную передачу заказа Покупателю.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">7. РАЗРЕШЕНИЕ СПОРОВ</h3>
      <p>
        <p><br/></p> 7.1. Досудебное урегулирование споров:
        <p><br/></p> 7.1.1. Досудебное урегулирование споров между Сторонами осуществляется в обязательном
        порядке путём проведения переговоров и предъявления претензий.
        <p><br/></p> 7.1.2. Стороны обязуются предпринять все разумные меры для урегулирования спорных вопросов
        до обращения в судебные органы, чтобы минимизировать риски и затраты.
        <p><br/></p>  7.2. Порядок досудебного урегулирования: Стороны договорились о следующем обязательном
        порядке досудебного урегулирования споров:
        <p><br/></p>  7.2.1. Форма и содержание претензии: Претензия должна быть представлена в письменной
        форме и подписана должным образом уполномоченным лицом Стороны, предъявляющей
        претензию.
        <p><br/></p>  7.2.2. В претензии необходимо указать следующие данные:
        • Требования к другой Стороне, включая сумму претензии и обоснованный её расчет (если
        претензия подлежит денежной оценке).
        • Обстоятельства, на которых основываются требования, и доказательства, подтверждающие
        данные обстоятельства.
        • Перечень прилагаемых документов и иных доказательств, необходимых для рассмотрения
        претензии.
        • Иные сведения, необходимые для урегулирования спора, которые Сторона считает важными
        для решения вопроса.
        • Срок рассмотрения претензии:
        oПретензия рассматривается в течение десяти (10) календарных дней с даты её получения
        другой Стороной.
        oПо результатам рассмотрения претензии Сторона, получившая претензию, обязана
        уведомить Сторону, предъявившую претензию, в письменной форме, указав мотивы
        принятого решения и, при необходимости, предложения о порядке дальнейшего
        урегулирования спора.
        • Запрос дополнительных документов:
        oЕсли к претензии не приложены документы, необходимые для её надлежащего
        рассмотрения, получающая Сторона вправе запросить недостающие документы у
        предъявляющей Стороны.
        oЗапрос должен быть направлен в течение двух (2) календарных дней с момента получения
        претензии. Сторона, направившая претензию, обязана предоставить недостающие документы
        в кратчайшие сроки, но не позднее трёх (3) календарных дней с момента получения запроса.
        oВ случае не предоставления затребованных документов в указанный срок, претензия
        рассматривается на основании имеющихся данных.
        <p><br/></p>  7.3. Судебное разбирательство:
        <p><br/></p>   7.3.1. Если спор не был урегулирован в порядке досудебного урегулирования, каждая из Сторон
        имеет право обратиться в суд для разрешения спора.
        <p><br/></p>  7.3.2. Все споры и разногласия, возникшие из данного Договора - Оферты или в связи с ним,
        подлежат разрешению в судебном порядке в соответствии с действующим законодательством
        Республики Казахстан.
        <p><br/></p>  7.3.3. Стороны соглашаются, что при подаче иска он рассматривается судом по месту нахождения
        Исполнителя, если иное не предусмотрено обязательными нормами законодательства.
        <p><br/></p>  7.4. Конфиденциальность разбирательства: Стороны обязуются сохранять
        конфиденциальность всей информации, связанной с урегулированием спора, включая материалы
        досудебного разбирательства и документы, использованные в суде.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. ПОРЯДОК ИЗМЕНЕНИЯ, РАСТОРЖЕНИЯ ДОГОВОРА - ОФЕРТЫ</h3>
<p>
  <p><br/></p>  8.1. Обмен уведомлениями и запросами: Стороны договорились, что уведомления и (или)
  запросы поДоговору – Оферте могут направляться любым из следующих способов:
  <p><br/></p>  8.1.1. На бумажных носителях:
  • По почте, обязательно регистрируемым почтовым отправлением (заказным письмом), на
  адрес места жительства или регистрации Стороны.
  • Нарочно, под расписку получателя на экземпляре уведомления отправителя.
  <p><br/></p>   8.1.2. Без применения бумажных носителей, посредством электронной почты:
  • Со стороны Исполнителя с электронного адреса, содержащего доменное имя платформы,
  например, support@dom-food.com (электронный адрес для коммуникации с Пользователями).
  • Со стороны Пользователя с электронного адреса, указанного при регистрации на Сайте.
  <p><br/></p>  8.1.3. Требования к уведомлению или запросу по электронной почте:
  • Тема сообщения (subject) должна отражать суть уведомления или запроса.
  • Текст сообщения должен содержать всю информацию, необходимую для рассмотрения
  запроса или уведомления. Уведомления, отправленные с иных электронных адресов или в ином
  формате, могут не рассматриваться.
  <p><br/></p>  8.2. Изменение и дополнение Договора-Оферты: Исполнитель вправе в одностороннем порядке
  изменять и (или) дополнять Договор-Оферту, включая условия и порядок оказания Услуг, объем,
  комплектность и стоимость. При этом:
  <p><br/></p>  8.2.1. Уведомление об изменениях:
  • Уведомление Пользователя о внесении изменений и (или) дополнений осуществляется
  любым общедоступным способом, включая размещение информации на Сайте и (или)
  направление уведомления на электронную почту Пользователя, не позднее чем за 10 (десять)
  календарных дней до вступления изменений в силу.
  • В случае несогласия Пользователя с изменениями он обязан письменно уведомить
  Исполнителя до их вступления в силу и прекратить пользование Услугами. Отсутствие такого
  уведомления считается согласием на изменения, и Договор-Оферта считается измененным с
  момента, указанного в уведомлении Исполнителя.
  <p><br/></p>   8.2.2. Хранение и доступ к Договору-Оферте:
  • Исполнитель предоставляет Пользователю возможность скачать текст Договора-Оферты
  на момент совершения Акцепта, нажав соответствующую кнопку ("Скачать") на Сайте.
  <p><br/></p>  8.2.3. Действующая редакция:
  • Актуальная редакция Договора-Оферты размещена на Сайте и доступна для ознакомления в
  любое время.
  <p><br/></p>  8.3. Расторжение Договора-Оферты: Расторжение Договора-Оферты возможно по соглашению
  Сторон, а также в случае одностороннего отказа от исполнения, в соответствии с условиями
  Договора и законодательством Республики Казахстан.
  <p><br/></p>   8.4. Предложение о расторжении:
  • Предложение о расторжении Договора направляется другой Стороне в форме, согласованной
  Сторонами, не менее чем за 5 (пять) рабочих дней до предполагаемой даты расторжения.
  • Расторжение осуществляется в той же форме, что и Договор-Оферта, путём направления
  предложения одной Стороной и его принятия (Акцепта) другой Стороной.
  <p><br/></p>   8.5. Односторонний отказ от исполнения: Односторонний отказ от исполнения Договора-Оферты
  осуществляется путём письменного уведомления другой Стороны не менее чем за семь (7)
  календарных дней до даты отказа.
  <p><br/></p> 8.6. Дополнительные основания для расторжения:
  Стороны согласовали иные случаи, при которых возможен отказ от Договора:
  <p><br/></p>  8.6.1. Инициатива Пользователя:
  • Систематическое невыполнение Исполнителем обязательств.
  • Просрочка выполнения обязательств более чем на 5 (пять) дней.
  • Ненадлежащее выполнение обязательств Исполнителем.
  <p><br/></p>  8.6.2. Инициатива Исполнителя:
  • Нарушение правил платформы Пользователем, включая систематические жалобы на
  качество продукции или упаковки, нарушение санитарных норм и использование испорченных
  продуктов.
  • Просрочка выполнения обязательств Пользователем на срок более чем семь (5)
  календарных дней.
  • Ненадлежащее выполнение обязательств Пользователем.
  <p><br/></p>  8.7. Последствия расторжения: После расторжения Договора Пользователь теряет доступ к своему
  профилю на Платформе, а все его предложения удаляются.
  <p><br/></p>  8.8. Финансовые расчёты после расторжения: После расторжения Договора Исполнитель
  обязуется завершить все текущие финансовые расчёты с Пользователем, включая выплаты за уже
  выполненные заказы.
  <p><br/></p>   8.9. Урегулирование задолженностей: Обе стороны обязаны урегулировать все задолженности и
  обязательства друг перед другом в течение 7 рабочих дней после расторжения Договора.
  <p><br/></p>  8.10. Временная приостановка (заморозка) учётной записи: Исполнитель вправе временно
  приостановить учётную запись Пользователя, если выявлены нарушения, которые могут быть
  устранены. В таком случае Пользователю дается время для исправления недочётов, после чего
  может быть пересмотрено решение о расторжении.
  <p><br/></p>  8.11. Автоматическое расторжение: Если Пользователь не устраняет выявленные нарушения в
  установленный срок, Договор будет расторгнут автоматически.
  <p><br/></p>  8.12. Возобновление сотрудничества: Пользователь имеет право обратиться для возобновления
  сотрудничества, если он устранил все причины расторжения. Решение о возобновлении принимает
  Исполнитель на основании повторного рассмотрения.
  <p><br/></p>  8.13. Изменение юридического адреса Исполнителя:
  <p><br/></p> 8.13.1. В случае изменения юридического адреса Исполнителя, Компания обязуется разместить
  информацию о таком изменении на Сайте. Пользователи и Поставщики обязаны самостоятельно
  отслеживать любые изменения, размещаемые на сайте.
  <p><br/></p>  8.13.2. Изменение юридического адреса не является основанием для расторжения ДоговораОферты или изменения условий оказания Услуг. Договор продолжает действовать на тех же
  условиях, что и до изменения юридического адреса Исполнителя
</p>
      <h3 className="font-bold text-2xl mt-4 text-center">9. ШТРАФНЫЕ САНКЦИИ</h3>
      <p>
        <p><br/></p> 9.1. Общие положения: Исполнитель вправе применять штрафные санкции к Пользователю в
        случае нарушения обязательств, установленных настоящим Договором-Офертой. Штрафы
        применяются за нарушение сроков выполнения заказов, санитарных норм, условий
        конфиденциальности, ненадлежащее качество продукции, а также за отказ от выполнения доставки.
        <p><br/></p> 9.2. Штраф за нарушение сроков выполнения заказов:
        <p><br/></p> 9.2.1. Первое нарушение: Пользователь получает письменное предупреждение с разъяснением
        допущенного нарушения и рекомендациями по устранению недостатков.
        <p><br/></p>  9.2.2. Повторное нарушение: В случае повторного нарушения Пользователь уплачивает штраф
        5% от стоимости заказа за каждый день просрочки, но не более 30% от общей суммы.
        <p><br/></p> 9.2.3. В случае систематического нарушения сроков (2 или более случаев в течение одного месяца)
        Исполнитель вправе временно ограничить доступ Пользователя к Платформе до устранения
        нарушений.
        <p><br/></p> 9.2.4. Систематические нарушения: В случае, если нарушения сроков происходят более пяти раз
        в течение одного месяца, Исполнитель вправе временно ограничить доступ Пользователя к
        Платформе до устранения всех нарушений и согласования дальнейших действий для
        предотвращения подобных ситуаций в будущем.
        <p><br/></p>  9.3. Штраф за ненадлежащее качество продукции:
        <p><br/></p>  9.3.1. В случае поступления жалоб на ненадлежащее качество продукции (включая упаковку или
        состояние блюд при доставке), Исполнитель вправе наложить на Пользователя штраф в размере
        10% от стоимости жалобного заказа.
        <p><br/></p>   9.3.2. При повторных нарушениях (более 3 раз в течение одного календарного месяца)
        Исполнитель оставляет за собой право увеличить размер штрафа до 15% от стоимости заказа и
        временно приостановить доступ Пользователя к Платформе до устранения всех нарушений.
        <p><br/></p>   9.4. Штраф за нарушение санитарных норм: В случае выявления нарушений санитарных
        норм или использования испорченных продуктов, Пользователь обязан уплатить штраф в размере
        150 000 тенге за каждый выявленный случай нарушения. При повторных нарушениях Исполнитель
        имеет право полностью расторгнуть договор с Пользователем без права восстановления.
        <p><br/></p>   9.5. Штраф за нарушение условий конфиденциальности: В случае разглашения
        конфиденциальной информации, Пользователь обязан уплатить штраф в размере 500 000 тенге
        за каждый случай нарушения. Пользователь также несет ответственность за убытки, причиненные
        Исполнителю, которые могут быть взысканы дополнительно к штрафу.
        <p><br/></p>  9.6. Штраф за отказ от выполнения обязательств по доставке:
        <p><br/></p>  9.6.1. Если Пользователь обязан осуществить доставку самостоятельно по причине отсутствия
        доступных курьеров, но отказывается это сделать, на него накладывается штраф в размере 15% от
        стоимости заказа.
        <p><br/></p>  9.6.2. В таких случаях заказ аннулируется, а Пользователь обязан компенсировать все понесённые
        убытки.
        <p><br/></p>  9.7. Способы взыскания штрафов: Все штрафы удерживаются Исполнителем из сумм,
        подлежащих выплате Пользователю по завершённым Заказам. В случае, если сумма штрафов
        превышает текущие обязательства Исполнителя перед Пользователем, Пользователь обязан
        погасить штрафные санкции в течение 5 (пяти) календарных дней с момента получения
        соответствующего уведомления от Исполнителя.
        <p><br/></p>  9.8. Уведомление о наложении штрафов: Пользователь уведомляется о наложении штрафов в
        письменной форме, включая электронное уведомление. Уведомление должно содержать основание
        для наложения штрафа, его размер и сроки, в течение которых штраф должен быть погашен.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">10. НАЛОГООБЛОЖЕНИЕ И ФИНАНСОВЫЕ ОБЯЗАННОСТИ</h3>
    <p>
      <p><br/></p>  10.1. Ответственность за уплату налогов: Пользователь несёт полную ответственность за
      самостоятельную уплату всех налогов и обязательных платежей, связанных с доходами,
      полученными через Платформу. Это включает налог на доходы физических лиц (НДФЛ),
      социальные отчисления, пенсионные взносы и другие платежи, предусмотренные
      законодательством Республики Казахстан. Исполнитель не несёт обязательств по уплате этих
      налогов за Пользователя.
      <p><br/></p>  10.2. Отчётность перед налоговыми органами: Пользователь обязан самостоятельно вести учёт
      доходов, полученных от оказания услуг, и своевременно подавать налоговые декларации в
      соответствии с законодательством Республики Казахстан. Исполнитель не осуществляет удержание
      налогов с выплат, производимых Пользователю, и не несет ответственность за нарушение
      Пользователем налогового законодательства.
      <p><br/></p>  10.3. Самозанятость и статус индивидуального предпринимателя:
      <p><br/></p>  10.3.1. Пользователь, являющийся самозанятым лицом или индивидуальным
      предпринимателем, обязан самостоятельно зарегистрироваться в соответствующих органах и
      уплачивать налоги в порядке, предусмотренном законодательством Республики Казахстан.
      <p><br/></p>   10.3.2. Исполнитель вправе запросить копии регистрационных документов или подтверждение
      налогового статуса Пользователя. Отказ предоставить такие документы может привести к
      временной блокировке доступа к Платформе.
      <p><br/></p>   10.4. Освобождение Исполнителя от ответственности: Исполнитель не несет ответственности
      за любые налоговые обязательства Пользователя, связанные с оказанием услуг через Платформу.
      Пользователь обязуется компенсировать Исполнителю любые убытки, понесенные в результате
      несоблюдения Пользователем требований налогового законодательства.
      <p><br/></p>  10.5. Опция оплаты налогов Исполнителем:
      <p><br/></p>  10.5.1. По согласованию сторон, Исполнитель может взять на себя обязанность по уплате налогов
      за Пользователя. В таком случае стороны обязуются заключить отдельное соглашение, в котором
      будут отражены все детали и условия данной услуги.
      <p><br/></p>  10.5.2. Индивидуальное налогообложение: Размер налога и условия его уплаты могут
      варьироваться в зависимости от статуса Пользователя (например, самозанятость, ИП) и характера
      деятельности.
      <p><br/></p>   10.5.3. Компенсация со стороны Пользователя: В случае, если Исполнитель берёт на себя
      обязанность уплаты налогов за Пользователя, Пользователь обязан возместить все затраты
      Исполнителя, связанные с уплатой налогов, включая дополнительные комиссии и расходы. Оплата
      производится на основании отдельного счета, выставленного Исполнителем, в течение 10 (десяти)
      календарных дней с момента выставления счета.
      <p><br/></p>  10.5.4. Индивидуальный договор: Все условия, касающиеся уплаты налогов Исполнителем за
      Пользователя, фиксируются в отдельном договоре, который подписывается обеими сторонами и
      не является частью настоящего Договора-Оферты. Данный договор может предусматривать
      дополнительные условия, включая удержание суммы налогов из выплат Пользователю.
      <p><br/></p>  10.6. Налог на добавленную стоимость (НДС):
      <p><br/></p>   10.6.1. НДС на услуги Исполнителя: В случае, если Исполнитель станет плательщиком НДС,
      услуги, оказываемые Пользователю, могут облагаться НДС в соответствии с законодательством
      Республики Казахстан. В этом случае стоимость услуг будет увеличена на сумму НДС, которая
      будет включена в итоговую цену.
      <p><br/></p>  10.6.2. НДС на готовую продукцию Пользователя: В случае, если Пользователь является
      плательщиком НДС, он обязан самостоятельно рассчитывать и уплачивать НДС с доходов,
      получаемых от реализации готовой продукции через Платформу.
      <p><br/></p>  10.6.3. Изменение НДС: Пользователь уведомляется о включении НДС в стоимость услуг за 10
      (десять) календарных дней до вступления изменений в силу, с размещением информации на
      Сайте и/или отправкой уведомления на электронную почту Пользователя. В случае несогласия с
      изменением, Пользователь может прекратить использование услуг, уведомив Исполнителя до
      вступления изменений в силу.
      <p><br/></p>   10.7. Порядок урегулирования налоговых обязательств:
      <p><br/></p>   10.7.1. В случае выявления налоговыми органами задолженности по налогам, вызванной
      несвоевременной уплатой налогов Пользователем, Пользователь обязуется в кратчайшие сроки
      погасить данную задолженность.
      <p><br/></p>  10.7.2. Если обязательства по уплате налогов были возложены на Исполнителя на основании
      отдельного соглашения, Исполнитель вправе удерживать соответствующие суммы из выплат
      Пользователю до полного погашения задолженности.
      <p><br/></p>  10.8. Уведомление о налоговых изменениях: Исполнитель оставляет за собой право изменять
      условия оплаты налогов в случае изменения налогового законодательства или иных обстоятельств,
      влияющих на налоговые обязательства. Пользователь будет уведомлен о таких изменениях в
      порядке, предусмотренном настоящим Договором.
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">11. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
      <p>
        <p><br/></p> 11.1. Мирное урегулирование споров: Все споры и разногласия, возникающие между
        Сторонами в процессе выполнения Договора-Оферты, решаются, прежде всего, путём переговоров
        и мирного урегулирования. Если договориться не удаётся, Стороны вправе обратиться в суд по
        месту нахождения Исполнителя или в гражданский суд, если иное не предусмотрено ДоговоромОфертой.
        <p><br/></p> 11.2. Досудебный порядок урегулирования споров:
        <p><br/></p> 11.2.1. До обращения в суд, каждая из Сторон обязана направить другой стороне письменную
        претензию, в которой четко описаны суть спора и предложения по его урегулированию. Сторона,
        получившая претензию, обязана предоставить письменный ответ в течение 10 (десяти) рабочих
        дней с момента её получения.
        <p><br/></p>  11.2.2. Если стороны не приходят к соглашению в указанный срок, спор может быть передан на
        рассмотрение в суд, в соответствии с законодательством Республики Казахстан.
        <p><br/></p>  11.3. Применимое право: Настоящий Договор-Оферта, а также все правоотношения,
        возникающие в связи с его исполнением, регулируются и подлежат толкованию в соответствии с
        законодательством Республики Казахстан.
        <p><br/></p>  11.4. Вступление в силу и срок действия Договора-Оферты:
        <p><br/></p>  11.4.1. Договор-Оферта вступает в силу с момента, когда Пользователь присоединяется к
        Договору-Оферте и к правилам отбора и оказания услуг, совершив Акцепт в порядке,
        предусмотренном Договором-Офертой.
        <p><br/></p>   11.4.2. Договор-Оферта действует до полного исполнения Сторонами всех обязательств, принятых
        на себя по данному Договору.
        <p><br/></p>11.4.3. Исполнитель оставляет за собой право изменить условия Договора-Оферты, уведомив
        Пользователя о таких изменениях в порядке, предусмотренном настоящим Договором.
        <p><br/></p>   11.5. Изменение юридического адреса Исполнителя:
        <p><br/></p>  11.5.1. В случае изменения юридического адреса Исполнителя, Исполнитель обязуется уведомить
        Пользователя не позднее, чем за 10 (десять) рабочих дней до изменения. Уведомление может
        быть отправлено в письменной форме или по электронной почте. Данные изменения также будут
        отображены на Сайте.
        <p><br/></p>  11.5.2. Изменение адреса не является основанием для расторжения Договора и не влияет на
        условия оказания Услуг.
        <p><br/></p>   11.6. Действие обязательств по конфиденциальности после расторжения договора: Все
        обязательства по конфиденциальности, предусмотренные настоящим Договором-Офертой,
        остаются в силе и после его расторжения в течение трёх лет. Пользователь обязуется не разглашать
        полученную конфиденциальную информацию, а также не использовать её в своих интересах или
        передавать третьим лицам.
        <p><br/></p>  11.7. Передача прав и обязанностей по Договору:
        <p><br/></p>   11.7.1. Пользователь не имеет права передавать свои права и обязательства по настоящему
        Договору-Оферте третьим лицам без предварительного письменного согласия Исполнителя.
        <p><br/></p>   11.7.2. Исполнитель имеет право передавать свои права и обязательства по Договору-Оферте,
        уведомив Пользователя об этом не позднее, чем за 10 (десять) рабочих дней до момента передачи.
        <p><br/></p>  11.8. Отказ от ответственности: Исполнитель не несёт ответственности за убытки Пользователя,
        возникшие в результате невозможности использования Платформы, вызванной техническими
        сбоями, профилактическими работами, форс-мажорными обстоятельствами и другими причинами,
        не зависящими от Исполнителя.
        <p><br/></p> 11.9. Автономность положений договора: В случае, если одно или несколько положений
        настоящего Договора-Оферты будут признаны недействительными или не подлежащими
        исполнению, это не влияет на действительность и исполнение остальных положений Договора.
        Недействительное положение заменяется на законное и исполнимое, максимально
        соответствующее первоначальному намерению Сторон.
      </p>

    </div>)
}

export default PublicOfferCompany;

