import {useEffect} from "react";

const ReturnPolicy = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">ПОЛИТИКА ВОЗВРАТА</h1>
      <h3 className="font-bold text-xl mt-4">1. Общие положения</h3>
      <p>1.1. Настоящая Политика возврата разработана в соответствии с требованиями Гражданского
        кодекса Республики Казахстан и Закона Республики Казахстан «О защите прав
        потребителей» и регулирует порядок возврата и обмена товаров, приобретенных через платформу
        DOM FOOD (ДОМ ФУД).</p>
      <p><br/></p>

      <h3 className="font-bold text-xl mt-4">1.2. Возврат или обмен товара осуществляется по обращению на:</h3>
      <p><br/></p>
      <p>1.2.1. e-mail: support@dom-food.com (пометка «Возврат/обмен товара»).
        <p><br/></p>  1.2.2. Телефон: +7 777 9001033
        <p><br/></p> 1.2.3. Юридический адрес: Казахстан, г. Алматы, Алмалинский район, ул. Карасай Батыра,
        дом 193Б, индекс 050008..</p>
      <p><br/></p>

      <h6 className="font-bold text-xl mt-4">1.3. Вопросы возврата регулируются на основании следующих принципов:</h6>
      <p><br/></p>
      <p><p><br/></p> 1.3.1. Соблюдение прав потребителя в соответствии с законодательством РК.
        <p><br/></p> 1.3.2. Обеспечение прозрачности и добросовестности в урегулировании возвратов и
        обменов.
        <p><br/></p>  1.3.3. Продавец обязуется оперативно рассматривать все обращения и претензии.</p>
      <p>We do not process sensitive personal information, such as data related to racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, health, or sexual orientation.</p>
      <p><br/></p>

      <p> <strong> 1.4. </strong> Оформляя заказ на платформе DOM FOOD (ДОМ ФУД), покупатель подтверждает свое
        согласие с условиями данной Политики. Если покупатель не согласен с условиями, он должен
        воздержаться от использования услуг платформы и оформления заказа</p>

      <p className="font-bold text-xl mt-4">2. Условия возврата и обмена товара надлежащего качества</p>
      <p className="font-bold text-xl mt-4">2.1. В соответствии с Гражданским кодексом РК (статья 454):</p>
      <p><br/></p>
    <p>«Покупатель вправе в течение
      четырнадцати дней с момента передачи ему непродовольственного товара, если более длительный
      срок не объявлен продавцом, обменять купленный товар в месте покупки или иных местах,
      объявленных продавцом, на аналогичный товар другого размера, формы, габарита, фасона,
      расцветки, комплектации и т. п., произведя, в случае разницы в цене, необходимый перерасчет с
      продавцом.».</p>
      <p><br/></p>
      <p><strong> 2.2. </strong>При отсутствии необходимого для обмена товара у продавца покупатель вправе вернуть
        товар и получить уплаченную за него сумму (ГК РК, ст. 454, п. 2).</p>
      <p><br/></p>
      <p><strong>2.3.</strong> Требования для обмена или возврата:</p>
      <p>2.3.1. Товар не был в употреблении и сохранил товарный вид и потребительские свойства.
        <p><br/></p> 2.3.2. Сохранена оригинальная упаковка, пломбы, ярлыки и полная комплектация.
        <p><br/></p> 2.3.3. Необходимо предоставить документы, подтверждающие факт покупки (товарный или
        кассовый чек)</p>
      <p>
       <strong> 2.4. Законом РК «О защите прав потребителей» (статья 14) </strong> установлено: «Покупатель вправе
        обменять товар на другой товар по соглашению сторон. При этом производится перерасчет в
        случае разницы в цене.»
      </p>
      <p><strong> 2.5. Место возврата и обмена: </strong></p>
      <p>2.5.1. Возврат и обмен товара осуществляется:
        <p><br/></p> 2.5.1.1. По адресу продавца или в месте, указанном в договоре купли-продажи.
        <p><br/></p> 2.5.1.2. В случае дистанционного оформления заказа возврат и обмен могут быть
        произведены через курьерские службы или почтовые отправления за счет покупателя (если
        товар надлежащего качества).</p>
      <p><strong>2.6. Исключения для возврата:</strong></p>
      <p>2.6.1. Согласно <strong> Закону РК «О защите прав потребителей» (статья 30),</strong> не подлежат
        возврату:</p>
      <p>2.6.1.1. Продукты питания и готовые блюда, если они надлежащего качества.
        <p><br/></p>  2.6.1.2. Товары, изготовленные по индивидуальному заказу или с особыми требованиями
        покупателя.
        <p><br/></p> 2.6.1.3. Лекарственные средства и медицинские изделия.
        <p><br/></p> 2.6.1.4. Нательное белье и чулочно-носочные изделия.
        <p><br/></p>  2.6.1.5. Товары, продаваемые на метраж (ткани, провода и т. д.).</p>
      <p><strong>2.7. Порядок возврата стоимости:</strong></p>
      <p>2.7.1. При возврате товара покупателю возвращается полная стоимость товара в течение 10
        рабочих дней после получения товара продавцом и проверки его состояния.
        <p><br/></p> 2.7.2. В случае обмена на товар с большей стоимостью покупатель должен доплатить
        разницу.
        <p><br/></p> 2.7.3. Если товар дешевле, разница возвращается покупателю.</p>
      <p><strong>3. Условия возврата и обмена товара ненадлежащего качества:</strong></p>
      <p><strong>3.1. Права покупателя в случае ненадлежащего качества товара Согласно статье 455
        Гражданского кодекса Республики Казахстан:</strong> «Покупатель, которому продан товар
        ненадлежащего качества, если его недостатки не были оговорены продавцом, вправе по своему
        выбору потребовать:</p>
      <p>3.1.1. Соразмерного уменьшения покупной цены;
        <p><br/></p>  3.1.2. Безвозмездного устранения недостатков в разумный срок;
        <p><br/></p> 3.1.3. Возмещения своих расходов на устранение недостатков;
        <p><br/></p> 3.1.4. Замены товара ненадлежащего качества на товар, соответствующий договору;
        <p><br/></p> 3.1.5. Отказа от исполнения договора и возврата уплаченной за товар суммы.».</p>
      <p><strong>3.2. Товары, приобретенные дистанционно:</strong></p>
      <p><br/></p>
      <p>3.2.1. Если товар был приобретен через курьерскую службу или почтой, расходы на возврат или обмен товара ненадлежащего качества несет продавец</p>
      <p>3.2.2. Покупатель обязан сообщить о выявленных недостатках не позднее 7 календарных
        дней после получения товара.</p>
      <p><strong>3.3. Замена товара с недостатками: </strong></p>
      <p>
        <p><br/></p> 3.3.1. Вернуть товар и получить уплаченную за него сумму в течение 10 рабочих дней.
        <p><br/></p> 3.3.2. Дождаться поступления товара, если стороны достигнут соглашения.
      </p>
      <p><stromg>Порядок проверки качества товара В</stromg>3.4. Порядок проверки качества товара В соответствии со статьей 428 ГК РК: «При
        необходимости продавец вправе провести проверку качества товара. В случае, если проверка
        установит, что недостатки возникли по вине покупателя, последний обязан возместить продавцу
        все расходы, связанные с проверкой и транспортировкой товара.».</p>
      <p><br/></p>
      <p>3.4.1. Покупатель вправе участвовать в проверке лично или через представителя.
        <p><br/></p> 3.4.2. Если товар признан надлежащего качества, расходы на проверку оплачиваются
        покупателем</p>
      <p><strong>3.5. Возмещение транспортных расходов: </strong> Продавец обязан компенсировать все транспортные
        и прочие расходы, понесенные покупателем при возврате товара ненадлежащего качества, в
        соответствии с <strong> статьей 30 Закона РК «О защите прав потребителей». </strong></p>
      <p> <strong>3.6.  Особые условия для технически сложных товаров: </strong>Согласно статье 15 Закона РК «О
        защите прав потребителей», в случае выявления существенного недостатка в технически
        сложном товаре, покупатель вправе:</p>
      <p><br/></p>
      <p>3.6.1. Требовать замены на аналогичный товар или на товар другой марки с перерасчетом
        стоимости.
        <p><br/></p> 3.6.2. Расторгнуть договор и потребовать возврата уплаченных средств.</p>
      <p><br/></p>
      <p><strong>3.7. Возмещение дополнительных расходов:  </strong>В соответствии с пунктом 8 статьи 30 Закона РК
        «О защите прав потребителей»: «Продавец обязан возместить покупателю расходы на доставку
        и (или) возврат товара ненадлежащего качества, а также расходы на проверку качества товара.».</p>
      <p><strong>3.8. Исключение из ответственности продавца:</strong></p>
      <p>3.8.1. Продавец не несет ответственность за недостатки товара, если:
        <p><br/></p> 3.8.1.1. Товар поврежден покупателем вследствие нарушения условий хранения или
        эксплуатации.
        <p><br/></p> 3.8.1.2. Недостатки возникли в результате действий третьих лиц или непреодолимой силы.
        <p><br/></p> 3.8.1.3. Товар был продан с оговоркой о наличии определенных недостатков, и покупатель
        был проинформирован об этом при покупке</p>
      <p><br/></p>
      <p><strong>4. Товары, не подлежащие возврату и обмену: </strong></p>
      <p>4.1. Исключения из права на возврат и обмен: Согласно статье 30 Закона Республики
        Казахстан «О защите прав потребителей», не подлежат возврату и обмену:</p>
      <p>
        <p><br/></p> 4.1.1. Продовольственные товары и готовые блюда, если они надлежащего качества.
        <p><br/></p> 4.1.2. Лекарственные средства и медицинские изделия.
        <p><br/></p> 4.1.3. Нательное белье и чулочно-носочные изделия.
        <p><br/></p> 4.1.4. Животные и растения.
        <p><br/></p> 4.1.5. Товары, продаваемые на метраж: ткани, провода, ленты, линолеум и другие подобные
        товары.
        <p><br/></p> 4.1.6. Абонентские устройства сотовой связи
      </p>
      <strong>4.2. Продукция, изготовленная на заказ:</strong>
      <p>4.2.1. Индивидуальные заказы и товары, изготовленные по спецификациям покупателя,
        не подлежат возврату, если они соответствуют заявленным требованиям и не имеют недостатков</p>
      <strong>4.3. Истекший срок годности и несоответствие стандартам:</strong>
      <p>4.3.1. Товары с истекшим сроком годности или не соответствующие требованиям
        регламентов и стандартов могут быть возвращены в течение 30 календарных дней с момента
        покупки.</p>
      <strong>4.4. Технически сложные товары:</strong>
      <p>4.4.1. Возврат и обмен технически сложных товаров возможен только при выявлении
        существенного недостатка, делающего использование товара невозможным (пункт 1-1 статьи
        15 Закона РК «О защите прав потребителей»).</p>
      <strong>4.5. Проверка товара при получении: Покупатель обязан проверить товар в момент
        доставки. Претензии по внешнему виду и комплектности принимаются только во время
        получения товара.</strong>
      <strong>4.6. Комплектность и упаковка при возврате:</strong>
      <p>4.6.1. Возвращаемый товар должен быть:
        <p><br/></p> 4.6.1.1. В полной комплектности,
        <p><br/></p>4.6.1.2. С инструкцией и сопроводительными документами (если они прилагались),
        <p><br/></p> 4.6.1.3. В неповрежденной оригинальной упаковке.</p>
      <strong>4.7. Транспортные расходы:</strong>
      <p>4.7.1. При возврате товара надлежащего качества транспортные расходы несет
        покупатель.
        <p><br/></p>  4.7.2. При возврате товара ненадлежащего качества все расходы на транспортировку и
        экспертизу компенсирует продавец.</p>
      <p><br/></p>
      <strong>4.8. Основания для отказа в возврате:</strong>
      <p>
        <p><br/></p> 4.8.1. Продавец может отказать в возврате или обмене, если:
        <p><br/></p>4.8.1.1. Товар использовался и утратил товарный вид.
        <p><br/></p> 4.8.1.2. Нарушены условия эксплуатации или хранения, указанные в инструкции.
        <p><br/></p> 4.8.1.3. Отсутствуют документы, подтверждающие покупку. В случае утери чека покупатель
        должен предоставить иные доказательства покупки (например, банковская выписка).
        <strong>5. Порядок возврата денежных средств и обмена товара</strong>
        <p>5.1. Возврат денежных средств при отказе от товара: Если покупатель возвращает товар
          надлежащего качества в соответствии с пунктом 2.2 ГК РК, продавец обязан вернуть полную
          стоимость товара в течение 10 рабочих дней с момента получения товара и подтверждения его
          состояния.</p>
        <strong>5.2. Порядок обмена товара:</strong>
        <p>5.2.1. Обмен товара надлежащего качества производится в течение 14 календарных дней с
          момента покупки (статья 454 ГК РК).
          <p><br/></p>5.2.2. При необходимости перерасчета стоимости разница компенсируется:
          <p><br/></p> 5.2.2.1. В случае, если товар для замены дороже, покупатель доплачивает разницу.
          <p><br/></p>5.2.2.2. Если товар дешевле, продавец возвращает разницу в цене покупателю.</p>
      </p>
      <strong>5.3. Возврат и обмен товара ненадлежащего качества:</strong>
      <p>5.3.1. В соответствии с пунктом 1 статьи 455 ГК РК, покупатель вправе:
        <p><br/></p>5.3.1.1. Потребовать замены товара на аналогичный или товар другой марки с перерасчетом
        стоимости.
        <p><br/></p>5.3.1.2. Расторгнуть договор и получить полную сумму за товар.
        <p><br/></p> 5.3.1.3. Потребовать соразмерного уменьшения цены или устранения недостатков.</p>
      <strong>5.4. Сроки возврата и обмена:</strong>
      <p>5.4.1. При обмене или возврате денежных средств продавец обязан выполнить
        обязательства не позднее 10 рабочих дней с момента получения возвращенного товара.
        <p><br/></p> 5.4.2. В случае замены товара при отсутствии нужной позиции у продавца срок выполнения
        требования может быть продлен, но не более чем на 30 календарных дней (статья 30 Закона РК
        «О защите прав потребителей»).</p>
      <strong>5.5. Компенсация транспортных расходов:</strong>
      <p>5.5.1. При возврате или обмене товара ненадлежащего качества продавец обязан
        компенсировать все расходы на транспортировку и экспертизу.
        <p><br/></p> 5.5.2. При возврате товара надлежащего качества транспортные расходы несет покупатель.</p>
      <strong>5.6. Порядок возврата через курьерские службы:</strong>
      <p>5.6.1. Если товар был доставлен через курьерскую службу, покупатель может оформить
        возврат через ту же службу.
        <p><br/></p>5.6.2. Покупатель обязан правильно упаковать товар, чтобы избежать повреждений при
        транспортировке. Продавец вправе отказать в возврате, если товар поврежден по вине
        покупателя.</p>
      <strong>5.7. Документы для возврата и обмена:</strong>
      <p>5.7.1. Для оформления возврата или обмена необходимо:
        <p><br/></p>5.7.1.1. Заявление на возврат или обмен с указанием причины.
        <p><br/></p>5.7.1.2. Документы, подтверждающие покупку (чек, квитанция или банковская выписка).
        <p><br/></p>5.7.1.3. Полная комплектация товара (при наличии: инструкция, гарантийный талон и
        упаковка).</p>
      <strong>5.8. Ответственность при нарушении сроков возврата: </strong>
      <p>5.8.1. В соответствии с пунктом 6 статьи 30 Закона РК, продавец обязан выплатить
        неустойку в размере 1% от стоимости товара за каждый день просрочки, если обязательства по
        возврату или обмену не выполнены вовремя.</p>
      <strong>6. Заключительные положения</strong>
      <strong>6.1. Применимое законодательство:</strong>
      <p>6.1.1. Все отношения, возникающие в рамках настоящей Политики возврата, регулируются
        Гражданским кодексом Республики Казахстан, Законом Республики Казахстан «О защите
        прав потребителей», а также иными нормативно-правовыми актами, действующими на
        территории Республики Казахстан</p>
      <strong>6.2. Изменение условий политики возврата:</strong>
      <p>6.2.1. Платформа DOM FOOD (ДОМ ФУД) оставляет за собой право вносить изменения в
        Политику возврата без предварительного уведомления.
        <p><br/></p>6.2.2. Обновленная версия Политики вступает в силу с момента ее размещения на сайтах
        https://dom-food.com и https://domfood.kz, если иное не предусмотрено в новой редакции.
        <p><br/></p>6.2.3. Все изменения распространяются на правоотношения, возникшие после вступления
        изменений в силу</p>
      <strong>6.3. Контактная информация для вопросов по возврату:</strong>
      <p>6.3.1. E-mail: support@dom-food.com
        <p><br/></p>6.3.2. Телефон: +7 777 9001033.
        <p><br/></p>6.3.3. Юридический адрес: Казахстан, г. Алматы, Алмалинский район, ул. Карасай Батыра,
        дом 193Б, индекс 050008.</p>
      <strong>6.4. Порядок урегулирования споров:</strong>
      <p>6.4.1. Споры, возникающие между покупателем и продавцом (или платформой DOM FOOD
        (ДОМ ФУД)) относительно возврата или обмена товара, подлежат урегулированию в
        претензионном порядке.
        <p><br/></p> 6.4.2. Претензия направляется на юридический адрес продавца или через электронную
        почту платформы.
        <p><br/></p>  6.4.3. Продавец или платформа обязаны рассмотреть претензию в течение 30
        календарных дней с момента получения и предоставить письменный ответ покупателю.</p>
      <strong>6.5. Передача спора в судебные органы:</strong>
      <p>6.5.1. В случае, если стороны не пришли к соглашению в досудебном порядке, спор
        передается на рассмотрение суда в соответствии с законодательством Республики Казахстан.
        <p><br/></p>6.5.2. Местом рассмотрения спора является территориальный суд по месту нахождения
        продавца или покупателя, в зависимости от условий договора</p>
      <strong>6.6. Форс-мажорные обстоятельства (обстоятельства непреодолимой силы):</strong>
      <p>6.6.1. Продавец и платформа не несут ответственности за задержки или невозможность
        выполнения обязательств по возврату или обмену товара, если это вызвано форс-мажорными
        обстоятельствами, такими как:
        <p><br/></p>  6.6.1.1. Природные катаклизмы (землетрясения, наводнения и др.).
        <p><br/></p>  6.6.1.2. Военные действия или массовые беспорядки.
        <p><br/></p> 6.6.1.3. Ограничения со стороны государственных органов, введение чрезвычайного
        положения или блокировка финансовых операций.
        <p><br/></p>  6.6.2. В случае наступления форс-мажорных обстоятельств, платформа обязуется
        уведомить покупателей в кратчайшие сроки</p>
      <strong>6.7. Действительность положений политики:</strong>
      <p>6.7.1. Если какое-либо положение настоящей Политики будет признано недействительным
        или утратившим силу, это не влияет на действительность остальных положений.
        <p><br/></p>  6.7.2. Положения, противоречащие законодательству, автоматически заменяются нормами,
        соответствующими действующим нормативным актам Республики Казахстан.</p>
      <strong>6.8. Политика возврата как часть пользовательского соглашения:</strong>
      <p>6.8.1. Настоящая Политика возврата является неотъемлемой частью пользовательского
        соглашения, размещенного на сайтах DOM FOOD. При оформлении заказа пользователь
        подтверждает свое согласие с условиями Политики.
        <p><br/></p>6.8.2. В случае несогласия с условиями возврата, пользователь обязан прекратить
        использование сервиса и воздержаться от оформления заказов на платформе.</p>
    </div>
  )
}

export default ReturnPolicy;
