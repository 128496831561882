import React, {useState} from "react";
import CustomModal from "./CustomModal";
import CourierForm from "./Forms/CourierForm";
import Courier from './../images/CourierImage.png'

const BlockOne = () => {
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);

  return (
    <div className="my-6 flex flex-col-reverse md:flex-row pr-4 md:pr-16 lg:pr-20 xl:pr-[150px] pl-4 lg:pl-[50px] xl:pl-[85px] items-center justify-around">
      <img className="lg:w-full w-[400px] max-w-[600px]" src={Courier} alt="png" />
      <div className="mb-10 md:mb-0">
        <div className="flex gap-x-2 justify-center md:justify-start">
          <p className="text-[#333F51] text-[22px] font-bold lg:text-xl xl:text-3xl">Стань курьером</p>
          <p className="text-[22px] lg:text-xl xl:text-3xl text-[#FDA110] font-bold">DomFood</p>
        </div>
        <p className="mt-[28px] text-base lg:text-xl text-center">Оставь заявку и начни зарабатывать вместе с Domfood</p>
        <div className="mt-[28px] text-base lg:text-xl space-y-2 font-normal">
          <li>0% комиссии до конца года</li>
          <li>Стабильные выплаты</li>
          <li>Гибкий график</li>
        </div>
        <div onClick={() => setIsOpenMobileNav(true)} className="flex justify-center md:justify-start">
          <button className="mt-[28px] rounded-[30px] h-[52px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] text-white px-10 font-bold">Стать курьером</button>
        </div>
      </div>
      <CustomModal setIsOpenMobileNav={setIsOpenMobileNav} isOpenMobileNav={isOpenMobileNav}>
        <CourierForm setIsOpenMobileNav={setIsOpenMobileNav} />
      </CustomModal>
    </div>
  )
}

export default BlockOne

