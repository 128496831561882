import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Arrow from '../images/arrowSelect.png';
import ArrowTop from '../images/arrowSelectTop.png';

const CitySelect = ({ formik }) => {
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState('Выберите регион');
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState('Выберите город');
  const [isRegionOpen, setIsRegionOpen] = useState(false);
  const [isCityOpen, setIsCityOpen] = useState(false);

  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await axios.get('https://api.dom-food.com/page/states');
        setRegions(response.data.data);
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    fetchRegions();
  }, []);

  const toggleRegionDropdown = () => {
    setIsRegionOpen(!isRegionOpen);
    setIsCityOpen(false);
  };

  const toggleCityDropdown = () => {
    setIsCityOpen(!isCityOpen);
    setIsRegionOpen(false);
  };

  const selectRegion = async (region) => {
    setSelectedRegion(region.name);
    formik.setFieldValue('state_id', region.id)
    setSelectedCity('Выберите город');
    setIsRegionOpen(false);
    try {
      const response = await axios.get(`https://api.dom-food.com/page/states/${region.id}`);
      setCities(response.data.data[0]?.cities || []);
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  const selectCity = (city) => {
      setSelectedCity(city);
    formik.setFieldValue('city_id', city.id)

    setIsCityOpen(false);
  };

  return (
    <div className="space-y-4">
      <div className="relative w-full">
        <div
          onClick={toggleRegionDropdown}
          className="max-h-[50px] flex justify-between items-center p-4 border border-gray-400 rounded-full cursor-pointer hover:border-orange-500"
        >
          <span className="text-gray-400">{selectedRegion}</span>
          <img src={isRegionOpen ? ArrowTop : Arrow} alt="toggle arrow" />
        </div>
        {isRegionOpen && (
          <div className="absolute mt-2 w-full border border-gray-400 rounded-xl bg-white z-40">
            {regions?.map((region, index) => (
              <div
                key={index}
                onClick={() => selectRegion(region)}
                className="p-3 hover:bg-gray-100 cursor-pointer text-start text-[#333F51] border-b border-gray-[#E5E7EB]"
              >
                {region.name}
              </div>
            ))}
          </div>
        )}
      </div>

      {selectedRegion !== 'Выберите регион' && (
        <div className="relative w-full">
          <p className="text-[#333F51] text-lg text-start my-2">Город</p>
          <div
            onClick={toggleCityDropdown}
            className="max-h-[50px] flex justify-between items-center p-4 border border-gray-400 rounded-full cursor-pointer hover:border-orange-500"
          >
            <span className="text-gray-400">{selectedCity.name}</span>
            <img src={isCityOpen ? ArrowTop : Arrow} alt="toggle arrow" />
          </div>
          {isCityOpen && (
            <div className="absolute mt-2 w-full border border-gray-400 rounded-xl bg-white z-40">
              {cities?.map((city, index) => (
                <div key={index} className="p-3 hover:bg-gray-100 rounded-xl">
                  <div
                    key={index}
                    onClick={() => selectCity(city)}
                    className="hover:bg-gray-100 cursor-pointer rounded-xl text-start text-[#333F51] border-b border-gray-[#E5E7EB]"
                  >
                    {city.name}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CitySelect;
