import Image1 from './../images/new-1.jpg'
import image2 from './../images/new-2.jpg'
import image3 from './../images/new-4.png'
import image4 from './../images/new-3.png'
import Process from './../images/proces.png'
import CoverHow from "../images/coverHow.png";

const data = [
  {icon: Image1, title: 'Заполнение заявки', subtitle: 'Перейдите на сайт DomFood и заполните форму регистрации, указав основную информацию о себе и своем кулинарном бизнесе.'},
  {icon: image2, title: 'Создание меню', subtitle: 'Разработайте и загрузите ваше меню на платформу. Мы берем на себя фотоссесию Ваших блюд и предоставляем Вам эту услугу бесплатно. Добавьте описания блюд, фотографии и цены.'}
]

const dataTwo = [
  {icon: image3, title: 'Загрузка документов', subtitle: 'Предоставьте необходимые документы для подтверждения вашей квалификации и соблюдения санитарных норм.'},
  {icon: image4, title: 'Начало приема заказов', subtitle: 'После одобрения вашей заявки, вы можете начинать принимать заказы и готовить вкусные блюда для наших клиентов. Наши курьеры доставят до клиентра'}
]

const gridItemStyle = {
  backgroundImage: `url(${CoverHow})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  position: 'relative'
}

const HowItsWork = () => {
  return (
    <div className="mt-10 lg:mt-40">
        <div className="flex justify-center">
          <div className="w-[330px] text-nowrap">
            <p className="gradient-text text-xl md:text-3xl font-bold text-center md:text-start">Как это работает?</p>
          </div>
        </div>
          <div className="px-4 md:px-[30px] lg:px-[50px] bg-[#ffffff] mt-4 md:mt-20">
            <div style={gridItemStyle} className="flex flex-col rounded-xl md:flex-row px-4 md:px-[30px] lg:px-[60px] xl:px-[160px] justify-center md:justify-around py-6 md:py-[115px]">
              <div className="space-y-10 w-full">
                {data.map((item,index) => {
                  return (
                    <div className="shadow-xl w-full md:w-[320px] lg:w-[384px] flex items-center flex-col bg-white max-h-[350px] md:max-h-[403px] min-h-[350px] md:min-h-[403px] rounded-2xl py-4 md:py-[20px] px-4 lg:px-[32px]" key={index}>
                      <img className="max-w-[100px] md:max-w-[120px] max-h-[100px] md:max-h-[120px]" src={item.icon} alt="svg" />
                      <p className='gradient-text text-[26px] md:text-2xl mt-[10px] font-bold'>{item.title}</p>
                      <p className="text-base md:text-lg	text-[#333333] mt-[10px] text-center">{item.subtitle}</p>
                    </div>
                  )
                })}
              </div>
               <img className="hidden xl:block absolute" src={Process} alt="png" />
              <div className="space-y-10 mt-8 md:mt-[210px]">
                {dataTwo.map((item,index) => {
                  return (
                    <div className="shadow-xl w-full md:w-[320px] lg:w-[384px] flex items-center flex-col bg-white max-h-[350px] md:max-h-[403px] min-h-[350px] md:min-h-[403px] rounded-2xl py-4 md:py-[20px] px-4 lg:px-[32px]" key={index}>
                      <img className="max-w-[100px] md:max-w-[120px] max-h-[100px] md:max-h-[120px]" src={item.icon} alt="svg" />
                      <p className='gradient-text text-xl md:text-2xl mt-[10px] font-bold'>{item.title}</p>
                      <p className="text-base md:text-lg	text-[#333333] mt-[10px] text-center">{item.subtitle}</p>
                    </div>
                  )
                })}
              </div>
          </div>
          </div>
      </div>
  )
}

export default HowItsWork

