import BlockOne from "../components/BlockOne";
import About from "../components/About";
import ForFooder from "../components/ForFooder";
import HowItsWork from "../components/HowItsWork";
import ContactUsBlock from "../components/ContactUsBlock";
import BecomeCourier from "../components/BecomeCourier";
import QuestionsBlock from "../components/QuetionsBlock";

const Home = () => {
  return (
    <div>
      <div id="blockOne">
        <BlockOne />
      </div>
      <div id="becomeCourier">
        <BecomeCourier />
      </div>
      <div id="about">
        <About />
      </div>
      <div id="forFooder">
        <ForFooder />
      </div>
      <div id="howItsWork">
        <HowItsWork />
      </div>
      <div id="questionsBlock">
        <QuestionsBlock />
      </div>
      <div id="contactUsBlock">
        <ContactUsBlock />
      </div>
    </div>
  );
};

export default Home;
