import { useState } from 'react';
import Arrow from "../images/arrowSelect.png";
import ArrowTop from "../images/arrowSelectTop.png";

const radius = [
  1000,2000,3000,4000,5000,6000,7000,8000,9000,10000
]

const SelectRadius = ({ formik }) => {
  const [selectedRadius, setSelectedRadius] = useState('Выберите здесь');
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectRadius = (radius) => {
    formik.setFieldValue('radius', radius);
    setSelectedRadius(radius);
      setIsOpen(false);
  };

  return (
    <div className="relative w-full">
      <div
        onClick={toggleDropdown}
        className="max-h-[50px] flex justify-between items-center p-4 border border-1 border-gray-400 rounded-full cursor-pointer hover:border-orange-500"
      >
        <span className="text-gray-400">{selectedRadius}</span>
        <img src={isOpen ? Arrow : ArrowTop} alt="png" />
      </div>

      {isOpen && (
        <div className="overflow-y-scroll max-h-[200px] hover:border-orange-500 absolute mt-2 w-full border border-1 border-gray-400 rounded-xl bg-white z-10">
          {radius.map((radius, index) => (
            <div key={index} className="p-3 hover:bg-gray-100 border-b rounded-xl">
              <label className="flex items-center">
                <input
                  type="radio"
                  name="radius"
                  value={radius}
                  onClick={() => selectRadius(radius)}
                  className="mr-2"
                />
                {radius}
              </label>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectRadius;
