import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ErrorMassage from "./ErrorMassage";

const YandexMap = ({ formik, errors }) => {
  const [address, setAddress] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(null);

  useEffect(() => {
    const loadYandexMaps = () => {
      const script = document.createElement('script');
      script.src = 'https://api-maps.yandex.ru/2.1/?lang=ru_RU';
      script.type = 'text/javascript';
      script.onload = () => {
        window.ymaps.ready(initMap);
      };
      document.head.appendChild(script);
    };

    const initMap = () => {
      const myMap = new window.ymaps.Map('map', {
        center: [51.143964, 71.435819],
        zoom: 17,
      });

      // Create a draggable marker
      const myMarker = new window.ymaps.Placemark(
        myMap.getCenter(),
        {},
        { draggable: true }
      );

      myMap.geoObjects.add(myMarker);
      setMap(myMap);
      setMarker(myMarker);

      myMarker.events.add('dragend', async () => {
        const coords = myMarker.geometry.getCoordinates();
        updateCoordinates(coords[0], coords[1]);
      });
    };

    loadYandexMaps();
  }, []);

  const fetchSuggestions = async (inputValue) => {
    try {
      const apiKey = 'a99add16-70c9-414d-83a1-8dd7c5b225a2';
      const response = await axios.get(
        `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&format=json&geocode=${inputValue}`
      );

      if (response.data) {
        const foundSuggestions =
          response.data.response.GeoObjectCollection.featureMember.map(
            (item) => item.GeoObject.metaDataProperty.GeocoderMetaData.text
          );
        setSuggestions(foundSuggestions);
      }
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
  };

  const handleAddressChange = (e) => {
    const inputValue = e.target.value;
    setAddress(inputValue);
    if (inputValue.length > 2) {
      fetchSuggestions(inputValue);
    } else {
      setSuggestions([]);
    }
  };

  const updateMapCenter = (latitude, longitude) => {
    if (map) {
      map.setCenter([latitude, longitude]);
      if (marker) {
        marker.geometry.setCoordinates([latitude, longitude]);
      }
    }
  };

  const updateCoordinates = async (latitude, longitude) => {
    formik.setFieldValue('lat', latitude);
    formik.setFieldValue('lng', longitude);

    const apiKey = 'a99add16-70c9-414d-83a1-8dd7c5b225a2';
    const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&format=json&geocode=${longitude},${latitude}`;

    try {
      const response = await axios.get(url);
      const geoObject = response.data.response.GeoObjectCollection.featureMember[0]?.GeoObject;
      const newAddress = geoObject?.metaDataProperty.GeocoderMetaData.text;
      setAddress(newAddress || 'Address not found');
      formik.setFieldValue('address', newAddress || '');
    } catch (error) {
      console.error('Error fetching address from coordinates:', error);
    }
  };

  const handleSuggestionClick = async (suggestion) => {
    setAddress(suggestion);
    formik.setFieldValue('address', suggestion);
    const result = await getCoordinates(suggestion);
    if (result) {
      updateMapCenter(result.latitude, result.longitude);
    }
    setSuggestions([]);
  };

  async function getCoordinates(address) {
    const apiKey = 'a99add16-70c9-414d-83a1-8dd7c5b225a2';
    const url = `https://geocode-maps.yandex.ru/1.x/?apikey=${apiKey}&format=json&geocode=${address}`;

    try {
      const response = await axios.get(url);
      if (response.data.response.GeoObjectCollection.featureMember.length > 0) {
        const firstGeoObject = response.data.response.GeoObjectCollection.featureMember[0].GeoObject;
        const coordinates = firstGeoObject.Point.pos.split(' ');

        const result = {
          latitude: parseFloat(coordinates[1]),
          longitude: parseFloat(coordinates[0]),
          title: firstGeoObject.metaDataProperty.GeocoderMetaData.text
        };
        formik.setFieldValue('lat', result.latitude);
        formik.setFieldValue('lng', result.longitude);
        return result;
      } else {
        console.log('Address not found');
        return null;
      }
    } catch (error) {
      console.error('Error fetching coordinates:', error);
    }
  }

  return (
    <div>
      <div style={{ marginBottom: '20px' }}>
        <input
          className="max-h-[50px] flex justify-between items-center p-4 border border-1 border-gray-400 rounded-full cursor-pointer hover:border-orange-500"
          type="text"
          value={address}
          onChange={handleAddressChange}
          placeholder="Enter address *"
          style={{ padding: '10px', width: '100%' }}
        />
        {errors.address && <ErrorMassage message={errors.address} />}
      </div>

      {suggestions.length > 0 && (
        <ul style={{ border: '1px solid #ccc', padding: '10px', width: '100%' }}>
          {suggestions.map((suggestion, index) => (
            <li
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              style={{ cursor: 'pointer', padding: '5px 0' }}
            >
              {suggestion}
            </li>
          ))}
        </ul>
      )}

      <div id="map" style={{ width: '100%', height: '400px' }}></div>
    </div>
  );
};

export default YandexMap;
