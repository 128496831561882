import {useEffect} from "react";

const AgreementUsers = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">СОГЛАШЕНИЕ О ЗАЩИТЕ ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ</h1>
      <p className="mt-4">Настоящее Соглашение о защите персональных данных Пользователей Сайта (далее —
        «Соглашение») разработано в соответствии с Законом Республики Казахстан от 21 мая 2013
        года № 94-V «О персональных данных и их защите», а также другими нормативными
        правовыми актами Республики Казахстан (далее совместно — «Закон»).
        Соглашение регулирует отношения, возникающие между Товариществом с ограниченной
        ответственностью «DOM FOOD (ДОМ ФУД)» (далее — «Компания») и Пользователями Сайта
        в процессе сбора и обработки их персональных данных. Компания действует через Директора —
        Евлоева Абдуллу Аслановича на основании Устава. БИН: 240940031813, адрес места
        нахождения: Республика Казахстан, г. Алматы, Алмалинский район, ул. Карасай Батыра, д. 193Б,
        почтовый индекс 050008, электронный адрес Сайта: https://dom-food.com и https://domfood.kz/,
        электронная почта support@dom-food.com, контактный телефон: +7 777 9001033; ИИК:
        KZ46722S000040853571; БИК: CASPKZKA (АО «Kaspi Bank»), КБе: 17 (далее - Компания)
        Персональных данных Пользователей Сайта.</p>
      <h3 className="font-bold text-2xl mt-4 text-center">1. ПОНЯТИЯ И ТЕРМИНЫ</h3>
      <p>
        <p><br/></p> 1.1. Для целей Соглашения все слова и выражения, написанные с заглавных букв и не
        раскрытые в тексте данного Соглашения, имеют значения и (или) определения, установленные
        действующим законодательством Республики Казахстан.
        <p><br/></p> 1.2. Сайт — интернет-ресурс "DOM FOOD (ДОМ ФУД)", содержащий информацию в
        текстовом, графическом, аудиовизуальном или ином формате, размещенную на аппаратнопрограммном комплексе с уникальным сетевым адресом и (или) доменным именем,
        функционирующим в сети Интернет по адресам https://dom-food.com и https://domfood.kz.
        <p><br/></p>  1.3. Пользователь — субъект персональных данных, предоставивший Компании свои
        персональные данные на основании запроса Компании.
        <p><br/></p> 1.4. Стороны — совместно именуемые в Соглашении Компания и Пользователь.
        <p><br/></p> 1.5. Персональные данные — сведения, относящиеся к Пользователю, а также любые их
        изменения и (или) дополнения в будущем, зафиксированные на электронных, бумажных или
        иных материальных носителях, включая, но не ограничиваясь:
        <p><br/></p> 1.5.1.фамилия, имя, отчество (при наличии);
        <p><br/></p>  1.5.2. индивидуальный идентификационный номер (ИИН);
        <p><br/></p>  1.5.3. дату рождения;
        <p><br/></p>  1.5.4. почтовый адрес (место жительства), номер мобильного телефона и адрес электронной
        почты;
        <p><br/></p>  1.5.5. данные о банковском счете или карте.
        <p><br/></p>  1.6. Обработка персональных данных — любые действия или совокупность действий,
        совершаемые Компанией в отношении персональных данных Пользователя, включая сбор,
        систематизацию, накопление, уточнение (обновление или изменение), использование,
        распространение (включая передачу), обезличивание, блокирование, уничтожение и бессрочное
        хранение Персональных данных.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. ПРЕДМЕТ СОГЛАШЕНИЯ</h3>
    <p>
      <p><br/></p> 2.1. Пользователь предоставляет Компании, как оператору персональных данных, свои
      данные и выражает полное и безоговорочное согласие на их обработку. Это согласие
      предоставляется свободно и добровольно, на условиях, установленных настоящим Соглашением.
      Обработка данных выполняется в электронном виде и используется исключительно для целей,
      предусмотренных данным документом.
      <p><br/></p>2.2. Дата выдачи согласия на обработку персональных данных совпадает с моментом, когда
      Пользователь ознакомился с Соглашением и нажал кнопку «Зарегистрироваться» на странице
      регистрации. Согласие действует бессрочно, но может быть отозвано в любой момент путем
      направления письменного уведомления в адрес Компании заказным почтовым отправлением.
      Согласие считается отозванным через 10 (десять) рабочих дней после получения уведомления
      Компанией.
      <p><br/></p> 2.3. Обработка персональных данных осуществляется Компанией для следующих целей,
      включая, но не ограничиваясь:
      <p><br/></p> 2.3.1. проведение внутренних и внешних исследований для повышения качества и доступности
      предоставляемых услуг;
      <p><br/></p>2.3.2. реализация маркетинговых программ, статистических исследований и анализа данных для
      разработки новых сервисов и улучшения текущих;
      <p><br/></p> 2.3.3. продвижение услуг Сайта, включая отправку персонализированной (таргетированной)
      рекламы, взаимодействие с Пользователем через почтовую рассылку, электронную почту,
      телефонную связь и иные каналы.
      <p><br/></p> 2.3.4. обеспечение безопасности операций, в том числе для предотвращения мошенничества,
      защиты прав Компании и Пользователей.
      <p><br/></p> 2.3.5. выполнение обязательств Компании перед Пользователем, включая уведомление о статусе
      заказов, акциях и изменениях на платформе.
      <p><br/></p>2.4. Обязательства Компании по защите персональных данных включают:
      <p><br/></p> 2.4.1. предотвращение несанкционированного доступа к персональным данным Пользователя;
      <p><br/></p> 2.4.2. оперативное выявление фактов утечки или несанкционированного доступа и принятие мер
      по их устранению;
      <p><br/></p> 2.4.3.минимизацию негативных последствий в случае утечки данных или иного инцидента;
      <p><br/></p> 2.4.4. внедрение передовых технических и организационных мер для защиты данных,
      соответствующих международным стандартам.
      <p><br/></p> 2.5. Обязательства Компании по защите Персональных данных вступают в силу с момента
      получения данных и действуют до их уничтожения или обезличивания. Компания обязуется
      уведомить Пользователя в случае изменения условий обработки данных.
      <p><br/></p> 2.6. Отзыв согласия на обработку данных может быть осуществлен Пользователем в любой
      момент путем направления письменного уведомления в адрес Компании за 10 (десять) рабочих
      дней до даты отзыва. Однако отзыв согласия не распространяется на следующие случаи:
      <p><br/></p>  2.6.1. если отзыв противоречит требованиям законодательства или правовым обязательствам
      Компании;
      <p><br/></p> 2.6.2. при наличии невыполненных обязательств Пользователя перед Компанией;
      <p><br/></p> 2.6.3. если обработка или передача данных необходима для исполнения договорных
      обязательств перед третьими лицами или государственными органами;
      <p><br/></p> 2.6.4. при необходимости выполнения требований по трансграничной передаче данных для
      обеспечения услуг, предусмотренных соглашением.
      <p><br/></p> 2.7. Трансграничная передача данных: В случае необходимости Компания вправе
      осуществлять передачу персональных данных в другие страны при условии соблюдения
      требований законодательства Республики Казахстан и обеспечения надлежащего уровня защиты
      данных
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. ПРОЧИЕ УСЛОВИЯ</h3>
    <p>
      <p><br/></p> 3.1. Вступление в силу:
      <p><br/></p> 3.1.1.Соглашение вступает в силу с момента, указанного в пункте 2.2 настоящего документа,
      и продолжает действовать до момента полного исполнения Сторонами всех принятых на себя
      обязательств или до его расторжения в установленном порядке.
      <p><br/></p> 3.2. Частичная недействительность: Если какое-либо из положений настоящего Соглашения
      будет признано недействительным, незаконным или утратившим юридическую силу, это не
      затрагивает действительность и юридическую силу остальных положений. В таком случае
      стороны обязуются заменить недействительное положение новым, максимально соответствующим
      цели и смыслу изначального положения.
      <p><br/></p>  3.3. Изменение и дополнение Соглашения: Компания оставляет за собой право вносить
      изменения и дополнения в настоящее Соглашение в одностороннем порядке. Обновленная версия
      Соглашения публикуется на Сайте, и продолжение пользования Сайтом после публикации таких
      изменений считается согласием Пользователя с новыми условиями.
      <p><br/></p> 3.4. Форс-мажор: Компания и Пользователь освобождаются от ответственности за частичное
      или полное неисполнение обязательств по Соглашению, если это вызвано обстоятельствами
      непреодолимой силы (форс-мажор), такими как природные катастрофы, военные действия, акты
      государственных органов и другие непредвиденные события, которые невозможно предвидеть
      или предотвратить.
      <p><br/></p>3.5. Применимое право и разрешение споров: Все вопросы, не урегулированные настоящим
      Соглашением, регулируются в соответствии с законодательством Республики Казахстан. Споры
      и разногласия, возникающие между Сторонами в связи с настоящим Соглашением, разрешаются
      путем переговоров. Если Стороны не достигнут соглашения, спор передается на рассмотрение в
      суд по месту нахождения Компании.
      <p><br/></p> 3.6. Порядок расторжения Соглашения: Пользователь вправе в любое время расторгнуть
      Соглашение, прекратив использование Сайта и направив письменное уведомление Компании.
      Компания также вправе расторгнуть Соглашение в случае нарушения Пользователем его
      условий. В случае расторжения обязательства по защите персональных данных сохраняют силу
      до полного уничтожения или обезличивания данных.
      <p><br/></p> 3.7. Конфиденциальность: Все персональные данные, предоставленные Пользователем,
      являются конфиденциальными. Компания обязуется не передавать персональные данные третьим
      лицам без согласия Пользователя, за исключением случаев, предусмотренных законодательством
      Республики Казахстан.
      <p><br/></p> 3.8. Уведомления и связи с Пользователем: Все уведомления, связанные с данным
      Соглашением, направляются Пользователю на указанный при регистрации адрес электронной
      почты. Пользователь обязуется своевременно обновлять контактные данные в случае их
      изменения.
      <p><br/></p>  3.9. Права Пользователя:
      <p><br/></p>  3.9.1. Пользователь имеет право:
      • Запрашивать информацию о целях и способах обработки его персональных данных;
      • Требовать уточнения или исправления данных в случае их неточности;
      • Получать информацию о передаче данных третьим лицам, если такая передача
      предусмотрена.
      <p><br/></p>  3.10. Ответственность Сторон: Компания несет ответственность за надлежащее выполнение
      обязанностей по защите персональных данных. В случае нарушения обязательств по защите
      данных, Пользователь имеет право требовать возмещения причиненного ущерба в соответствии с
      законодательством Республики Казахстан. Пользователь несет ответственность за достоверность
      предоставляемых данных и использование Сайта в соответствии с условиями Соглашения.
    </p>
    </div>
  )
  }

  export default AgreementUsers