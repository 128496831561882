import {useEffect} from "react";

const RulesChef = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">ПРАВИЛА ОТБОРА И ОКАЗАНИЯ УСЛУГ ПОЛЬЗОВАТЕЛЕМ (ПОВАРОМ)</h1>
      <p className="mt-4">
        Настоящие Правила отбора и оказания услуг Пользователем (далее — Повар) являются
        неотъемлемой частью и приложением к Публичной оферте оказания электронных услуг Компанией
        Товарищество с ограниченной ответственностью «DOM FOOD (ДОМ ФУД)» Принятие условий
        настоящих Правил осуществляется Поваром путем присоединения к условиям Договора-Оферты и
        проставления галочки в соответствующем поле на Сайте https://dom-food.com/ и https://domfood.kz/.
        Такое действие считается полным и безоговорочным принятием условий настоящих Правил и
        обязательным условием для исполнения принятых на себя обязательств. Настоящие Правила
        вступают в силу с момента присоединения Пользователя к Договору-Оферте.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">1. ОТБОР ПОВАРОВ</h3>
        <p>
          <p><br/></p> 1.1. Кто может участвовать: Пользователем (далее — Повар) может быть физическое лицо
          (самозанятый), индивидуальный предприниматель или юридическое лицо, которые обладают
          соответствующей квалификацией и готовы предоставить услуги на платформе. Повар обязуется
          выполнять все требования законодательства в области приготовления и продажи пищевых
          продуктов.
          <p><br/></p>1.2. Требования к документам: Повар обязан предоставить необходимые документы, включая, но
          не ограничиваясь, сертификаты о санитарной безопасности, дипломы или другие документы,
          подтверждающие квалификацию. Документы должны быть предоставлены в течение 5 рабочих дней
          с момента начала сотрудничества. Эти документы будут опубликованы в аккаунте Повара на
          платформе для повышения доверия Покупателей. Повар несет ответственность за достоверность и
          актуальность представленных документов.
          <p><br/></p>1.3. Тестирование: Повар может приступить к работе на платформе сразу после регистрации и
          получения доступа к личному кабинету. Однако окончательное одобрение его профиля и блюд будет
          проведено после тестирования, которое включает проверку представленных документов и оценку
          качества предложенных блюд. В случае, если блюда не соответствуют стандартам качества, Повар
          будет уведомлен о необходимых доработках.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. АССОРТИМЕНТ БЛЮД И НАПИТКОВ ПРЕДОСТАВЛЯЕМЫЕ НА ПЛАТФОРМЕ</h3>
        <p>
          <p><br/></p> 2.1. Виды блюд и напитков: Исполнитель (Платформа - интернет ресурс на которой размещается
          готовая продукция) далее по тексту Платформа сотрудничает с поварами, предлагающими
          разнообразные блюда, включая халяль, национальные кухни, авторские рецепты, веганские блюда,
          кондитерские изделия, а также напитки (соки, смузи, чаи, кофе и другие напитки по выбору повара).
          Каждый Повар может выбирать ассортимент в зависимости от своих предпочтений и
          специализации, но обязан соблюдать все установленные стандарты качества и безопасности
          продуктов.
          <p><br/></p>2.2. Описание блюд и напитков: Повар обязан предоставить подробное описание каждого блюда и
          напитка, включающее:
          <p><br/></p>2.2.1. Состав (ингредиенты): Полный перечень ингредиентов, используемых при приготовлении
          блюда или напитка.
          <p><br/></p> 2.2.2. Наличие аллергенов: Указание на возможное присутствие аллергенов (например, орехи,
          молочные продукты, глютен и т.д.).
          <p><br/></p> 2.2.3. Порции и примерное время приготовления: Информация о размере порции и времени,
          необходимом для приготовления.
          <p><br/></p> 2.2.4. Вид подачи напитков: Для напитков указываются особенности подачи, например, "горячий",
          "холодный", "с льдом" и другие детали.
          <p><br/></p>  2.2.5. Фотография: Каждое блюдо и напиток должно сопровождаться качественной фотографией,
          которая будет отображаться на платформе. Повар должен стремиться к тому, чтобы внешний вид
          готового блюда или напитка при подаче максимально соответствовал изображению, однако
          допускаются небольшие изменения, вызванные процессом транспортировки.
          <p><br/></p>  2.3. Использование свежих ингредиентов: Повар обязуется использовать только свежие и
          качественные ингредиенты для приготовления блюд и напитков. Использование просроченных,
          испорченных или некачественных ингредиентов категорически недопустимо.
          <p><br/></p> 2.4. Отзывы Покупателей: Повар обязан внимательно следить за отзывами Покупателей,
          оставленными на платформе. В случае регулярных жалоб на качество блюд или напитков,
          Платформа имеет право инициировать проверку качества, связаться с поваром для получения
          разъяснений и при необходимости внести изменения в карточку повара, включая временное
          приостановление продаж до устранения выявленных недостатков.
          <p><br/></p> 2.5. Гибкость в описании: Описание блюд и напитков должно быть информативным, но
          допускается творческий подход для привлечения Покупателей. Повар может добавлять интересные
          факты о блюде, его истории или предложить нестандартные варианты подачи, что поможет привлечь
          внимание потенциальных покупателей.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. ПРОЦЕДУРА ТЕСТИРОВАНИЯ ГОТОВОЙ ПРОДУКЦИИ</h3>
        <p>
          <p><br/></p>  3.1. Начало сотрудничества: Повар может начать работу на платформе сразу после регистрации и
          заполнения профиля, включая указание всех необходимых данных о себе, своих блюдах и напитках.
          Однако окончательное одобрение и публикация его блюд и напитков возможны только после
          предоставления всех необходимых документов, которые должны быть предоставлены в течение 10
          рабочих дней с момента регистрации, и прохождения процедуры тестирования.
          <p><br/></p>  3.2. Тестирование блюд и напитков: Перед публикацией на платформе каждое блюдо или напиток
          должно быть протестировано. Повар обязан предоставить образцы для тестирования. Платформа
          проводит проверку на соответствие вкуса, внешнего вида, ингредиентов, и заявленным стандартам
          качества. Это тестирование также включает оценку уровня подготовки блюда в сравнении с
          представленным описанием и фотографией.
          <p><br/></p> 3.3. Периодическое тестирование: Для обеспечения стабильного качества продукции, Платформа
          может проводить повторное тестирование блюд и напитков. Это касается как новых предложений
          повара, так и ранее добавленных блюд, чтобы гарантировать, что качество остается на высоком
          уровне. Платформа оставляет за собой право проводить контрольные закупки, чтобы убедиться в
          соблюдении стандартов качества на постоянной основе.
          3.4. Обратная связь: По результатам тестирования Платформа предоставляет повару рекомендации
          и комментарии. Поварам необходимо учитывать эту обратную связь и вносить соответствующие
          изменения перед публикацией продукции. В случае, если блюдо не прошло тестирование, повар
          может внести корректировки и подать блюдо на повторную проверку. Окончательное решение о
          допуске блюда к продаже принимается на основании результатов повторного тестирования.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. ПРОЦЕСС ОФОРМЛЕНИЯ ЗАКАЗОВ НА ГОТОВУЮ ПРОДУКЦИЮ</h3>
      <p>
        <p><br/></p>4.1. Регистрация на платформе: Повар регистрируется на платформе, создавая свой профиль,
        заполняет карточку с предложениями блюд и напитков, прикрепляет фотографии, описания, цены и
        предоставляет все необходимые документы в течение 10 рабочих дней с момента регистрации. До
        прохождения всех процедур проверки, профили поваров и их блюда могут быть опубликованы с
        соответствующей отметкой о необходимости завершения проверки.
        <p><br/></p>4.2. Прием заказов: Заказы на блюда и напитки размещаются через платформу. Повар обязан
        оперативно реагировать на поступающие заказы, подтверждая их в течение указанного времени
        (например, 5-10 минут) с момента получения уведомления. В случае задержки повар должен
        немедленно уведомить платформу.
        <p><br/></p>4.3. Уведомления: Повар получает автоматические уведомления о новых заказах через систему. Он
        обязан следить за исправностью системы уведомлений, включая обновления приложения и
        стабильность интернет-соединения, чтобы избегать сбоев при приеме заказов.
        <p><br/></p>4.4. Обработка заказов: Повар обязуется подготовить заказ в срок, указанный в системе. Время
        приготовления блюда должно соответствовать заявленному в карточке блюда. Любое изменение
        времени приготовления требует согласования с платформой.
        <p><br/></p> 4.5. Чрезвычайные ситуации: В случае невозможности выполнения заказа (например, из-за
        отсутствия ингредиентов или технических проблем), повар обязан незамедлительно уведомить
        платформу через систему. Платформа в свою очередь уведомляет Покупателя о задержке или отмене
        заказа.
        <p><br/></p>4.6. Готовность заказа: После того как заказ готов, повар обязан отметить это в системе, кликнув
        "Заказ готов". Далее заказ должен быть передан курьеру для доставки или, если доставка
        осуществляется поваром, отправлен до покупателя в кратчайшие сроки.
        <p><br/></p> 4.7. Упаковка: Повар обязан использовать собственные упаковочные материалы до тех пор, пока
        платформа не предоставит фирменные упаковки. Расходы на упаковочные материалы несет повар.
        Все блюда и напитки, особенно жидкие продукты, должны быть упакованы таким образом, чтобы
        предотвратить порчу или разлив. Упаковка должна быть герметичной и надежной, чтобы избежать
        повреждений во время транспортировки.
        <p><br/></p> 4.8. Фотографирование заказа: Перед отправкой повар обязан сфотографировать готовый заказ и
        отправить фото в чат платформы. Это необходимо для контроля качества упаковки и разрешения
        возможных споров о состоянии заказа при получении Покупателем.
        <p><br/></p>  4.9. Коммуникация с Покупателями: Повар не имеет права общаться с Покупателями напрямую
        или через систему. Вся коммуникация с Покупателями осуществляется платформой от имени
        повара. Повар имеет право взаимодействовать исключительно с платформой, чтобы обеспечить
        единый стандарт обслуживания и избегать недоразумений.
        <p><br/></p> 4.10. Оплата: Повар принимает оплату исключительно через платформу. Любые другие формы
        расчета вне системы категорически запрещены. Платформа осуществляет выплаты повару на
        основании полученных и выполненных заказов.
        <p><br/></p>4.11. Изменение информации: Повар обязан своевременно обновлять информацию о блюдах и
        напитках, а также актуализировать данные о наличии продуктов, если что-то временно недоступно.
        Актуальность информации — ключевой аспект для поддержания качества обслуживания
        Покупателей.
        <p><br/></p>4.12. Скидки и акции: Повар может участвовать в акциях и скидках, организованных платформой.
        Участие в акциях требует своевременного обновления информации на странице повара, а также
        соблюдения всех условий, установленных платформой.
        <p><br/></p>4.13. Поддержка: Повар может обратиться в службу поддержки платформы для решения любых
        вопросов, связанных с заказами, техническими неполадками или другими операционными
        моментами. Платформа предоставляет поддержку, касающуюся технических вопросов, на
        постоянной основе бесплатно. Юридическая, бухгалтерская и другая поддержка предоставляется в
        течение первого месяца сотрудничества бесплатно, после чего может быть оказана на платной
        основе.
        <p><br/></p> 4.14. Контроль за выполнением: Платформа оставляет за собой право проверять своевременность
        и качество выполнения заказов. Повара обязаны соблюдать все внутренние правила платформы по
        обслуживанию Покупателей и предоставлению услуг, обеспечивая высокий уровень качества и
        безопасности продукции.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">5. ОСУЩЕСТВЛЕНИЕ ДОСТАВКИ ГОТОВОЙ ПРОДУКЦИИ</h3>
      <p>
        <p><br/></p> 5.1. Ответственность за доставку: Доставка готовой продукции может осуществляться как
        Платформой через курьерские службы, так и самим Поваром. Повар несет полную ответственность
        за своевременную передачу заказа курьеру или непосредственно покупателю. В случае, если
        доставка осуществляется Платформой, Повар должен обеспечить готовность заказа в сроки,
        указанные в системе.
        <p><br/></p> 5.2. Передача заказа курьеру: После того как заказ готов, Повар обязан отметить это в системе,
        кликнув "Заказ готов". Далее заказ должен быть передан курьеру в кратчайшие сроки после его
        готовности, не допуская излишних задержек. Если курьер уже ожидает заказ, Повар должен
        передать его максимально быстро, чтобы избежать задержек в доставке.
        <p><br/></p> 5.3. Собственная доставка: Если Повар самостоятельно осуществляет доставку, он полностью
        отвечает за своевременность и качество доставки. Повар обязан корректно взаимодействовать с
        Покупателем и при необходимости с курьерской службой, если она привлекается. Повар должен
        соблюдать все установленные стандарты и правила платформы, касающиеся доставки.
        <p><br/></p> 5.4. Качество упаковки: Повар обязан использовать собственные упаковочные материалы до тех
        пор, пока Платформа не предоставит фирменные упаковки. Расходы на упаковочные материалы
        несет Повар. Все блюда и напитки, особенно жидкие продукты, должны быть упакованы таким
        образом, чтобы предотвратить порчу или разлив. Упаковка должна быть герметичной и надежной,
        чтобы избежать повреждений во время транспортировки и сохранить внешний вид блюда.
        <p><br/></p>5.5. Фотографирование заказа: Перед отправкой заказа Повар обязан сделать фотографию готового
        заказа и отправить её в чат Платформы. Это позволит Платформе контролировать качество упаковки
        и поможет в разрешении споров, если заказ будет поврежден при доставке. Фотография должна
        четко показывать упаковку и готовое блюдо, чтобы подтвердить его соответствие заявленным
        стандартам.
        <p><br/></p> 5.6. Обратная связь по доставке: В случае возникновения проблем с доставкой (например,
        задержка, проблемы с курьером или отсутствие Покупателя по адресу), Повар обязан
        незамедлительно уведомить Платформу через систему, чтобы Покупатели могли быть оперативно
        проинформированы о статусе своего заказа. Платформа координирует все действия, направленные
        на минимизацию задержек и неудобств для Покупателей.
        <p><br/></p> 5.7. Контроль за сохранностью заказа: Повар несет полную ответственность за сохранность заказа
        до момента его передачи курьеру или Покупателю. Любые проблемы, возникшие из-за
        неправильной упаковки товара, несоответствующих условий хранения, а также повреждения заказа
        до момента передачи курьеру или Покупателю, являются ответственностью Повара. Платформа не
        несет ответственности за утрату или порчу заказов, произошедшие по причине нарушения Поваром
        правил упаковки и передачи. В случае возникновения споров Платформа оставляет за собой право
        выступать в роли посредника, однако Повар может понести ответственность, включая временное
        приостановление доступа к платформе или штрафные санкции, в случае систематических
        нарушений.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. ОСУЩЕСТВЛЕНИЕ КОНТРОЛЯ КАЧЕСТВА И ПОДДЕРЖКА СО СТОРОНЫ
        ИСПОЛНИТЕЛЯ</h3>
      <p>
        <p><br/></p> 6.1. Мониторинг качества: Платформа регулярно отслеживает качество предоставляемых услуг
        Поварами. Это включает в себя проверку отзывов Покупателей, анализ фотографий заказов и оценку
        соответствия заявленным стандартам. Платформа также может проводить контрольные закупки для
        оценки качества на практике.
        <p><br/></p>6.2. Аудиты:
        <p><br/></p>6.2.1. Платформа оставляет за собой право проводить аудит деятельности Повара в случае
        возникновения подозрений на нарушение условий договора, таких как использование сторонних
        каналов для оплаты, несоблюдение стандартов качества или нарушение санитарных норм.
        <p><br/></p>6.2.2. Аудиты могут включать проверку качества блюд, соблюдение стандартов санитарии и сроков
        выполнения заказов, а также соблюдение иных обязательств, установленных договором. Цель
        аудита — обеспечить соответствие предоставляемых услуг установленным стандартам и защитить
        интересы Покупателей Платформы.
        <p><br/></p> 6.3. Отзывы Покупателей: Повара обязаны внимательно следить за отзывами, оставляемыми
        Покупателями. В случае получения негативных отзывов, Повар обязан предпринять меры для
        устранения причин недовольства Покупателей. Платформа может предоставлять рекомендации и
        консультировать Повара для улучшения качества услуг и продукции.
        <p><br/></p>  6.4. Регулярные проверки: Платформа оставляет за собой право проводить регулярные проверки
        качества блюд и упаковки. Повар может быть обязан предоставить образцы блюд для тестирования,
        чтобы подтвердить соответствие заявленным стандартам. Эти проверки направлены на
        поддержание высокого уровня качества, гарантируя, что продукты и услуги соответствуют
        ожиданиям Покупателей.
        <p><br/></p> 6.5. Разрешение споров: В случае споров, связанных с качеством блюд или доставкой, Платформа
        проводит анализ ситуации, опираясь на предоставленные фотографии и данные о заказе. Если
        упаковка оказалась недостаточно надежной и это стало причиной порчи заказа, ответственность за
        это несет Повар. Платформа выступает посредником в таких спорах, чтобы защитить интересы
        Покупателей и обеспечить справедливое решение.
        <p><br/></p> 6.6. Меры за несоблюдение стандартов: В случае систематического нарушения стандартов
        качества (например, регулярные жалобы Покупателей на плохую упаковку или порчу блюд),
        Платформа оставляет за собой право принять меры в отношении Повара. Эти меры могут включать
        временное приостановление его доступа к платформе, наложение штрафных санкций, а также
        полное расторжение договора.
        <p><br/></p> 6.7. Поддержка поваров: В первый месяц сотрудничества Платформа оказывает Поварам
        бесплатную помощь в виде поддержки по налоговым, бухгалтерским и юридическим вопросам. Это
        позволяет Поварам сосредоточиться на своей основной деятельности и быстро адаптироваться к
        работе на платформе. Для получения поддержки Повара могут обратиться через специальный канал
        на платформе или в службу поддержки.
        <p><br/></p> 6.8. Платная поддержка: По истечении первого месяца сотрудничества, если Повар нуждается в
        дополнительной помощи по юридическим или бухгалтерским вопросам, Платформа может
        предоставлять такую помощь на платной основе.
        <p><br/></p> 6.9. Поддержка и обучение: Помимо юридической и бухгалтерской помощи, Платформа также
        предлагает Поварам поддержку для улучшения качества их работы. Это включает предоставление
        рекомендаций и инструкций по улучшению блюд и упаковки, а также организацию обучающих
        мероприятий и тренингов для повышения стандартов услуг.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">7. СТОИМОСТЬ ГОТОВОЙ ПРОДУКЦИИ</h3>
      <p>
        <p><br/></p>7.1. Установка цены поваром: Повар самостоятельно устанавливает цену на свои блюда и напитки
        в системе, учитывая затраты на ингредиенты, время на приготовление, упаковку, и другие
        связанные расходы. Повар обязан обеспечить, чтобы установленная цена была справедливой и
        отражала качество предлагаемых блюд.
        <p><br/></p>7.2. Добавление комиссии платформы: Платформа добавляет к установленной Поварами цене
        свою комиссию. Размер комиссии варьируется в зависимости от условий сотрудничества и указан
        в условиях публичной оферты (акцепт), с которыми Повар соглашается при регистрации.
        Окончательная цена, отображаемая на Платформе для пользователя, уже включает как цену блюда,
        так и комиссию Платформы, при этом эти компоненты не выделяются отдельно. Пользователь
        видит только итоговую стоимость блюда, без указания комиссии.
        <p><br/></p> 7.3. Стоимость доставки: Стоимость доставки отображается отдельно при оформлении заказа.
        Пользователь видит стоимость доставки как отдельную позицию, добавляемую к стоимости
        заказанных блюд.
        <p><br/></p> 7.4. Состав стоимости готовой продукции: В стоимость готового блюда входит:
        <p><br/></p> 7.4.1. Цена, установленная Поваром, с учетом всех расходов на ингредиенты, приготовление и
        упаковку.
        <p><br/></p> 7.4.2. Комиссия Платформы, добавляемая к стоимости Повара и включенная в итоговую цену для
        Покупателя.
        Примечание: Таким образом, пользователь видит две основные суммы при оформлении заказа:
        стоимость блюд (включающую цену и комиссию) и стоимость доставки, отображаемую отдельно.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. ПРАВА И ОБЯЗАННОСТИ СТОРОН</h3>
      <strong>Обязанности Повара:</strong>
      <p>
        <p><br/></p> 1. Приготовление блюд:
        <p><br/></p>1.1. Повар обязан готовить блюда строго в соответствии с заявленными рецептами и стандартами
        качества, которые указаны на Платформе.
        <p><br/></p>1.2. Все блюда должны быть приготовлены с соблюдением санитарных норм и требований к
        безопасности пищевых продуктов, действующих в соответствии с законодательством Республики
        Казахстан.
        <p><br/></p> 1.3. Использование только свежих и качественных ингредиентов.
        <p><br/></p> 1.4. Использование испорченных или просроченных продуктов категорически ЗАПРЕЩЕНО!
        <p><br/></p> 2. Соблюдение стандартов упаковки:
        <p><br/></p> 2.1. Повар обязан использовать собственные упаковочные материалы до тех пор, пока Платформа
        не предоставит фирменные упаковки. Расходы на упаковочные материалы несет Повар.
        <p><br/></p> 2.2. Все блюда и напитки, особенно жидкие продукты, должны быть упакованы таким образом,
        чтобы предотвратить порчу или разлив во время транспортировки. Упаковка должна быть
        герметичной и надежной.
        <p><br/></p> 3. Фотографирование заказов:
        <p><br/></p>  3.1. Перед отправкой заказа повар обязан сфотографировать готовое блюдо в упаковке и
        отправить фото в чат Платформы. Это необходимо для контроля качества упаковки и разрешения
        возможных споров.
        <p><br/></p>  4. Соблюдение сроков:
        <p><br/></p> 4.1. Повар обязан строго соблюдать сроки приготовления и передачи заказа курьеру или
        Покупателю. В случае, если Повар не успевает приготовить заказ в указанный срок, он обязан
        немедленно уведомить Платформу через систему (в течение 5 минут после осознания
        невозможности выполнения заказа в срок).
        <p><br/></p> 5. Обновление информации:
        <p><br/></p>  5.1. Повар обязан своевременно обновлять информацию о своих блюдах, включая состав, порции,
        наличие ингредиентов, цены и описание.
        <p><br/></p>  5.2. Если какое-то блюдо временно недоступно, либо закончилось, то повар обязан оперативно
        указать это на платформе, то есть в системе.
        <p><br/></p>  6. Коммуникация с Покупателями:
        <p><br/></p>6.1. Повар не имеет права общаться с Покупателями напрямую, как через систему Платформы,
        так и любыми другими способами.
        <p><br/></p> 6.2. Все взаимодействие с Покупателями осуществляется исключительно Платформой от имени
        Повара, чтобы обеспечить единый стандарт обслуживания.
        <p><br/></p>  6.3. Повар обязан общаться только с администрацией Платформы и получать все запросы и
        комментарии через внутренние системы коммуникации.
        <p><br/></p> 7. Прием оплаты:
        <p><br/></p>  7.1. Повар обязан принимать оплату за свои услуги только через Платформу. Любые сторонние
        способы расчетов или попытки обхода системы оплаты Платформы строго запрещены и могут
        привести к расторжению договора.
        <p><br/></p> 8. Участие в акциях и скидках:
        <p><br/></p>  8.1. Повар обязан своевременно участвовать в акциях и скидках, организованных Платформой,
        если они применимы к его предложениям. Участие в таких мероприятиях позволяет увеличить
        продажи и привлечь новых Покупателей.
        <p><br/></p> 9. Соответствие требованиям платформы:
        <p><br/></p>  9.1. Повар обязан выполнять все правила и требования Платформы, связанные с качеством блюд,
        сроками выполнения заказов и соблюдением стандартов. Это включает соблюдение всех политик
        Платформы, касающихся санитарных норм, качества продуктов и взаимодействия с
        Покупателями.
        <p><br/></p>  10. Конфиденциальность:
        <p><br/></p>  10.1. Повар обязуется не разглашать конфиденциальную информацию, полученную в ходе
        сотрудничества с Платформой, как во время сотрудничества, так и после его завершения. Это
        включает коммерческие данные, информацию о Покупателях, а также любые внутренние
        процессы Платформы.
        <p><br/></p>10.2. Обязательство по сохранению конфиденциальности сохраняется в течение 3 лет после
        расторжения договора.
      </p>
      <strong>Права повара:</strong>
      <p>
        <p><br/></p> 1. Размещение блюд:
        <p><br/></p>1.1. Повар имеет право размещать свои блюда и напитки на Платформе, добавлять новые блюда,
        изменять состав, описание, а также цены на свои предложения. Все изменения должны
        соответствовать стандартам Платформы и не противоречить условиям публичной оферты
        (акцепта).
        <p><br/></p> 2. Участие в акциях:
        <p><br/></p> 2.1. Повар имеет право участвовать в различных акциях и скидках, организуемых Платформой
        для привлечения Покупателей. Участие в акциях способствует увеличению продаж и
        популярности блюд Повара.
        <p><br/></p>  3. Поддержка на старте:
        <p><br/></p>   3.1. В первый месяц Поварам предоставляется бесплатная помощь по вопросам
        налогообложения, бухгалтерии и юридическим аспектам работы. Эта поддержка направлена на
        облегчение адаптации к работе на Платформе.
        <p><br/></p>  3.2. По истечении этого срока помощь предоставляется на платной основе, если Повар нуждается
        в дополнительных консультациях.
        <p><br/></p>  4. Получение оплаты:
        <p><br/></p>  4.1. Повар имеет право на получение своевременной оплаты через Платформу за все
        выполненные заказы. Оплата осуществляется в соответствии с условиями, установленными в
        публичной оферте (акцепте).
        <p><br/></p>  5. Обращение в службу поддержки:
        <p><br/></p>  5.1. Повар имеет право в любое время обратиться в службу поддержки Платформы для решения
        вопросов, связанных с заказами, техническими проблемами или взаимодействием с
        Покупателями через Платформу. Поддержка доступна для оперативного решения всех
        возникающих вопросов.
        <p><br/></p>  6. Обратная связь от платформы:
        <p><br/></p>   6.1. Повар имеет право получать обратную связь от платформы о качестве его услуг, а также
        рекомендации по улучшению работы. Платформа заинтересована в поддержании высокого
        уровня качества и предлагает конструктивную обратную связь для Повара.
        <p><br/></p> 7. Контроль и улучшение качества:
        <p><br/></p>  7.1. Повар имеет право участвовать в обучающих программах и получать рекомендации от
        Платформы для улучшения качества своих блюд и взаимодействия с Покупателями. Такие
        программы могут включать тренинги, мастер-классы и другие мероприятия, направленные на
        повышение профессионального уровня. Участие в таких мероприятиях осуществляется на
        платной основе, и Повар обязуется самостоятельно оплачивать стоимость участия.
        <p><br/></p>  8. Право на удаление или временное приостановление:
        <p><br/></p>  8.1. Повар имеет право временно приостановить свою деятельность на Платформе или удалить
        свои предложения, предварительно уведомив администрацию Платформы. Такое уведомление
        должно быть сделано заблаговременно, чтобы избежать сбоев в обслуживании Покупателей и
        поддерживать прозрачность работы на Платформе.
        <p><br/></p>  9. Право на справедливое разрешение споров:
        <p><br/></p>  9.1. В случае возникновения споров Повар имеет право на справедливое рассмотрение ситуации
        Платформой. Анализ ситуации осуществляется с учетом предоставленных данных, таких как
        фотографии, отзывы Покупателей и другая доступная информация. Платформа обязуется
        обеспечить объективный подход при разрешении споров и учесть интересы всех сторон.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">9. ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ УСЛОВИЙ ДОГОВОРА</h3>
    <strong>Ответственность Повара:</strong>
      <p>
        Повар несет полную ответственность за:
        <p><br/></p> 1. Качество приготовленных блюд — все блюда должны соответствовать заявленным
        стандартам качества и требованиям, указанным на Платформе.
        <p><br/></p> 2. Соблюдение сроков выполнения заказов — Повар обязан готовить и передавать заказы в
        установленные сроки.
        <p><br/></p>3. Точность информации, предоставленной Покупателям — Повар обязан обеспечивать
        актуальность и точность информации о своих блюдах, включая ингредиенты, порции, цены и
        прочие важные детали.
        <p><br/></p>4. Соблюдение санитарных норм и стандартов безопасности — Повар обязан соблюдать все
        санитарные нормы и стандарты безопасности пищевых продуктов в соответствии с
        законодательством Республики Казахстан.
      </p>
      <strong>Ответственность за нарушение:</strong>
      <p>
        • Нарушение этих требований может привести к временной приостановке доступа Повара к
        системе, наложению штрафных санкций или расторжению договора в одностороннем порядке.
        Повторные или серьезные нарушения могут привести к постоянной блокировке профиля Повара
        на Платформе.
      </p>
      <strong>Ответственность Платформы:</strong>
      <p>
        • Платформа не несет ответственности за качество услуг, оказываемых Поварами.
        Однако Платформа обязана принимать меры для защиты своих Покупателей, включая
        временную блокировку профиля Повара, наложение штрафных санкций или расторжение
        договора в случае систематических или серьезных нарушений.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">10. ФИНАНСОВАЯ ОТВЕТСТВЕННОСТЬ</h3>
      <strong>Ответственность Повара:</strong>
      <p>Повар несет финансовую ответственность за:
        1. Убытки, понесенные Покупателями или Платформой в результате его действий или
        бездействия — включая случаи, когда несоблюдение стандартов качества или нарушение условий
        привело к возвратам, жалобам или другим финансовым потерям.
        2. Нарушение условий оплаты через Платформу — Поварам строго запрещено принимать
        оплату за заказы вне системы Платформы. Нарушение этого условия ведет к финансовым
        санкциям.
        3. Задержку выполнения заказов или их отмену по вине Повара — Повар несет ответственность
        за любые убытки, вызванные несвоевременной готовностью заказов или их отменой без
        уважительной причины.:</p>
      <strong>Право удержания выплат Платформой:</strong>
      <p>• Платформа вправе удерживать выплаты Повару в случае нарушения условий договора, если
        эти нарушения привели к финансовым убыткам для Платформы или Покупателя. Удержанные
        суммы могут быть использованы для компенсации убытков или для урегулирования претензий
        Покупателей.</p>
      <h3 className="font-bold text-2xl mt-4 text-center">11. КОМПЕНСАЦИЯ УБЫТКОВ</h3>
      <strong>Право Платформы на компенсацию:</strong>
      <p>
        Платформа имеет право требовать от Повара компенсацию убытков, вызванных его действиями или
        бездействием, включая:
        1. Использование испорченных продуктов — что привело к негативным последствиям для
        Покупателей, включая жалобы или угрозу их здоровью.
        2. Нарушение санитарных норм — любые нарушения, которые повлекли за собой санкции со
        стороны государственных органов или привели к жалобам Покупателей.
        3. Несоблюдение стандартов упаковки и доставки — что привело к порче продуктов, возвратам
        или другим финансовым убыткам для Платформы и Покупателей.
        <strong>Определение размера компенсации:</strong>
        <p>
          • Размер компенсации определяется в зависимости от тяжести нарушений и понесенных
          убытков Платформой или Покупателями. Платформа оставляет за собой право произвести
          расчет суммы компенсации на основании фактических убытков, а также потенциальных
          издержек, связанных с урегулированием претензий Покупателей и восстановлением
          репутации Платформы.
        </p>
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">12. САНКЦИИ ЗА НАРУШЕНИЕ УСЛОВИЙ</h3>
      <strong>Применение санкций за нарушения:</strong>
      <p>В случае систематических нарушений условий договора, Платформа оставляет за собой право
        применять санкции, включая:
        1. Временное ограничение доступа к Платформе — Повар может быть временно лишен
        доступа к системе до устранения выявленных нарушений.
        2. Штрафные санкции — Платформа может наложить штрафы в случае нарушений. Размер
        штрафов определяется в соответствии с условиями договора (акцепта) и зависит от тяжести
        нарушений и их последствий для Покупателей и Платформы. Платформа вправе удерживать эти
        суммы из выплат Повару.
        3. Полное расторжение договора с Поварами — При повторных или серьезных нарушениях,
        которые наносят ущерб репутации Платформы или интересам Покупателей, Платформа имеет
        право расторгнуть договор с Поваром в одностороннем порядке без права на восстановление
        доступа.</p>
      <h3 className="font-bold text-2xl mt-4 text-center">13. КОНФИДЕНЦИАЛЬНОСТЬ И ЗАЩИТА ДАННЫХ, ПОЛУЧАЕМЫХ ПРИ
        ИСПОЛНЕНИИ ДОГОВОРА</h3>
      <strong>Конфиденциальность информации:</strong>
        <p>
          1. Повар обязуется не разглашать и не использовать в личных целях или для передачи третьим
          лицам любую конфиденциальную информацию, полученную в ходе сотрудничества с Платформой,
          включая, но не ограничиваясь:
          2. Коммерческой информацией о деятельности Платформы, ее Покупателях, партнерах и
          бизнес-процессах.
          3. Личными данными Покупателей Платформы (имена, контактные данные, предпочтения в
          еде, история заказов, адрес).
          4. Внутренними документами и материалами Платформы, которые могут включать
          технические, маркетинговые, финансовые и операционные данные.
          5. Повар обязан принимать все необходимые меры для защиты конфиденциальной информации
          от несанкционированного доступа, утечки, кражи или использования.
        </p>
      <strong>Обработка и защита персональных данных:</strong>
      <p>
        1. Платформа обязуется обрабатывать персональные данные Повара в соответствии с
        международными стандартами и применимыми законами о защите данных Республики Казахстан,
        обеспечивая их безопасность и конфиденциальность.
        2. Персональные данные Повара могут использоваться исключительно для целей, связанных с:
        2.1. Выполнением обязательств по договору.
        2.2. Обеспечением корректной работы Платформы и предоставлением необходимых услуг.
        2.3. Улучшением пользовательского опыта, технической поддержки и предоставления
        информации о новых услугах.
        3. Платформа обязуется не передавать персональные данные Повара третьим лицам без его
        согласия, за исключением случаев, предусмотренных законодательством или необходимых для
        выполнения договора (например, передача данных курьерским службам).
      </p>
      <strong>Обязанности Повара по защите данных Покупателей:</strong>
      <p>
        1. Повар обязуется соблюдать все требования Платформы по защите персональных данных
        покупателей. Он не имеет права использовать, хранить или передавать данные Покупателей для
        каких-либо целей, кроме как для выполнения заказов через Платформу.
        2. Все данные Покупателей, доступ к которым имеет Повар, должны быть обработаны с
        максимальной осторожностью и в рамках применимых законов о защите данных. Передача данных
        Покупателей третьим лицам запрещена, за исключением случаев, предусмотренных
        законодательством или соглашением с Платформой.
      </p>
      <strong>Меры по защите данных и предотвращению утечек:</strong>
<p>
  1. В случае обнаружения нарушения конфиденциальности данных (например, утечки
  персональных данных) Платформа и Повар обязаны незамедлительно уведомить друг друга о
  факте нарушения и принять все необходимые меры для минимизации ущерба и устранения
  последствий.
  2. Платформа обязана провести расследование инцидента, определить причины утечки и
  предпринять шаги для предотвращения подобных случаев в будущем.
</p>
      <strong>Ответственность за утечку данных:</strong>
<p>
  1. В случае, если нарушение конфиденциальности данных произошло по вине Повара
  (например, вследствие неосторожного обращения с данными или их умышленного разглашения),
  он несет полную ответственность за все последствия, включая возможные юридические и
  финансовые санкции.
  2. Если утечка данных произошла по вине Платформы, она обязуется принять на себя
  ответственность за последствия и компенсировать любые убытки, понесенные Поварами
</p>
      <strong>Срок действия обязательств по конфиденциальности:</strong>
      <p>1. Все обязательства по конфиденциальности продолжают действовать даже после расторжения
        договора. Повар обязуется не разглашать конфиденциальную информацию в течение трех лет
        после окончания сотрудничества.
        2. Платформа обязана сохранять конфиденциальность персональных данных Повара после
        прекращения сотрудничества, за исключением случаев, когда обработка данных продолжается в
        соответствии с законодательством.</p>
      <strong>Использование имени, логотипа и отзывов Повара:</strong>
      <p>
        1. Платформа имеет право использовать имя, логотип, фотографии блюд и отзывы Повара для
        целей продвижения Платформы и маркетинговых активностей, при условии, что это не наносит
        ущерба деловой репутации Повара.
        2. В случае возражений Повара против использования его данных в рекламных целях, он обязан
        направить соответствующее уведомление Платформе, и Платформа обязуется прекратить
        использование его данных.
      </p>
      <strong>Удаление персональных данных:</strong>
      <p>
        1. Повар имеет право запросить удаление своих персональных данных из системы Платформы
        после расторжения договора. Платформа обязана выполнить этот запрос в течение 30 рабочих
        дней, если хранение данных не требуется по закону.
        2. Платформа обязана удалять все данные, которые не являются необходимыми для исполнения
        юридических обязательств или которые больше не требуются для целей сотрудничества
      </p>
      <strong>Использование куки и аналитических данных:</strong>
    <p>1. Платформа имеет право собирать данные о поведении Поваров на Платформе с помощью
      файлов cookie и других технологий для улучшения пользовательского опыта и повышения
      эффективности работы системы.
      2. Все данные, собранные с использованием файлов cookie и аналогичных технологий,
      обрабатываются в соответствии с политикой конфиденциальности Платформы и законами о
      защите данных.</p>

      <h3 className="font-bold text-2xl mt-4 text-center">14. ИНТЕЛЛЕКТУАЛЬНАЯ СОБСТЕННОСТЬ</h3>
      <stron>Права на контент: </stron>
      <p><br/></p>1. Повар сохраняет авторские права на все материалы, созданные им, включая рецепты,
      фотографии блюд и описания. Однако, повар предоставляет платформе неисключительное право
      на использование этих материалов в целях продвижения услуг платформы, размещения их на сайте
      и в маркетинговых материалах.
      <p><br/></p> 2. Платформа оставляет за собой право редактировать и использовать загруженные Поварами
      фотографии и другие материалы для улучшения визуального представления и соответствия
      стандартам качества Платформы.
      <stron>  Использование логотипов и бренда:</stron>
      <p><br/></p> 1. Платформа имеет право использовать профиль Повара в маркетинговых материалах, а также
      в рекламных кампаниях, направленных на продвижение как самой Платформы, так и услуг Повара.
      <p><br/></p>2. Повар соглашается с тем, что его блюда и фотографии могут быть использованы в рекламных
      видеороликах, баннерах и других материалах, созданных Платформой для привлечения
      Покупателей. Использование этих материалов не должно наносить ущерб деловой репутации
      Повара.
      <stron>  Фотографии поваров:</stron>
      <p><br/></p>1. Повар имеет возможность по своему желанию загрузить фотографию для своего профиля на
      Платформе. Эта фотография может быть использована в материалах Платформы, если Повар дает
      на это согласие.
      <p><br/></p> 2. Загрузка фотографии Повара не является обязательной, однако она может способствовать
      увеличению доверия Покупателей и повышению популярности предложений Повара на
      Платформе.

      <h3 className="font-bold text-2xl mt-4 text-center">15. МАРКЕТИНГОВАЯ ПОДДЕРЖКА</h3>
    <strong>Рекламные материалы:</strong>
      <p>
        • Платформа оставляет за собой право создавать рекламные материалы, в которых могут быть
        использованы фотографии блюд Поваров, их профили, а также логотипы и названия. Эти
        материалы могут быть использованы для продвижения услуг Платформы в социальных сетях, на
        сайте и других рекламных каналах. Платформа обязуется использовать материалы таким образом,
        чтобы не наносить ущерба деловой репутации Повара.
      </p>
      <strong>Продвижение блюд:</strong>
      <p>
        1. Повар имеет право участвовать в специальных рекламных акциях, организуемых
        Платформой, для увеличения продаж своих блюд. Платформа может предлагать акции, скидки или
        специальные предложения для привлечения Покупателей и повышения интереса к предложениям
        Повара.
        2. Платформа может предоставлять рекомендации Поварам по улучшению продаж и
        повышению привлекательности их блюд, используя анализ поведения Покупателей и отзывы. Эти
        рекомендации помогут Поварам адаптировать свои предложения к предпочтениям пользователей
        и улучшить видимость на Платформе.
      </p>
      <strong>Использование имени повара:</strong>
      <p>
        • Платформа имеет право использовать имя, бренд и отзывы Повара в маркетинговых целях
        для продвижения как услуг Платформы, так и непосредственно самого Повара. Если Повар
        возражает против использования его имени в каком-либо контексте, он обязан уведомить
        Платформу об этом, и Платформа обязуется прекратить использование его имени в таком
        контексте.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">16. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ В ПРАВИЛА</h3>
      <strong>Право на изменение правил:</strong>
      <p>
        Платформа оставляет за собой право вносить изменения и
        дополнения в настоящие Правила оказания услуг Пользователем (Поваром).      </p>
      <strong>Уведомление о внесении изменений:</strong>
      <p>
        Изменения и дополнения вступают в силу с момента их
        публикации на Сайте Платформы и/или в приложении. Платформа обязуется уведомить Повара о
        внесённых изменениях посредством уведомления в приложении или через электронную почту не
        менее чем за 5 (пять) календарных дней до вступления изменений в силу.
      </p>
      <strong>Принятие изменений Поварами:</strong>
      <p>
        Если Повар продолжает предоставлять услуги после вступления
        изменений в силу, это считается согласием с новыми условиями. В случае несогласия с внесёнными
        изменениями, Повар имеет право прекратить предоставление услуг и уведомить об этом Платформу
        в установленном порядке.
      </p>
    </div>
  )
}

export default RulesChef
