import { useEffect } from "react";

const PublicOfferCourier = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">ПУБЛИЧНАЯ ОФЕРТА ОКАЗАНИЯ УСЛУГ КУРЬЕРАМИ
        ТОО "DOM FOOD (ДОМ ФУД)"</h1>
      <p className="mt-4">
        Настоящее предложение, Товарищество с ограниченной ответственностью «DOM FOOD (ДОМ
        ФУД)» (далее — Исполнитель), в лице Директора Евлоева Абдуллы Аслановича, действующего на
        основании Устава, БИН 240940031813, адрес места нахождения: Казахстан, город Алматы,
        Алмалинский район, улица Карасай Батыра, дом 193Б, почтовый индекс 050008, электронный адрес:
        https://dom-food.com/ и https://domfood.kz/, электронная почта support@dom-food.com, номер
        телефона: +7 777 9001033, ИИК KZ46722S000040853571, БИК CASPKZKA в АО «Kaspi Bank», КБе
        17, выступает с настоящей публичной офертой.
        Данное предложение (далее — Оферта) представляет собой публичный договор, регулирующий
        оказание услуг по доставке продукции для платформы DOM FOOD (ДОМ ФУД). Условия Оферты
        обязательны для всех курьеров, принявших её, и вступают в силу с момента Акцепта.
        Настоящая Оферта устанавливает существенные условия, необходимые для организации доставки
        продукции от Поставщиков Пользователям (Покупателям), с учётом обязательных норм
        законодательства и требований Платформы. Исполнитель оставляет за собой право вносить
        изменения в данную Оферту и условия оказания услуг в порядке, предусмотренном в настоящем
        договоре
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">1. ПОНЯТИЯ И ТЕРМИНЫ, ИСПОЛЬЗУЕМЫЕ В НАСТОЯЩЕЙ ПУБЛИЧНОЙ
        ОФЕРТЕ</h3>
      <p><br/></p>
      <p>
        <p><br/></p> 1.1. Услуги – услуги, включающие доставку готовой продукции, маркетинговое продвижение и
        иные электронные услуги, предоставляемые на территории Республики Казахстан физическим и
        юридическим лицам, зарегистрированным на Платформе.
        <p><br/></p> 1.2. Договор-Оферта – публичное предложение Исполнителя, содержащее все условия для
        оказания Услуг, которое становится обязательным для обеих Сторон с момента его Акцепта
        Пользователем-Курьером.
        <p><br/></p> 1.3. Акцепт – согласие Пользователя на условия Договора-Оферты, подтвержденное
        регистрацией на Платформе или принятием заказа на доставку.
        <p><br/></p> 1.4. Информационная технология – совокупность методов, производственных процессов и
        программно-технических средств, объединённых в технологический комплекс, обеспечивающий
        сбор, создание, хранение, накопление, обработку, поиск, вывод, копирование, передачу и
        распространение информации.
        <p><br/></p> 1.5. Сайт – интернет-ресурс https://dom-food.com/ и https://domfood.kz/ Исполнителя,
        содержащий информацию в текстовом, графическом, аудиовизуальном или ином виде,
        размещённую на аппаратно-программном комплексе, имеющем уникальный сетевой адрес и (или)
        доменное имя, функционирующем в Интернете.
        <p><br/></p> 1.6. Посетитель/покупатель – лицо, посетившее Сайт для покупки готовой продукции
        Пользователя, без цели регистрации или размещения Заказа.
        <p><br/></p>  1.7. Пользователь (Курьер) – лицо, зарегистрированное на Платформе, принимающее заказы
        на доставку продукции и оказывающее курьерские услуги в соответствии с условиями настоящего
        Договора-Оферты.
        <p><br/></p>  1.8. Заказ – задание на доставку продукции, оформленное через Платформу, содержащее
        условия и сроки доставки.
        <p><br/></p> 1.9. Готовая продукция – продукция, производимая Пользователем (Поваром), прошедшая
        предварительное согласование с Исполнителем по качеству и предназначенная для доставки
        Покупателю.
        <p><br/></p>  1.10. Повар – лицо, осуществляющее изготовление готовой продукции, у которого ПользовательКурьер забирает заказ для дальнейшей доставки Покупателю.
        <p><br/></p>  1.11. Стороны – Исполнитель и Пользователь, заключившие Договор-Оферту.
        <p><br/></p>  1.12. Персональные данные – любая информация о Пользователе, обрабатываемая в рамках
        выполнения условий Договора-Оферты.
        <p><br/></p> 1.13. Сбор и Обработка персональных данных – получение, хранение, изменение,
        использование, дополнение, распространение, обезличивание, блокирование и уничтожение
        персональных данных в соответствии с законодательством.
        <p><br/></p>  1.14. Третьи лица – лица, связанные как в настоящее время, так и в будущем с Исполнителем в
        рамках Сбора, Обработки и защиты Персональных данных Пользователя.
        <p><br/></p> 1.15. Правила отбора и оказания услуг пользователем – приложение к настоящему договору
        Публичной оферты, содержащее все правила оказания услуг, требования, права и обязанности
        Пользователя. Эти правила являются неотъемлемой частью Договора (далее по тексту – Правила).
        <p><br/></p>  1.16. Маркетинговые услуги – действия, направленные на продвижение продукции на
        Платформе (акции, скидки, реклама).
        <p><br/></p>  1.17. Комиссия – процент от стоимости заказа, удерживаемый Исполнителем за предоставление
        Платформы и сопутствующих услуг.
        <p><br/></p>  1.18. Тариф – фиксированная стоимость или система расчета стоимости доставки, установленная
        Исполнителем для Курьера, которая может зависеть от расстояния, времени выполнения заказа и
        других факторов.
        <p><br/></p>  1.19. Акция (Скидка) – специальное предложение, доступное Пользователям в определённый
        период времени, включающее снижение стоимости готовой продукции или услуги для
        привлечения Покупателей и увеличения продаж.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. ПРЕДМЕТ ДОГОВОРА - ОФЕРТЫ</h3>
      <p><br/></p>
      <p>
        <p><br/></p>  2.1. Обязательства Сторон: Исполнитель обязуется по Заказу предоставить Услуги, а
        Пользователь обязуется их выполнить и соблюдать все установленные условия. Услуги включают
        доставку готовой продукции от Поваров покупателям в соответствии с условиями Платформы
        DOM FOOD (ДОМ ФУД). Пользователь принимает все условия Договора-Оферты в полном
        объёме посредством Акцепта.
        <p><br/></p>   2.2. Порядок совершения Акцепта – Акцепт считается совершённым Пользователем с момента
        выполнения любого из следующих действий:
        <p><br/></p>   2.2.1. Проставление галочки в соответствующем поле на странице Сайта или в приложении,
        подтверждающее согласие с Договором-Офертой и Правилами.
        <p><br/></p>   2.2.2. Регистрация на Сайте, что автоматически подтверждает согласие с условиями
        Договора-Оферты.
        <p><br/></p>  2.2.3. Принятие Заказа на доставку готовой продукции через приложение Платформы.
        <p><br/></p>  2.3. Правовые последствия Акцепта
        С момента совершения Акцепта считаются выполненными следующие условия:
        <p><br/></p>  2.3.1. Пользователь ознакомлен с условиями Договора и Правилами, согласен с ними и
        принимает их в полном объёме.
        <p><br/></p> 2.3.2. Акцепт Исполнителем получен и считается принятым.
        <p><br/></p> 2.3.3. Договор-Оферта считается заключённым и не требует двустороннего подписания,
        имея юридическую силу в электронном виде.
        <p><br/></p> 2.3.4. Письменная форма сделки соблюдена, и Договор имеет юридическую силу в
        соответствии с законодательством Республики Казахстан
        <p><br/></p>  2.4. Право на отзыв предложения: Исполнитель имеет право отозвать своё предложение о
        заключении Договора-Оферты в любое время, при этом это не освобождает Исполнителя от
        обязательств по уже принятым Заказам. В случае отзыва Договора-Оферты Исполнитель может
        разместить уведомление на Сайте с указанием точного времени приостановки действия Договора,
        не менее чем за 12 часов до отзыва, если иное не указано в условиях Договора-Оферты.
        <p><br/></p>  2.5. Временная недоступность Услуг: Исполнитель оставляет за собой право приостанавливать
        оказание Услуг или временно закрывать доступ к Сайту в случае проведения профилактических
        или иных технических работ. Исполнитель может уведомлять Пользователя о таких
        приостановках через приложение или Сайт, если это необходимо.
        <p><br/></p>   2.6. Прекращение действия Договора по инициативе Исполнителя: Исполнитель имеет
        право прекратить оказание Услуг и действие Договора-Оферты в одностороннем порядке, если
        Пользователь систематически нарушает правила Платформы или обязательства по Договору. В
        таком случае Исполнитель уведомляет Пользователя об этом решении через систему уведомлений
        в приложении или по электронной почте.
        <p><br/></p>  2.7. Изменения в Договоре-Оферте: Исполнитель оставляет за собой право вносить изменения
        в условия Договора-Оферты без обязательного уведомления Пользователя, если это не
        противоречит законодательству Республики Казахстан и условиям Договора. Любые изменения
        вступают в силу с момента их публикации на Сайте Исполнителя, и Пользователь считается
        согласным с ними, если продолжает пользоваться Услугами после вступления изменений в силу.
        <p><br/></p>  2.8. Ответственность Пользователя за качество и своевременность доставки: Пользователь
        обязуется доставлять готовую продукцию в соответствии с указанными сроками и стандартами.
        В случае нарушений Исполнитель вправе применить штрафные санкции или приостановить
        доступ к Платформе.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. УСЛОВИЯ ОКАЗАНИЯ УСЛУГ</h3>
      <p><br/></p>
      <p>3.1. Правила пользования Сайтом изложены в разделе «Пользовательское соглашение и
        условия работы» и являются обязательными для исполнения всеми Пользователями. Правила
        использования сайта и взаимодействия с Платформой доступны по ссылке: https://domfood.com/polzovatelskoe_soglashenie/.</p>
      <p>Таблица оказываемых услуг и их характеристик</p>
      <div id="table-scroll">

      <table  className="services-table mt-4" border="1" cellPadding="10">
        <thead>
        <tr>
          <th>№</th>
          <th>Наименование Услуги (виды)</th>
          <th>Особенности оказываемой Услуги, сведения об основных свойствах Услуги</th>
          <th>Срок оказания Услуги и порядок его исчисления</th>
          <th>Наличие или отсутствие гарантийного срока</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>Регистрация на сайте в личном кабинете</td>
          <td>Пользователь регистрирует свой профиль для получения доступа к Платформе и начала работы</td>
          <td>Регистрация осуществляется автоматически после подтверждения данных</td>
          <td>Отсутствует</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Прием заказов через Исполнителя</td>
          <td>Пользователь-Курьер принимает заказы, размещенные через Платформу, и обязуется доставить их в срок</td>
          <td>Срок выполнения зависит от заказа, но не превышает указанный при его размещении</td>
          <td>Отсутствует</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Доставка готовой продукции</td>
          <td>Курьер обязуется доставить продукцию от повара к Покупателю, следуя всем условиям транспортировки</td>
          <td>Время доставки указано при размещении заказа</td>
          <td>Отсутствует</td>
        </tr>
        <tr>
          <td>4</td>
          <td>Отчет о проделанной работе</td>
          <td>Курьер обязуется предоставлять отчет о доставке продукции, подтверждая выполнение заказа</td>
          <td>Подтверждение осуществляется после выполнения доставки</td>
          <td>Отсутствует</td>
        </tr>
        </tbody>
      </table>
      </div>
      <p>3.2. Регулярность оказания услуг: Исполнитель оказывает Услуги по Заказам Пользователя на
        регулярной основе, до момента завершения договорных отношений между Сторонами. Заказы
        оформляются в системе на постоянной основе..</p>
      <p>
        <p><br/></p> 3.3. Неограниченное количество Заказов: В рамках исполнения Договора-Оферты
        Пользователем может быть оформлено неограниченное количество Заказов для Покупателей.
        Выполнение каждого отдельного Заказа не прекращает действия Договора-Оферты.
        <p><br/></p>3.4. Оформление Заказов: Пользователь оформляет Заказы посредством совершения действий,
        указанных в Правилах отбора и оказания услуг Пользователем (приложение к настоящему
        Договору-Оферте).
        <p><br/></p> 3.5. Привлечение третьих лиц: Пользователь, принимая Акцепт Договора-Оферты, выражает
        согласие на привлечение Исполнителем третьих лиц (соисполнителей) к выполнению услуг по
        доставке или другим аспектам Договора. В частности, Услуги оказываются ПользователямиПоварами, которые изготавливают готовую продукцию для доставки Покупателю. Исполнитель
        также может привлекать иных исполнителей по отдельным участкам или комплексам Услуг.
        <p><br/></p>  3.6. Привлечение третьих лиц для выполнения Услуг: Пользователь, принимая условия
        Договора-Оферты, соглашается на возможность привлечения третьих лиц к выполнению
        отдельных задач.
        <p><br/></p> • Соисполнители: Исполнитель вправе привлекать третьих лиц для оказания Услуг, в том
        числе для выполнения доставки и контроля качества. Например, Услуги оказываются поварами,
        которые готовят продукцию, а курьеры занимаются её доставкой.
        <p><br/></p>• Взаимодействие Пользователей: Курьер обязан взаимодействовать с Поваром при
        получении заказа и обеспечивать качественную доставку покупателю. Курьеры также обязаны
        следовать инструкциям по доставке, указанным на Платформе.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. ЦЕНА, СРОК И ПОРЯДОК ОПЛАТЫ УСЛУГ</h3>
      <div id="table-scroll">

      <table className="payment-table my-4">
        <thead>
        <tr>
          <th>№</th>
          <th>Наименование Услуги (вид)</th>
          <th>Единица измерения (объем, количество и (или) комплектность Услуги)</th>
          <th>Стоимость Услуги</th>
          <th>Комментарии</th>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>1</td>
          <td>Доставка готовой продукции до Покупателя</td>
          <td>шт./комплект</td>
          <td>Варируется в зависимости от расстояния и условий доставки</td>
          <td>Курьер забирает продукцию у повара и доставляет Покупателю</td>
        </tr>
        <tr>
          <td>2</td>
          <td>Регистрация и приём заказов</td>
          <td>действие</td>
          <td>Включено в общую стоимость</td>
          <td>Доступ предоставляется после регистрации на Платформе</td>
        </tr>
        <tr>
          <td>3</td>
          <td>Бонусы и премии за выполнение</td>
          <td>бонус / заказ</td>
          <td>Зависит от условий кампаний</td>
          <td>Дополнительные премии за выполнение заказов в пиковые периоды</td>
        </tr>
        </tbody>
      </table>
      </div>
      <p>
        <p><br/></p>  4.1. Оказание услуг и расчёты:
        • Исполнитель оказывает услуги доставки, включая приём и транспортировку заказов от
        Пользователей (Поваров) до Покупателей. Все курьеры получают оплату за каждую
        выполненную доставку, исходя из стоимости заказа, указанной в приложении на момент
        принятия заказа.
        • Курьер получает ту стоимость за доставку, которая отображается в системе на момент
        принятия заказа. Конкретные тарифы могут варьироваться в зависимости от ряда факторов, таких
        как расстояние, загруженность или срочность выполнения заказа.
        <p><br/></p>4.2. Принципы ценообразования и комиссии:
        • Курьеры не видят комиссии или тарифной наценки, установленных Исполнителем. Они
        получают фиксированную сумму за каждую доставку, отображённую в заказе.
        • Исполнитель оставляет за собой право изменять условия ценообразования и комиссии, что
        не будет отображаться курьерам. Вся информация о комиссиях используется исключительно для
        внутреннего учёта.
        <p><br/></p>  4.3. Порядок и сроки выплат:
        • Все выплаты курьерам производятся безналичным путём в течение 1 (одного) календарного
        дня с момента выполнения и подтверждения доставки в системе.
        • Оплата услуг производится следующими способами:
        o Платёжными картами (VISA или MasterCard).
        o Переводы через платёжные терминалы и системы (например, Kaspi, Halyk Bank, QIWI).
        o Перечисление на электронные кошельки или счета.
        <p><br/></p>  4.4. Задержки в выплатах: В случае технических сбоев, блокировки платёжных систем или
        других форс-мажорных обстоятельств, выплаты могут быть временно приостановлены.
        Исполнитель обязуется уведомить курьера о задержках и предпринять все необходимые меры для
        их устранения в кратчайшие сроки.
        <p><br/></p>  4.5. Бонусы и премии: Исполнитель вправе предоставлять бонусы и премии за выполнение
        заказов в периоды высокой нагрузки, праздники или при других обстоятельствах, определённых
        платформой. Информация о бонусах будет отображаться в личном кабинете курьера на момент
        принятия заказа.
        <p><br/></p> 4.6. Дополнительные комиссии: В случае, если оплата производится через сторонние
        платёжные системы или банки, могут взиматься дополнительные комиссии, которые
        оплачиваются Пользователем или Покупателем.
        <p><br/></p>4.7. Налогообложение:
        • Курьер самостоятельно несёт ответственность за уплату налогов в соответствии со своим
        статусом (самозанятый или ИП). Если курьер пожелает, чтобы Исполнитель удерживал и
        уплачивал налоги, необходимо заключить отдельное соглашение, в котором будут оговорены все
        детали.
        • Исполнитель не несёт ответственности за налоговые обязательства курьеров и оставляет за
        собой право запросить подтверждение уплаты налогов.
        <p><br/></p> 4.8. Изменение условий выплат и комиссий: Исполнитель оставляет за собой право вносить
        изменения в условия выплат и внутренние комиссии, уведомляя курьера через личный кабинет
        или электронную почту. Курьер соглашается с такими изменениями, продолжая пользоваться
        услугами после их вступления в силу.
        <p><br/></p>  4.9. Политика отмены и отказа от заказов:
        • Курьер имеет право отказаться от выполнения заказа в случае непредвиденных
        обстоятельств, таких как поломка транспортного средства, срочные личные обстоятельства или
        другие форс-мажорные ситуации. В этом случае он обязан уведомить Исполнителя через
        приложение незамедлительно.
        • В случае, если курьер систематически отказывается от заказов без уважительных причин,
        Исполнитель оставляет за собой право ограничить доступ курьера к платформе и приостановить
        выплаты до урегулирования ситуации.
        <p><br/></p> 4.10. Взаимодействие с Пользователями и поварами: Курьер обязуется соблюдать вежливость
        и корректность при взаимодействии с Пользователями и Поварами. В случае поступления жалоб
        на поведение курьера Исполнитель вправе применить штрафные санкции или приостановить его
        доступ к Платформе.
        <p><br/></p>  4.11. Право на изменение маршрутов доставки: Исполнитель оставляет за собой право
        корректировать маршруты и условия доставки в зависимости от загруженности курьеров и
        логистических требований. Курьер обязуется следовать всем инструкциям Исполнителя,
        указанным в приложении, для оптимизации логистики.
        <p><br/></p>  4.12. Сохранность продукции при доставке: Курьер несёт полную ответственность за
        сохранность готовой продукции при транспортировке. В случае повреждения или порчи
        продукции курьер обязан немедленно уведомить Исполнителя и предоставить доказательства
        (например, фотографии). Исполнитель может наложить санкции за несоблюдение правил
        транспортировки.
        <p><br/></p>  4.13. Обработка данных и конфиденциальность:
        • Курьер обязуется соблюдать требования конфиденциальности, в том числе не разглашать
        личные данные Покупателей, адреса доставки и иные сведения, полученные в рамках
        выполнения заказов.
        • В случае нарушения условий конфиденциальности курьер несёт ответственность в
        соответствии с Договором-Офертой и законодательством Республики Казахстан.
        <p><br/></p> 4.14. Форс-мажор: В случае форс-мажорных обстоятельств (например, блокировка банковских
        счетов, сбои в платёжных системах) возможны задержки в выплатах. Исполнитель обязуется
        уведомить Пользователей о задержках и предоставить информацию о предполагаемых сроках
        устранения проблемы. В таких случаях претензии не могут быть предъявлены Исполнителю до
        разрешения ситуации.
      </p>

      <h3 className="font-bold text-2xl mt-4 text-center">5. ПРИЕМКА-ПЕРЕДАЧА ОКАЗАННЫХ УСЛУГ</h3>
      <p>
        <p><br/></p>5.1. Подтверждение выполненных доставок: Каждая завершённая доставка должна быть
        подтверждена курьером через приложение Платформы. После подтверждения заказ считается
        завершенным, и эта информация фиксируется в системе Исполнителя и отображается в личном
        кабинете курьера.
        <p><br/></p>5.2. Ежемесячные отчеты: Исполнитель предоставляет курьерам отчёт о выполненных
        доставках не позднее 10-го числа каждого месяца. В отчёте указываются все заказы за
        предыдущий месяц, включая информацию о маршрутах, времени выполнения, итоговых суммах
        и удержанных комиссиях.
        <p><br/></p> 5.3. Акт выполненных работ (по запросу):
        • В случае необходимости Исполнитель предоставляет Акт выполненных работ,
        подтверждающий оказание услуг за определённый период.
        • Акт может быть оформлен в электронной или бумажной форме и подписан обеими
        сторонами.
        • Подписанный экземпляр Акта остаётся у каждой из сторон.
        <p><br/></p> 5.4. Согласование и подписание Акта: Курьер обязан подписать Акт в течение 5 (пяти)
        календарных дней с момента его получения или направить Исполнителю обоснованный отказ.
        • Если курьер не предоставляет подписанный Акт или обоснованный отказ в установленный
        срок, услуги считаются оказанными и принятыми без претензий.
        • В случае отказа курьера от подписания Акта Исполнитель обязан устранить замечания в
        согласованные сроки.
        <p><br/></p>  5.5. Корректировка спорных заказов: При возникновении споров по конкретным доставкам
        Исполнитель может инициировать пересмотр отчётов. Указанные в спорных заказах недочёты
        устраняются с последующим пересмотром соответствующих сумм в отчёте.
        <p><br/></p> 5.6. Документы для налоговой и финансовой отчётности: По запросу курьера Исполнитель
        может предоставить документы, необходимые для ведения бухгалтерской и налоговой
        отчётности, такие как сводные отчёты и подтверждения выполненных доставок.
        <p><br/></p> 5.7. Устранение нарушений и повторная передача услуг:
        • Если выявлены недочёты в процессе доставки, Исполнитель вправе инициировать
        повторное выполнение услуг.
        • Курьер обязан устранить нарушения в течение согласованного срока, в противном случае
        доступ к Платформе может быть приостановлен.
        <p><br/></p> 5.8. Оценка качества и обратная связь: Курьеры получают регулярные оценки от
        покупателей. Высокие рейтинги могут привести к бонусам, а низкие оценки — к приостановке
        доступа и обязательной корректировке работы.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. ОБСТОЯТЕЛЬСТВА НЕПРЕОДОЛИМОЙ СИЛЫ (ФОРС-МАЖОР)</h3>
      <p>
        <p><br/></p>6.1. Освобождение от ответственности: Сторона освобождается от ответственности за
        частичное или полное неисполнение обязательств, если оно вызвано обстоятельствами
        непреодолимой силы, возникшими после заключения Договора-Оферты и носящими
        чрезвычайный характер, который невозможно было предвидеть или предотвратить.
        <p><br/></p>6.2. Примеры обстоятельств непреодолимой силы: К таким обстоятельствам относятся:
        • Природные катаклизмы: наводнения, пожары, землетрясения, ураганы, и другие
        природные явления.
        • Социальные и политические факторы: эпидемии, пандемии, забастовки, военные
        конфликты или вооруженные столкновения.
        • Действия государственных органов: введение карантина, изменения в законодательстве,
        запреты, блокировки счетов или любые решения властей, делающие исполнение обязательств
        невозможным.
        <p><br/></p>6.3. Обязательство уведомления: Сторона, столкнувшаяся с обстоятельствами непреодолимой
        силы, обязана уведомить другую Сторону в письменной форме в течение 5 (пяти) календарных
        дней с момента их наступления. Уведомление должно содержать:
        • Описание характера возникших обстоятельств.
        • Оценку их влияния на выполнение обязательств.
        • Ожидаемые сроки устранения или окончания обстоятельств.
        6.4. Подтверждение обстоятельств: Сторона, ссылающаяся на обстоятельства непреодолимой
        силы, обязана предоставить официальные документы, подтверждающие наличие этих
        обстоятельств. Обстоятельства, имеющие общеизвестный характер, могут быть освобождены от
        документального подтверждения, однако уведомление о них должно быть направлено в течение
        24 (двадцати четырёх) часов.
        <p><br/></p> 6.5. Лишение права на освобождение: Если Сторона не направит документы или уведомление
        в установленный срок, она теряет право ссылаться на форс-мажор как основание для
        освобождения от ответственности за неисполнение обязательств.
        <p><br/></p>6.6. Продление сроков: При наступлении обстоятельств непреодолимой силы срок выполнения
        обязательств продлевается на время их действия и устранения последствий. Если они
        продолжаются более 30 (тридцати) календарных дней, Стороны обязаны провести переговоры
        для поиска альтернативных решений. В случае, если соглашение не достигнуто, договор может
        быть расторгнут с уведомлением другой Стороны.
        <p><br/></p>6.7. Расторжение договора: При невозможности дальнейшего исполнения обязательств
        Стороны имеют право расторгнуть договор. В таком случае Стороны обязуются урегулировать
        все финансовые обязательства и расчёты в течение 10 (десяти) рабочих дней после расторжения.
        <p><br/></p> 6.8. Финансовые обязательства: Финансовые обязательства, возникшие до наступления форсмажорных обстоятельств, подлежат исполнению в полном объёме. В случае расторжения
        договора выплаты по уже завершённым заказам остаются в силе.
        <p><br/></p> 6.9. Приоритет выполнения: После устранения форс-мажорных обстоятельств Стороны
        обязаны возобновить выполнение обязательств в кратчайшие сроки, исходя из приоритетности
        ранее неисполненных обязательств.
        <p><br/></p>  6.10. Взаимные уведомления: Стороны обязаны уведомлять друг друга о снятии ограничений,
        вызванных форс-мажором, и о готовности к возобновлению исполнения обязательств.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">7. РАЗРЕШЕНИЕ СПОРОВ</h3>
    <p>
      <p><br/></p> 7.1. Досудебное урегулирование споров: Все споры и разногласия, возникающие между
      Сторонами в процессе исполнения Договора-Оферты, подлежат обязательному досудебному
      урегулированию. Стороны обязуются предпринять разумные и добросовестные усилия для
      урегулирования споров путём переговоров и предъявления претензий до обращения в судебные
      органы.
      <p><br/></p> 7.2. Стороны определяют следующий обязательный порядок досудебного урегулирования
      спора:
      <p><br/></p> 7.2.1. Подача претензии: Претензия подаётся в письменной форме, подписывается
      уполномоченным представителем и направляется другой Стороне с подтверждением получения.
      <p><br/></p> 7.2.2. Содержание претензии:
      • Конкретные требования к другой Стороне с указанием точной суммы претензии и её
      обоснованного расчёта.
      • Описание обстоятельств, на которых основываются требования.
      • Список приложений (документы, подтверждающие нарушение).
      • Предложения по урегулированию спора или дальнейшим действиям.
      <p><br/></p>  7.2.3. Сроки рассмотрения претензии:
      • Претензия рассматривается в течение 10 (десяти) календарных дней с момента получения.
      • В случае необходимости Сторона, получившая претензию, может запросить у инициатора
      дополнительные документы в течение 2 (двух) календарных дней. Запрошенные документы
      предоставляются в течение 3 (трёх) календарных дней.
      • Если документы не предоставлены в указанный срок, претензия рассматривается на
      основании имеющихся данных.
      <p><br/></p> 7.2.4. Ответ на претензию: Ответ предоставляется в письменной форме с
      мотивированным решением и, при необходимости, предложением по урегулированию спора.
      <p><br/></p>7.2.5. Привлечение третьей стороны: При необходимости стороны могут договориться о
      привлечении независимого посредника для разрешения спора на стадии досудебного
      урегулирования.
      <p><br/></p> 7.3. Судебное разбирательство:
      • В случае невозможности урегулирования спора в досудебном порядке каждая из Сторон
      вправе обратиться в суд.
      • Судебные разбирательства проводятся в соответствии с законодательством Республики
      Казахстан по месту нахождения Исполнителя, если иное не предусмотрено Договором.
      <p><br/></p> 7.4. Использование арбитража (по соглашению): Стороны могут по взаимному согласию
      обратиться к арбитражному суду для разрешения спора, если это предусмотрено дополнительным
      соглашением.
      <p><br/></p>  7.5. Преимущественное право на досудебное урегулирование: Обращение в суд возможно
      только при условии, что досудебный порядок был соблюдён и истёк срок для ответа на претензию.
    </p>
      <h3 className="font-bold text-2xl mt-4 text-center">8. ПОРЯДОК ИЗМЕНЕНИЯ, РАСТОРЖЕНИЯ ДОГОВОРА - ОФЕРТЫ</h3>
      <p>
        <p><br/></p> 8.1. Стороны пришли к соглашению, что уведомлениями и (или) запросами Стороны по
        Договору – Оферте могут обмениваться в любой из ниже перечисленных форм:
        <p><br/></p> 8.1.1. Бумажные носители:
        • Регистрируемое почтовое отправление (заказное письмо) на адрес проживания или
        регистрации Стороны.
        • Нарочно под расписку получателя на экземпляре уведомления отправителя.
        <p><br/></p> 8.1.2. Электронные уведомления:
        • Со стороны Исполнителя: Отправка уведомлений с электронного адреса, содержащего
        доменное имя Платформы (например, support@dom-food.com).
        • Со стороны Пользователя: Отправка сообщений с адреса, указанного при регистрации на
        Платформе.
        • Письма, отправленные с других адресов, кроме зарегистрированных, могут быть признаны
        недействительными для целей договора.
        <p><br/></p> 8.1.3. Уведомление и (или) запрос, передаваемые в форме сообщения по электронной
        почте, должны содержать:
        • Тема (subject) письма должна отражать суть запроса или уведомления.
        • В тексте сообщения - информация, необходимая для рассмотрения запроса или информация
        достаточная для понимания представленного уведомления.
        • Стороны отдельно пришли к заключению, что письма, отправленные с иных электронных
        адресов, в ином от вышеописанных форм виде могут не рассматриваться.
        <p><br/></p>  8.2. Изменение условий Договора-Оферты: Исполнитель имеет право вносить изменения или
        дополнения в условия Договора-Оферты:
        <p><br/></p>  8.2.1. Уведомление об изменениях:
        • Информация размещается на Сайте Платформы и/или отправляется Пользователю на
        электронную почту не менее чем за 10 (десять) календарных дней до вступления изменений
        в силу.
        • В случае несогласия с изменениями Пользователь обязан уведомить Исполнителя в
        письменной форме до вступления изменений в силу. Отсутствие уведомления считается
        согласием Пользователя.
        <p><br/></p>   8.2.2. Доступ к Договору-Оферте: Действующая редакция Договора-Оферты всегда
        доступна для ознакомления на Сайте.
        <p><br/></p>  8.3. Расторжение Договора-Оферты: Договор-Оферта может быть расторгнут по соглашению
        Сторон или в одностороннем порядке, если это предусмотрено условиями Договора или
        законодательством.
        <p><br/></p>  8.4. Предложение о расторжении:
        • Направляется другой Стороне за 5 (пять) рабочих дней до предполагаемой даты
        расторжения.
        • Расторжение происходит через обмен предложением и акцептом в той же форме, что и сам
        Договор-Оферта.
        <p><br/></p> 8.5. Односторонний отказ от исполнения: Письменное уведомление направляется за 7 (семь)
        календарных дней до предполагаемой даты расторжения.
        <p><br/></p>  8.6. Иные основания для расторжения:
        <p><br/></p> 8.6.1. Инициатива Пользователя:
        • Систематическое невыполнение обязательств Исполнителем.
        • Просрочка исполнения на срок более 5 (пяти) дней.
        • Нарушение иных условий Договора.
        <p><br/></p> 8.6.2. Инициатива Исполнителя:
        • Нарушение Пользователем стандартов доставки и санитарных норм, или жалобы
        Покупателей на качество доставки.
        • Просрочка выполнения обязательств на срок более 7 (семи) дней.
        • Грубое обращение с Покупателем или пользователем Платформы.
        • Регулярные опоздания на забор или доставку заказов.
        <p><br/></p> 8.7. После расторжения договора:
        • Доступ Пользователя к Платформе блокируется, а все его предложения удаляются.
        • Пользователь не может повторно зарегистрироваться на Платформе без согласия
        Исполнителя.
        <p><br/></p> 8.8. Выполнение текущих заказов: В случае расторжения договора Пользователь обязуется
        завершить все текущие заказы и финансовые расчеты, назначенные ему до момента расторжения,
        если Исполнитель не примет иного решения.
        <p><br/></p>  8.9. Урегулирование задолженностей: Обе стороны обязаны урегулировать все
        задолженности и обязательства друг перед другом в течение 14 (четырнадцати) рабочих дней
        после расторжения договора.
        <p><br/></p>  8.10. Приостановка учётной записи: Исполнитель вправе временно заморозить учётную запись
        Пользователя для устранения выявленных нарушений. Приостановка учётной записи
        сопровождается уведомлением с инструкциями для устранения нарушений и, при необходимости,
        сроками их выполнения.
        <p><br/></p>  8.11. Автоматическое расторжение: Если Пользователь не устраняет выявленные нарушения в
        установленный срок, договор будет расторгнут автоматически.
        <p><br/></p>  8.12. Возобновление сотрудничества: Пользователь имеет право обратиться к Исполнителю для
        возобновления сотрудничества, если он устранил все причины, приведшие к расторжению
        договора. Решение о возобновлении сотрудничества принимается Исполнителем на основании
        результатов повторного рассмотрения.
        <p><br/></p>  8.13. Возврат оборудования: При расторжении договора Пользователь использующий
        оборудование, предоставленное Исполнителем (например, термосумки), обязан вернуть, в
        течение 1 (одного) календарного дня с момента расторжения договора. Возвращённое
        оборудование должно быть в исправном состоянии и проверено Исполнителем.
        <p><br/></p>  8.14. Уведомление о расторжении: Курьер должен уведомить Платформу через приложение или
        по электронной почте за 5 (пять) рабочих дней до предполагаемой даты расторжения.
        <p><br/></p>  8.15. Изменение юридического адреса Исполнителя:
        • В случае изменения юридического адреса Исполнителя, информация об этом размещается
        на Сайте и/или в приложении. Пользователи обязаны самостоятельно отслеживать изменения,
        опубликованные на платформе.
        • Изменение юридического адреса не является основанием для расторжения Договора или
        изменения его условий. Договор продолжает действовать на тех же условиях, что и до изменения
        адреса.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">9. ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ</h3>
<p>
  <p><br/></p> 9.1. Мирное урегулирование споров: Все споры и разногласия, возникающие между
  Пользователем и Исполнителем в процессе исполнения условий Договора-Оферты, подлежат
  первоочередному урегулированию путем переговоров и взаимных консультаций. Стороны
  обязуются предпринять все разумные меры для достижения соглашения.
  <p><br/></p> 9.2. Порядок подачи претензии: Прежде чем обращаться в суд, Сторона обязана направить
  другой стороне письменную претензию, в которой указаны:
  • Суть спора и конкретные требования.
  • Доказательства и обоснования требований.
  • Предложения по мирному урегулированию конфликта.
  Сторона, получившая претензию, обязана предоставить ответ в течение 10 (десяти) рабочих
  дней. Если в указанный срок стороны не достигнут соглашения, спор может быть передан на
  рассмотрение в суд.
  <p><br/></p> 9.3. Разрешение споров в судебном порядке: Если спор не удается урегулировать в досудебном
  порядке, стороны имеют право обратиться в гражданский суд. Все споры и разногласия подлежат
  рассмотрению в суде по месту нахождения Исполнителя, если иное не предусмотрено условиями
  Договора-Оферты или законодательством Республики Казахстан.
  <p><br/></p> 9.4. Применимое законодательство: Настоящий Договор-Оферта и все правоотношения,
  возникающие в рамках его исполнения, регулируются и подлежат толкованию в соответствии с
  законодательством Республики Казахстан.
  <p><br/></p>9.5. Вступление в силу и срок действия:
  • Договор-Оферта вступает в силу в момент присоединения Пользователя к условиям
  оферты, что подтверждается совершением Акцепта.
  • Договор действует до момента полного выполнения Сторонами всех обязательств.
  <p><br/></p> 9.6. Изменение и дополнение условий: Исполнитель оставляет за собой право вносить
  изменения в условия Договора-Оферты. Все изменения вступают в силу с момента их публикации
  на Сайте, если не указано иное. Продолжение использования Платформы после вступления
  изменений в силу считается согласием с новыми условиями.
  <p><br/></p> 9.7. Отказ от ответственности: Исполнитель не несет ответственности за убытки, вызванные
  временной недоступностью Сайта или нарушениями, вызванными форс-мажорными
  обстоятельствами, сбоями в работе платформы, действиями третьих лиц или иных факторов, не
  зависящих от Исполнителя.
</p>
    </div>
  )
}

export default PublicOfferCourier
