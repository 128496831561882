import './App.css';
import Header from './components/Header';
import Footer from "./components/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Toaster } from 'react-hot-toast'
// import TermsAndConditions from "./pages/TermsAndConditions";
import Home from "./pages/Home";
// import TermServices from "./pages/TermServices";
import RemoveAccount from "./pages/RemoveAccount";
import ReturnPolicy from "./pages/ReturnPolicy";
import UserAgreement from "./pages/UserAgreement";
import RulesCourier from "./pages/RulesCourier";
import RulesChef from "./pages/RulesChef";
import AgreementUsers from "./pages/AgreementUsers";
import PublicOfferCourier from "./pages/PublicOfferCourier";
import PublicOfferCompany from "./pages/PublicOfferCompany";
import PrivacyPolicy from "./pages/PrivacyPolicy";

function App() {
  return (
    <div className="max-w-[1680px] m-auto w-full h-[100vh]">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
        {/*  /!*<Route path="/terms_and_conditions" element={<TermsAndConditions />} />*!/*/}
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        {/*  /!*<Route path="/terms_of_service" element={<TermServices />} />*!/*/}
          <Route path="/user_agreement" element={<UserAgreement />} />
          <Route path="/rules_courier" element={<RulesCourier />} />
          <Route path="/rules_chef" element={<RulesChef />} />
          <Route path="/agreement_users" element={<AgreementUsers />} />
          <Route path="/public_offer_courier" element={<PublicOfferCourier />} />
          <Route path="/public_offer_company" element={<PublicOfferCompany />} />
          <Route path="/return_policy" element={<ReturnPolicy />} />
          <Route path="/deleted_account" element={<RemoveAccount />} />
        </Routes>
        <Footer />
        <Toaster />
      </Router>
    </div>
  );
}

export default App;
