import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import Upload from '../images/upload.png';

function UploadInput({ formik }) {
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();

  const fileData = acceptedFiles.map((file) => ({
    file: file,
    name: file.name,
    size: file.size,
    type: file.type,
    preview: URL.createObjectURL(file),
  }));
  useEffect(() => {
    formik.setFieldValue('licenses', fileData);
  }, [acceptedFiles]);


  return (
    <section className="container">
      <aside>
        <div className="max-h-[65px] h-full flex justify-between items-center pl-4 py-4 border border-1 border-gray-400 rounded-xl cursor-pointer hover:border-orange-500">
          <p className="text-[#8896AB] text-lg text-start">Загрузить здесь</p>
          <div {...getRootProps({ className: 'dropzone' })}>
            <div className="flex justify-center items-center max-h-[65px] w-[110px] h-[65px] max-w-[110px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] rounded-xl">
              <img src={Upload} alt="png" />
              <input multiple {...getInputProps()} />
            </div>
          </div>
        </div>
      </aside>
      { fileData && fileData.length > 0 &&
        <div className="w-full flex bg-white border border-gray-400 hover:border-orange-500 max-h-[107px] h-[107px] rounded-xl mt-8">
        {fileData.map((file, index) => (
          <div key={index} className="mx-2 text-center">
            {file.type.startsWith('image/') ? (
              <img src={file.preview} alt={file.name} className="max-h-[100px] rounded mb-2" />
            ) : (
              <div className="flex flex-col p-1">
                <iframe
                  src={file.preview}
                  title={file.name}
                  className="w-[100px] h-[100px] rounded"
                ></iframe>
              </div>
            )}
          </div>
        ))}
      </div>
      }
    </section>
  );
}

export default UploadInput;
