import React from "react";
import Web from "../images/web.svg";
import Watsapp from "../images/watsapp.svg";
import Instagram from "../images/instagram.svg";
import Phone from "../images/phone.svg";
import Email from "../images/email.svg";

const menu = [
  { title: 'Главный' },
  { title: 'О нас' },
  { title: 'Условия' },
  { title: 'Возможности' },
  { title: 'FAQ' },
  { title: 'Обратная связь' }
];

const iconsBlock = [
  { title: 'watsapp', icon: Web },
  { title: 'instagram', icon: Watsapp },
  { title: 'email', icon: Instagram },
  { title: 'phone', icon: Phone },
  { title: 'web', icon: Email }
];

const MobileMenu = ({ setIsOpenMobileNav }) => {
  return (
    <>
      <div className='lg:flex gap-x-[25px] space-y-[20px]'>
        {menu.map((item, index) => {
          return (
            <div key={index}>
              <p className="text-[#333F51]">{item.title}</p>
            </div>
          );
        })}
      </div>
      <div className='flex justify-center gap-x-[14px] mt-[38px]'>
        {iconsBlock.map((item, index) => {
          return (
            <div key={index}>
              <img src={item.icon} alt="svg" />
            </div>
          );
        })}
      </div>
      <div className="flex justify-center">
      <div onClick={() => setIsOpenMobileNav(true)}
           className="mt-[38px] max-w-[135px] flex justify-center items-center border rounded-[30px] h-[41px] bg-gradient-to-r from-[#DE4333] to-[#FDA110]">
        <button className="m-px border rounded-[30px] h-[35px] px-8 bg-white">Я повар!</button>
      </div>
      </div>
    </>
  )
}

export default MobileMenu
