import {useEffect} from "react";

const RulesCourier = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-4 md:px-56 my-10 md:my-20">
      <h1 className="text-center font-bold text-3xl">ПРАВИЛА ОКАЗАНИЯ УСЛУГ ПОЛЬЗОВАТЕЛЕМ (КУРЬЕРОМ)</h1>
      <p className="mt-4">
        Настоящие Правила оказания услуг Пользователем (далее — Курьер) являются неотъемлемой
        частью и приложением к Публичной оферте оказания электронных услуг Компанией Товарищество
        с ограниченной ответственностью "DOM FOOD (ДОМ ФУД). Принятие условий настоящих
        Правил осуществляется Курьером путем присоединения к условиям Договора-Оферты и
        проставления галочки в соответствующем поле на Сайте https://dom-food.com/ и https://domfood.kz/.
        Такое действие считается полным и безоговорочным принятием условий настоящих Правил и
        обязательным условием для исполнения принятых на себя обязательств. Настоящие Правила
        вступают в силу с момента присоединения Пользователя к Договору-Оферте.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">1. ОБЩИЕ ПОЛОЖЕНИЯ</h3>
      <p>
        <p><br/></p> 1.1. Услуги доставки: Пользователь (далее — Курьер) предоставляет услуги доставки заказов,
        оформленных через Исполнителя (далее — Платформа), в качестве самозанятого лица. Курьер
        обязуется самостоятельно уплачивать налоги с доходов, полученных от выполнения заказов. В
        случае необходимости, Платформа может удерживать определённый процент с оплаты за услуги
        Курьера для уплаты налогов (размер данного процента будет определён позже).
        <p><br/></p>1.2. Предоставление заказов и контроль: Платформа предоставляет заказы Курьеру через
        приложение, а также осуществляет контроль за их выполнением, включая соблюдение сроков и
        качество доставки.
        <p><br/></p>1.3. Соблюдение условий договора: Курьер обязуется соблюдать условия Договора-Оферты,
        включая сроки доставки, сохранность товаров и стандарты качества, установленные
        Платформой.
        <p><br/></p>1.4. Регистрация и использование приложения: Курьер должен быть зарегистрирован на
        Платформе и использовать приложение для подтверждения получения и выполнения заказов.
        Все действия по приёму и сдаче заказов должны фиксироваться через приложение.
        <p><br/></p> 1.5. Гибкость рабочего времени: Курьер имеет гибкость в выборе рабочего времени, но обязан
        соблюдать согласованные сроки доставки для каждого заказа. В случае невозможности
        выполнения доставки в срок, Курьер обязан незамедлительно уведомить Платформу.
        <p><br/></p> 1.6. Коммуникация с Пользователями: Курьер имеет право взаимодействовать с
        Пользователями (Поварами) через чат для уточнения деталей доставки, а также с Покупателями для
        уточнения адреса и других условий доставки. Вся коммуникация должна вестись вежливо и в
        соответствии со стандартами Платформы.
        <p><br/></p>1.7. Соблюдение стандартов Платформы: Курьер обязуется соблюдать все правила и политики
        Платформы, включая стандарты обслуживания, качества доставки и взаимодействия с
        Покупателями.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">2. ОБЯЗАННОСТИ КУРЬЕРА</h3>
        <p>
          <p><br/></p> 2.1. Доставка заказов:
          <p><br/></p> • Своевременная доставка: Курьер обязан своевременно забирать заказы у Пользователей
          (Повара или Фермера) и доставлять их Покупателям в сроки, указанные в приложении Платформы.
          В случае непредвиденных обстоятельств, влияющих на возможность соблюдения сроков, Курьер
          обязан немедленно уведомить Платформу.
          <p><br/></p> • Сохранность и качество продукции: Курьер должен обеспечить сохранность и качество
          готовой продукции во время доставки. Это включает соблюдение правил транспортировки и
          использования надлежащих упаковочных материалов для предотвращения повреждения
          продуктов питания. Курьер несет ответственность за любые повреждения или нарушения
          целостности упаковки, которые произошли во время доставки.
          <p><br/></p>2.2. Использование транспортных средств:
          <p><br/></p> • Личный транспорт: Курьер обязан использовать личный транспорт для выполнения заказов.
          Платформа не несет ответственности за техническое состояние транспортного средства Курьера,
          его исправность или соответствие применимым требованиям.
          <p><br/></p> • Соблюдение правил дорожного движения: Курьер обязан соблюдать все правила
          дорожного движения и законы Республики Казахстан, касающиеся безопасного передвижения по
          дорогам во время выполнения заказов.
          <p><br/></p> 2.3. Подтверждение доставки:
          <p><br/></p> • Фиксация выполнения доставки: После завершения доставки Курьер обязан подтвердить
          выполнение заказа в приложении, указав, что заказ успешно передан Покупателю.
          <p><br/></p>• Фотографирование передачи заказа: Курьер также обязан сделать фотографию,
          подтверждающую передачу заказа Покупателю. Данная фотография должна быть загружена в
          систему Платформы и может использоваться для разрешения возможных споров. Фотография
          должна быть сделана таким образом, чтобы было чётко видно место передачи и сам заказ.
          <p><br/></p>2.4. Соблюдение санитарных норм:
          <p><br/></p> • Чистота и гигиена: Курьер обязан соблюдать санитарные нормы при работе с готовой
          продукцией, в том числе обеспечивать чистоту транспортных средств, используемых для доставки.
          Курьер обязан гарантировать, что продукты сохраняются в безопасных условиях и что ничего не
          угрожает их качеству во время транспортировки. Это включает использование чистых сумок или
          контейнеров для доставки.
          <p><br/></p> 2.5. Коммуникация с Покупателями и другими Пользователями:
          <p><br/></p> • Коммуникация с Поварами и Фермерами: Курьер имеет право взаимодействовать с
          другими Пользователями (Поварами или Фермерами) через чат приложения для уточнения деталей
          доставки.
          <p><br/></p> • Связь с Покупателями: Курьер может связываться с Покупателем во время доставки для
          уточнения адреса или других условий, связанных с получением заказа. Курьер должен соблюдать
          нормы вежливого общения и предоставлять актуальную информацию о состоянии доставки.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">3. СРОКИ И УСЛОВИЯ ДОСТАВКИ ГОТОВОЙ ПРОДУКЦИИ</h3>
        <p>
          <p><br/></p>  3.1. Соблюдение сроков доставки:
          • Своевременность доставки: Курьер обязан доставлять заказы в пределах времени,
          указанного на Платформе для каждого конкретного заказа. Вся информация о сроках и ожидаемом
          времени доставки будет доступна в приложении.
          • Уведомление о задержках: В случае, если Курьер понимает, что не сможет доставить заказ
          вовремя (например, из-за задержек на дорогах, погодных условий или других непредвиденных
          обстоятельств), он обязан незамедлительно уведомить Платформу через приложение, чтобы
          Платформа могла своевременно уведомить Покупателя.
          <p><br/></p>  3.2. Задержки и уведомления:
          • Задержки, не зависящие от Курьера: Если доставка задерживается по причинам, не
          зависящим от Курьера (например, пробки, погодные условия или иные обстоятельства форсмажора), Платформа обязуется уведомить Покупателя о задержке, чтобы уменьшить его
          недовольство.
          • Задержки по вине Курьера: В случае, если задержка произошла по вине Курьера,
          Платформа оставляет за собой право применить штрафные санкции или удержания из оплаты
          Курьера. Размер и условия штрафов определяются Платформой и могут быть изменены с
          предварительным уведомлением Курьера.
          <p><br/></p>  3.3. Точность адреса и соблюдение инструкций:
          • Проверка адреса доставки: Курьер обязан тщательно проверять адрес доставки, указанный
          в приложении. В случае, если адрес неясен или неполон, Курьер должен связаться с Покупателем
          или уведомить Платформу для получения уточнений.
          • Соблюдение инструкций Покупателя: Курьер обязан следовать дополнительным
          инструкциям от Покупателя, если они были указаны при оформлении заказа. Это может включать
          такие действия, как оставить заказ у двери, на ресепшене, или в другом указанном месте. Курьер
          обязан удостовериться в том, что все инструкции соблюдены в точности.
          <p><br/></p>  3.4. Условия доставки:
          • Сохранность товара: Курьер несёт полную ответственность за сохранность товара во время
          доставки, включая соблюдение всех необходимых температурных и санитарных условий, чтобы
          обеспечить качество готовой продукции. Это включает использование специализированных сумок
          для поддержания температуры (например, термосумок для горячих блюд).
          • Компенсация убытков: Если готовая продукция была повреждена или испорчена в
          процессе доставки, Курьер несёт ответственность за убытки, возникшие по его вине. В таких
          случаях Платформа может потребовать компенсацию убытков, что будет удержано из оплаты
          Курьера.
          <p><br/></p>  3.5. Ответственность за непринятые заказы:
          • Непринятые заказы: В случае, если Покупатель не доступен по указанному адресу и не
          отвечает на звонки или сообщения, Курьер обязан уведомить Платформу через приложение или
          чат. Платформа предоставит дальнейшие инструкции, включая возможное возвращение заказа
          Пользователю (Повару) или хранение заказа до уточнения деталей.
        </p>
      <h3 className="font-bold text-2xl mt-4 text-center">4. СИСТЕМА ОПЛАТЫ УСЛУГ</h3>
      <p>
        <p><br/></p>  4.1. Система оплаты: Курьер получает оплату за каждую выполненную доставку в соответствии с
        тарифами, установленными Платформой. Тарифы могут зависеть от различных факторов, таких
        как расстояние, время выполнения заказа, загруженность дорог и другие условия, определяемые
        Платформой. Курьеру будет предоставлена возможность ознакомиться с действующими тарифами
        в приложении.
        <p><br/></p>  4.2. Установление стоимости доставки: Стоимость доставки устанавливается Платформой на
        основе множества параметров, включая расстояние от Пункта получения до Покупателя, время
        выполнения заказа, тип транспортного средства, и прочие факторы. Платформа оставляет за собой
        право изменять стоимость доставки в зависимости от текущей загруженности и иных факторов,
        влияющих на процесс доставки.
        <p><br/></p>  4.3. Комиссия курьера: Курьер получает оплату за выполненные доставки за вычетом комиссии,
        определенной Платформой. Размер комиссии может меняться в зависимости от условий доставки,
        включая загруженность и расстояние. Курьер будет заранее уведомлен о любых изменениях в
        тарифах и комиссиях. Все изменения вступают в силу с момента уведомления Курьера через
        приложение.
        <p><br/></p>  4.4. Окончательная стоимость для Покупателя: Окончательная стоимость доставки, которую
        видит Покупатель при оформлении заказа, включает в себя все применимые сборы и комиссии. Эта
        стоимость является фиксированной на момент оформления заказа и не подлежит изменению после
        акцепта Покупателем.
        <p><br/></p>  4.5. Периодичность выплат: Оплата за выполненные доставки осуществляется на ежедневной
        основе. В случае возникновения технических или иных причин, приводящих к задержке выплат,
        Платформа обязуется уведомить Курьера и предоставить информацию о предполагаемой дате
        выплаты. Курьер соглашается с возможностью временных задержек выплат в случае форсмажорных обстоятельств.
        <p><br/></p>  4.6. Удержания и налоги:
        • Самостоятельная уплата налогов: Курьер, работающий как самозанятое лицо, обязан
        самостоятельно уплачивать налоги с доходов, полученных через Платформу, в соответствии с
        законодательством Республики Казахстан.
        • Удержание налогов Платформой: В случае, если Платформа берёт на себя обязанность по
        удержанию определённого процента от выплат Курьера для уплаты налогов, размер такого
        удержания будет согласован с Курьером.
        <p><br/></p>  4.7. Штрафы и удержания: В случае нарушения условий Договора (например, опоздание при
        доставке Покупателю, повреждение готовой продукции, несоблюдение санитарных норм),
        Платформа оставляет за собой право применять штрафные санкции, включая удержание до 50% от
        стоимости заказа в качестве неустойки. Конкретный размер штрафа зависит от тяжести нарушения
        и будет определяться Платформой.
        <p><br/></p>  4.8. Форс-мажор: В случае возникновения форс-мажорных обстоятельств, таких как блокировка
        счетов Платформы, технические сбои или задержки в банках, Платформа обязуется уведомить
        Курьеров о возможных задержках выплат и указать предполагаемые сроки. Обе Стороны
        соглашаются, что не будут подавать претензии по данному факту, пока не будут устранены все
        препятствия к выплатам.
        <p><br/></p>  4.9. Бонусы за выполнение: Курьеры могут получать дополнительные премии за успешное
        выполнение определенного количества доставок или работу в периоды высокой загруженности,
        например, в часы пик или в праздничные дни. Бонусы устанавливаются Платформой и
        выплачиваются в дополнение к основным тарифам.
        <p><br/></p> 4.10. Прозрачность выплат: Платформа обязуется предоставить Курьерам полную информацию о
        каждой выплате, включая данные о выполненных доставках, произведенных удержаниях и
        наложенных штрафах (если применимо). Информация будет доступна в личном кабинете Курьера в
        приложении.
        <p><br/></p>  4.11. Механизм разрешения споров по оплате: В случае возникновения споров по оплате Курьер
        имеет право обратиться в службу поддержки Платформы. Платформа обязуется рассмотреть жалобу
        и предоставить письменный ответ Курьеру в течение 5 (пяти) рабочих дней с момента обращения.
        Все споры по оплате решаются с учётом данных, зарегистрированных в приложении
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">5. ОТВЕТСТВЕННОСТЬ КУРЬЕРА ЗА ВРЕМЯ ОКАЗАНИЯ УСЛУГ ПО ДОСТАВКЕ
        ГОТОВОЙ ПРОДУКЦИИ</h3>

      <p>
        <p><br/></p>  5.1. Сохранность заказов:
        • Полная ответственность за сохранность: Курьер несет полную ответственность за
        сохранность готовой продукции в течение всего процесса доставки. Он обязан обеспечить, чтобы
        упаковка оставалась неповрежденной, а продукция доставлялась в хорошем состоянии, сохраняя
        все качественные характеристики.
        • Компенсация ущерба: В случае, если заказ был поврежден или испорчен во время доставки
        по вине Курьера, он несет ответственность за компенсацию стоимости заказа. Платформа
        оставляет за собой право удерживать соответствующую сумму из оплаты Курьера.
        <p><br/></p>  5.2. Оповещение о проблемах:
        • Незамедлительное уведомление о проблемах: Курьер обязан немедленно уведомить
        Платформу о любых проблемах, связанных с доставкой (например, невозможность найти адрес
        Покупателя, авария или любые другие препятствия). Уведомление осуществляется через
        приложение Платформы или иные доступные средства связи.
        • Проблемы с доступностью Покупателя: Если Покупатель недоступен по указанному
        адресу и не отвечает на звонки, Курьер должен связаться с Покупателем с использованием
        предоставленных контактных данных. Если это не удается, Курьер обязан уведомить Платформу
        для получения дальнейших инструкций.
        <p><br/></p>   5.3. Соблюдение правил Платформы:
        • Соблюдение стандартов и правил: Курьер обязан соблюдать все внутренние правила и
        стандарты, установленные Платформой, касающиеся доставки, взаимодействия с Покупателями и
        другими Пользователями (Поварами или Фермерами).
        • Штрафы за нарушения: Нарушение правил Платформы, таких как несвоевременная
        доставка или неуважительное обращение с Покупателями, может повлечь за собой наложение
        штрафов, удержание части оплаты или расторжение договора с Курьером.
        <p><br/></p>  5.4. Ответственность за соблюдение сроков
        • Штрафы за нарушение сроков: Курьер обязан соблюдать установленные сроки доставки. В
        случае, если доставка не была выполнена в срок без уважительных причин (например, болезнь или
        форс-мажорные обстоятельства), Платформа имеет право наложить штрафы или удержать часть
        оплаты Курьера. Размер штрафа определяется в зависимости от тяжести нарушения и фиксируется
        Платформой.
        <p><br/></p>  5.5. Ответственность за санитарные условия
        • Соблюдение санитарных норм: Курьер обязан поддерживать чистоту транспортных
        средств, используемых для доставки, и строго соблюдать все санитарные нормы. Это включает
        регулярное обслуживание и дезинфекцию транспортного средства, а также использование средств
        для обеспечения безопасности продукции (например, термосумок).
        <p><br/></p>  5.6. Ответственность за работу в приложении
        • Обновление статуса доставки: Курьер несет ответственность за своевременное обновление
        статуса выполнения доставки в приложении. Это включает в себя подтверждение получения заказа
        у Пользователя (Повар или Фермер) и подтверждение передачи заказа Покупателю.
        Несвоевременное обновление статуса может привести к применению штрафов.
        <p><br/></p>  5.7. Ответственность за использование личных данных
        • Защита персональных данных Покупателей: Курьер обязан обеспечивать сохранность и
        конфиденциальность персональных данных Покупателей, таких как адреса и контактные
        телефоны, полученные в процессе выполнения заказа. Курьер не имеет права передавать эти
        данные третьим лицам или использовать их для личных целей, не связанных с выполнением
        доставки. Нарушение этого обязательства может повлечь за собой юридическую ответственность,
        включая штрафы и расторжение договора.
      </p>
      <h3 className="font-bold text-2xl mt-4 text-center">6. ПОРЯДОК ВНЕСЕНИЯ ИЗМЕНЕНИЙ В ПРАВИЛА</h3>
    <p>
      <p><br/></p> 6.1. Право на изменение правил: Платформа оставляет за собой право вносить изменения и
      дополнения в настоящие Правила оказания услуг Пользователем (Курьером).
      <p><br/></p>  6.2. Уведомление о внесении изменений: Изменения и дополнения вступают в силу с момента их
      публикации на Сайте Платформы и/или в приложении. Платформа обязуется уведомить Курьера о
      внесённых изменениях посредством уведомления в приложении или через электронную почту не
      менее чем за 5 (пять) календарных дней до вступления изменений в силу.
      <p><br/></p>6.3. Принятие изменений Курьером: Если Курьер продолжает предоставлять услуги после
      вступления изменений в силу, это считается согласием с новыми условиями. В случае несогласия с
      внесёнными изменениями, Курьер имеет право прекратить предоставление услуг и уведомить об
      этом Платформу в установленном порядке.
    </p>
    </div>
    )
}
export default RulesCourier