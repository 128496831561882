import BlockOneImage from '../images/blockOne.png'
import MapImage from '../images/mapImage.png'
import Animation from "./Animation";
import {useState} from "react";
import CustomModal from "./CustomModal";
import CookerForm from "./Forms/CookerForm";

const gridItemStyle = {
  backgroundImage: `url(${BlockOneImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  mixBlendMode: 'soft-light',
  position: 'relative'
}

const BlockOne = () => {
  const [isOpenMobileNav, setIsOpenMobileNav] = useState(false);

  return (
    <div className="w-full h-[79%] mt-10 md:mt-20" style={gridItemStyle}>
      <div className="flex flex-col md:flex-row items-center md:pl-16 xl:pl-[70px] pl-4 px-4 md:px-0">
        <div className="text-center md:text-start max-w-[350px] md:max-w-[250px] lg:max-w-full">
          <h1 className="text-[28px] md:text-3xl xl:text-6xl text-[#DE4333] font-custom font-bold">DomFood</h1>
          <h2 className="text-[22px] xl:text-3xl text-[#333F51] text-wrap md:text-nowrap font-bold font-custom mt-4">Домашняя кухня, доставленная прямо
            </h2>
          <h2 className="text-[22px] xl:text-3xl text-[#333F51] text-wrap md:text-nowrap font-bold font-custom mt-2">к вашему дверному порогу!</h2>
          <div className="mt-[10px]">
            <p className="text-[#404F65] text-base font-normal text-wrap xl:text-nowrap mt-[10px]">Скачайте уникальное приложение Domfood прямо сейчас и <span className=" md:hidden">откройте для себя новый уровень комфорта и</span></p>
            <p className="text-[#404F65] font-normal text-base hidden md:block">откройте для себя новый уровень комфорта и</p>
            <p className="text-[#404F65] text-base">гастрономического наслаждения!</p>
          </div>
          <div onClick={() => setIsOpenMobileNav(true)}>
            <button className="mt-6 lg:mt-28 rounded-[30px] h-[52px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] text-white px-10 font-bold">Оставить заявку</button>
          </div>
        </div>
        <div className="min-w-auto xl:min-w-[500px] mt-10 md:mt-0 flex items-center md:block">
          <img className="w-[240px] md:w-[350px] xl:w-[500px]" src={MapImage} alt="png" />
          <div className="block md:hidden">
            <Animation height={100} width={100} />
          </div>
        </div>
        <div className="hidden md:block">
          <Animation height={163} width={163} />
        </div>
      </div>
      <CustomModal setIsOpenMobileNav={setIsOpenMobileNav} isOpenMobileNav={isOpenMobileNav} title="Заполните заявку на нашем сайте или свяжитесь с нами по телефону для получения дополнительной информации.">
        <CookerForm setIsOpenMobileNav={setIsOpenMobileNav} />
      </CustomModal>
    </div>
  )
}

export default BlockOne