import React, { useState } from "react";
import { useFormik } from 'formik';
import SingleSelectFormField from "../SingleSelectFormField";
import axios from 'axios';
import classNames from "../../utils";
import toast from 'react-hot-toast';
import SelectTypeCar from "../SelectTypeCar";
import ErrorMassage from "../ErrorMassage";


const Courier = ({ setIsOpenMobileNav }) => {
  const [access, setAccess] = useState(true);
  const [errors, setErrors] = useState({});


  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      state_id: '',
      city_id: '',
      car_type: '',
      transport_name: '',
    },
    onSubmit: async (values) => {
      try {
        const response = await axios.post('https://api.dom-food.com/page/register-courier', values);
        console.log('Form submitted successfully:', response.data);
        setIsOpenMobileNav(false)
        toast.success('Your account has been created, waiting for improvement from admin')
      } catch (error) {
        setErrors(error.response.data.errors);
        console.error('Error submitting form:', error);
      }
    },
  });

  const checkAccess = () => {
    setAccess(!access);
  };

  return (
    <form onSubmit={formik.handleSubmit} className="space-y-4 mt-4 px-4 md:px-16">
      <input
        name="first_name"
        value={formik.values.first_name}
        onChange={formik.handleChange}
        className="hover:border-orange-500 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"
        placeholder="Имя"
      />
      { errors.first_name && <ErrorMassage message={errors.first_name} /> }

      <input
        name="last_name"
        value={formik.values.last_name}
        onChange={formik.handleChange}
        className="hover:border-orange-500 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"
        placeholder="Фамилия"
      />
      { errors.last_name && <ErrorMassage message={errors.last_name} /> }

      <input
        type="email"
        name="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        className="hover:border-orange-500 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"
        placeholder="Эл. Почта*"
      />
      { errors.email && <ErrorMassage message={errors.email} /> }

      <input
        name="phone"
        value={formik.values.phone}
        onChange={formik.handleChange}
        className="hover:border-orange-500 border border-1 border-gray-400 rounded-full h-[45px] max-w-[650px] px-[20px] w-full"
        placeholder="Телефон"
      />
      { errors.phone && <ErrorMassage message={errors.phone} /> }

      <p className="text-[#333F51] text-lg text-start">Область</p>
      <SingleSelectFormField
        formik={formik}
      />
      { errors.state_id && <ErrorMassage message={errors.state_id} /> }
      { errors.city_id && <ErrorMassage message={errors.city_id} /> }


      <p className="text-[#333F51] text-lg text-start">Вид транспорта</p>
      <SelectTypeCar
        formik={formik}
      />
      { errors.car_type && <ErrorMassage message={errors.car_type} /> }

      <button
        disabled={access}
        type="submit"
        className={classNames(access ? 'cursor-not-allowed' : 'cursor-pointer', 'w-full mt-8 rounded-[30px] h-[52px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] text-white px-10 font-bold')}
      >
        Отправить заявку
      </button>
      <div className="flex gap-x-4">
        <input onChange={checkAccess} type="checkbox" />
        <label className="text-[#333F51] text-sm text-start">Я принимаю условия Публичной Оферты и Пользовательского Соглашения а также соглашаюсь с Политикой Конфиденциальности.</label>
      </div>
    </form>
  );
};

export default Courier;
